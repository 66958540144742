export const data = {
  // app: "iPhone",
  // pharmacy: {
  //   name: "Brooklyn Center Pharmacy",
  //   phone: "+ 1 (718) 250-0060",
  //   address: "112 Dekalb Ave, Brooklyn, NY 11201",
  // },
  // doctor: {
  //   name: "Joshua Davis",
  //   phone: "+ 1 (418) 350-2060",
  // },
  // insurance: {
  //   name: "Gateway Health",
  //   logo: "https://misn-ny.org/wp-content/uploads/2019/10/healthfirst-logo-2.jpg",
  //   images: [
  //     "https://image.shutterstock.com/image-vector/insurance-medical-card-600w-1021000552.jpg",
  //     "https://image.shutterstock.com/image-vector/insurance-medical-card-600w-1021000552.jpg",
  //   ],
  // },
};
