import {SingleValue, ActionMeta} from 'react-select';

import Typography from 'components/Typography';
import Card from 'components/Card';
import CardContent from 'components/CardContent';
import {ScriptStatusOption} from 'components/ScriptStatusSelect';

import {Prescription, SelectOption} from 'types';

import styles from '../RequestDetails.module.scss';
import Button from 'components/Button';
import {useCallback, useEffect, useState} from 'react';
import PrescriptionDrugModal from './PrescriptionDrugModal';
import {RequestService} from 'services/request.service';
import {useQueryClient} from 'react-query';
import {get} from 'lodash';
import {mapMMStatusText} from 'constants/mappings';

interface Props {
  requestId: number;
  origin?: string;
  status?: string;
  sync_source?: string | null;
  drugs: Prescription[];
  onSendNotification: (id: number | undefined) => Promise<any>;
  onCharge: (id: number | undefined) => Promise<any>;
  onRequestSignature: () => Promise<any>;
  onChangeStatus: (
    newValue: SingleValue<ScriptStatusOption>,
    actionMeta: ActionMeta<SelectOption>
  ) => void;
}

function Prescriptions({
  drugs,
  onCharge,
  onSendNotification,
  requestId,
  status,
  sync_source,
}: Props) {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedDrug, setSelectedDrug] = useState<Prescription>();
  const queryClient = useQueryClient();

  const handleEditDrug = useCallback((drug: Prescription) => {
    setSelectedDrug(drug);
    if (drug) {
      setIsEditModalOpen(true);
    }
  }, []);

  const handleUpdateDrug = useCallback(
    async (data: {amount_due?: string}) => {
      if (!selectedDrug) {
        return;
      }

      const requestService = new RequestService();
      await requestService.updateRequestDrug(selectedDrug.id, data);
      queryClient.invalidateQueries(['request_details', requestId.toString()]);
      setIsEditModalOpen(false);
    },
    [queryClient, requestId, selectedDrug]
  );

  useEffect(() => {
    if (selectedDrug) {
      setSelectedDrug(drugs.find((item) => item.id === selectedDrug.id));
    }
  }, [drugs, selectedDrug]);

  return (
    <>
      <Card>
        <CardContent px="sm" pt="md" pb="sm">
          <div style={{marginBottom: 25}} className={styles.row}>
            <div style={{flex: 1}}>
              <p style={{margin: 0}}>Total amount:</p>
              <p style={{margin: 0, marginTop: 5}}>
                <b>
                  $
                  {drugs
                    .reduce((pr, curr) => pr + Number(curr.amount_due), 0)
                    .toFixed(2)}
                </b>
              </p>
            </div>
            <div style={{flex: 1}}>
              <p style={{margin: 0}}>Total paid:</p>
              <p style={{margin: 0, marginTop: 5}}>
                <b>
                  $
                  {drugs
                    .reduce((pr, curr) => pr + (Number(curr.paid) || 0), 0)
                    .toFixed(2)}
                </b>
              </p>
            </div>
          </div>
          <div className={styles.row}>
            <Button
              color="blue"
              disabled={
                drugs.filter((item) => Number(item.amount_due) > 0).length ===
                  0 ||
                (status === 'completed'
                  ? drugs.filter((item) => item.status !== 'completed')
                      .length === 0
                  : false)
              }
              onClick={() => onSendNotification(undefined)}
              className={styles.notify_action}
            >
              Notify to Pay All
            </Button>
            <Button
              color="blue"
              onClick={() => onCharge(undefined)}
              disabled={
                drugs.filter((item) => Number(item.amount_due) !== 0).length ===
                  0 || status === 'completed'
              }
              className={styles.notify_action}
            >
              Charge All
            </Button>
          </div>
        </CardContent>
      </Card>
      <Card>
        <PrescriptionDrugModal
          isOpen={isEditModalOpen}
          drug={selectedDrug}
          sync_source={sync_source}
          onClose={() => setIsEditModalOpen(false)}
          // onChangeStatus={onChangeStatus}
          onSubmit={handleUpdateDrug}
        />
        <CardContent px="sm" pt="md" pb="sm">
          <Typography component="p" variant="label">
            Service
          </Typography>
          {drugs.map((drug) => (
            <div key={drug.id} className={styles.drug}>
              <Typography
                component="p"
                variant="h3"
                className={styles.drug__name}
              >
                {drug.name}
              </Typography>
              <Typography component="p" variant="label">
                {drug.strength}
              </Typography>
              <div className={styles.drugActions}>
                {/* <ScriptStatusSelect
                name={`drug-${drug.id}`}
                status={drug.status}
                onChange={onChangeStatus}
                type="prescription"
                origin={origin}
                isPvfr={true}
                isVerified={!!selectedDrug?.is_pvrf_done}
                // isPvfr={drugs.filter((item) => !item.is_pvrf_done).length === 0}
                isDisabled={drug.status === 'delivered'}
              /> */}
                <p>Status: {mapMMStatusText[drug.status as 'completed']}</p>

                <Button
                  color="outlined"
                  component="button"
                  size="sm"
                  onClick={() => handleEditDrug(drug)}
                >
                  Edit
                </Button>
              </div>
              <div className={styles.payments}>
                <div className={styles.payment}>
                  <Typography variant="label" className={styles.payment__label}>
                    Amount due
                  </Typography>
                  <Typography
                    variant="strong"
                    className={styles.payment__value}
                  >
                    ${drug.amount_due || 0}
                  </Typography>
                </div>
                <div className={styles.payment}>
                  <Typography variant="label" className={styles.payment__label}>
                    Paid
                  </Typography>
                  <Typography
                    variant="strong"
                    className={styles.payment__value}
                  >
                    ${drug.paid || 0}
                  </Typography>
                </div>
              </div>
              <div style={{marginTop: 15}} className={styles.row}>
                <Button
                  color="blue"
                  disabled={
                    Number(drug?.amount_due) === 0 ||
                    drug.status === 'completed'
                  }
                  onClick={() => onSendNotification(drug.id)}
                  className={styles.notify_action}
                >
                  Notify to Pay
                </Button>
                <Button
                  color="blue"
                  onClick={() => onCharge(drug.id)}
                  disabled={
                    get(drug, 'is_paid', false) ||
                    drug.status === 'completed' ||
                    Number(get(drug, 'amount_due')) === 0
                  }
                  className={styles.notify_action}
                >
                  Charge Card
                </Button>
              </div>
            </div>
          ))}
        </CardContent>
      </Card>
    </>
  );
}

export default Prescriptions;
