import {TypeGooglePlaceDetail} from '../types';

export const extractAddressFromPlaceDetails = (
  placeDetails: any
): TypeGooglePlaceDetail => {
  const zipcodeComponent = placeDetails.address_components.find((item: any) =>
    item.types.includes('postal_code')
  );
  const zipcode = zipcodeComponent?.long_name || '';

  const cityComponent = placeDetails.address_components.find(
    (item: any) =>
      item.types.includes('administrative_area_level_1') &&
      item.types.includes('political')
  );
  const city = cityComponent?.long_name || '';
  const state = cityComponent?.short_name || '';

  const streetNumber = placeDetails.address_components.find((item: any) =>
    item.types.includes('street_number')
  );

  const route = placeDetails.address_components.find((item: any) =>
    item.types.includes('route')
  );

  const adddress = [streetNumber?.long_name, route?.long_name];

  return {
    fullAddress: placeDetails.formatted_address,
    addressLine1: adddress.join(' ').trim(),
    city,
    state,
    zipcode,
    lat: placeDetails.geometry.location.lat(),
    lon: placeDetails.geometry.location.lng(),
  };
};
