import DataTable from 'components/DataTable';
import useRequests from 'pages/Requests/useRequests';
import {useRouteMatch} from 'react-router-dom';

const SCHEMA = [
  {dataKey: 'number', header: 'Request number'},
  {dataKey: 'created_at', header: 'Date & Time'},
  {dataKey: 'status', header: 'Request Status'},
];

function Requests() {
  const {params} = useRouteMatch<{userId: string}>();
  const {data = []} = useRequests({userId: params.userId});
  return <DataTable schema={SCHEMA} data={data} selectable={false} />;
}

export default Requests;
