import { useContext } from "react";

import uiContext from "contexts/uiContext";

function useUi() {
  const {
    sidebarExtended,
    setSidebarExtended,

    fullsizeContent,
    setFullsizeContent,
  } = useContext(uiContext);

  return {
    sidebarExtended,
    setSidebarExtended,

    fullsizeContent,
    setFullsizeContent,
  };
}

export default useUi;
