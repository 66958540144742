import Input, { InputProps } from "components/Input";
import icon from "./icons/icon.svg";

import styles from "./SearchInput.module.scss";

function SearchInput(props: InputProps) {
  return (
    <Input
      className={styles.root}
      icon={<img alt="search icon" src={icon} />}
      {...props}
    />
  );
}

SearchInput.defaultProps = {
  placeholder: "Search",
};

export default SearchInput;
