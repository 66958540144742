import authContext from "contexts/authContext";
import { useContext } from "react";

function useAuth() {
  const auth = useContext(authContext);

  return auth;
}

export default useAuth;
