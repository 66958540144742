import {useMutation} from 'react-query';
import {CustomerService} from 'services/customer.service';

const useRequestSignature = () => {
  const {mutateAsync, isLoading} = useMutation({
    mutationFn: async ({requestId}: {
      requestId: number;
    }) => {
      const customerService = new CustomerService();
      return customerService.requestSignature(requestId);
    },
  });

  return {
    requestSignature: mutateAsync,
    isRequesting: isLoading,
  };
};

export default useRequestSignature;
