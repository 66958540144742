import React from "react";
import cn from "classnames";

import styles from "./CardContent.module.scss";

type Size = "sm" | "md" | "lg" | "none";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  dark?: boolean;
  // paddings
  px: Size;
  pt: Size;
  pb: Size;
}

function CardContent({
  dark,
  px,
  pt,
  pb,
  className,
  children,
  ...restProps
}: Props) {
  return (
    <div
      className={cn(
        styles.root,
        className,
        styles[`px-${px}`],
        styles[`pt-${pt}`],
        styles[`pb-${pb}`],
        {
          [styles.dark]: dark,
        }
      )}
      {...restProps}
    >
      {children}
    </div>
  );
}

CardContent.defaultProps = {
  px: "md",
  pt: "lg",
  pb: "sm",
};

export default CardContent;
