import api from 'api';
import useAuth from 'hooks/useAuth';
import {get} from 'lodash';
import toast from 'react-hot-toast';
import {QueryFunctionContext, useQuery} from 'react-query';
import {useHistory} from 'react-router-dom';

type TariffType = {
  id: string;
  name: string;
  description: string;
  price: number;
  marketing_features: TariffAddition[];
};

export type TariffAddition = {
  name: string;
};

async function getTarifs({queryKey}: QueryFunctionContext<any[]>) {
  const [,] = queryKey;

  const {data} = await api.get<TariffType[]>('/payment/stripe-products/', {
    params: {},
  });

  return data;
}

function useTariff() {
  const {currentUser, refetch} = useAuth();
  const {goBack} = useHistory();
  const {data} = useQuery(['tariff'], getTarifs);

  const buyTariff = async ({
    product_id,
    count,
  }: {
    product_id: string;
    count: number;
  }) => {
    try {
      if (!!get(currentUser, 'company.plan.plan_id')) {
        await api.put('/payment/stripe-subscription-management/', {
          product_id,
          quantity: count,
        });
      } else {
        await api.post('/payment/stripe-subscription-management/', {
          product_id,
          quantity: count,
        });
      }
      setTimeout(() => {
        refetch(false);
        goBack();
      }, 1000);
    } catch (error) {
      toast.error(get(error, 'response.data.message', 'Error'));
    }

    return data;
  };

  return {
    data: data || [],
    buyTariff,
  };
}

export default useTariff;
