import DataTable from 'components/DataTable';
import StatusBadge from 'components/StatusBadge';
import Typography from 'components/Typography';
import {formatDistanceToNow, parseJSON} from 'date-fns';
import useOrderList from 'hooks/useOrderList';
import {capitalize} from 'lodash';
import {useMemo} from 'react';
import {useRouteMatch} from 'react-router-dom';
import {OrderStatus, StatusColor} from 'types';

const SCHEMA = [
  {dataKey: 'number', header: 'New Leaf Order Number'},
  {dataKey: 'created_at', header: 'Date & Time'},
  {dataKey: 'status', header: 'Delivery Status'},
];

const colors: Record<OrderStatus, StatusColor> = {
  ready_for_delivery: 'iris',
  in_transit: 'fuschia',
  delivered: 'green',
  completed: 'green',
  canceled: 'critical',
  on_way: 'critical',
  pickup: 'green',
};

function Deliveries() {
  const {params} = useRouteMatch<{userId: string}>();
  const {orders} = useOrderList({userId: params.userId});

  const data = useMemo(
    () =>
      orders.map((order) => ({
        id: order.id,
        number: <Typography variant="strong">#{order.id}</Typography>,
        created_at: formatDistanceToNow(parseJSON(order.created_at), {
          addSuffix: true,
        }),
        status: (
          <StatusBadge color={colors[order.status]}>
            {capitalize(order.status.replaceAll('_', ' '))}
          </StatusBadge>
        ),
      })),
    [orders]
  );
  return <DataTable schema={SCHEMA} data={data} selectable={false} />;
}

export default Deliveries;
