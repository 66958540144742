import {useQuery} from 'react-query';
import {CustomerService} from 'services/customer.service';

type Props = {
  customerId?: number;
};

const useCustomerInsurances = ({customerId}: Props) => {
  const {data: insurances = [], isLoading} = useQuery({
    queryFn: async () => {
      const customerService = new CustomerService();
      const result = await customerService.getInsurances();
      return result.data.results;
    },
    queryKey: ['insurances'],
  });

  return {
    insurances,
    isLoading,
  };
};

export default useCustomerInsurances;
