import React, {useMemo, useState} from 'react';
import {
  QueryFunctionContext,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import {format} from 'date-fns';
import {capitalize} from 'lodash';

import StatusBadge from 'components/StatusBadge';
import TableAction from 'components/TableAction';

import api from 'api';
import {Customer, ListResponse, StatusColor} from 'types';
import {parseISO} from 'date-fns/esm';
import {getFullName} from 'helpers/user';

type TabValue = 'pickup' | 'delivered,canceled';

interface Order {
  id: number;
  created_at: string;
  status: 'pickup' | 'delivered' | 'canceled';
  customer: Customer;
}

const colorMapping: Record<string, StatusColor> = {
  pickup: 'iris_dark',
  delivered: 'gray',
  canceled: 'critical',
};

async function getInstorePickups({queryKey}: QueryFunctionContext<string[]>) {
  const [, status] = queryKey;
  const {data} = await api.get<ListResponse<Order>>('/orders/pickup/', {
    params: {status},
  });

  return data;
}

function useInstorePickups() {
  const [activeId, setActiveId] = useState(0);
  const [isConfirming, setIsConfirming] = useState(false);
  const [status, setStatus] = useState<TabValue>('pickup');
  const {data: orders, isLoading} = useQuery(
    ['instore_pickup', status],
    getInstorePickups
  );
  const queryClient = useQueryClient();
  const complete = useMutation(
    'complete_order',
    async function (order_id: number) {
      await api.post(`/orders/${order_id}/complete/`);
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(['instore_pickup', 'pickup']);
        queryClient.invalidateQueries(['instore_pickup', 'delivered,canceled']);
      },
    }
  );

  function onChangeTab(tab: string) {
    setStatus(tab as TabValue);
  }

  function openConfirm(event: React.MouseEvent<HTMLDivElement>) {
    const dataId = event.currentTarget.getAttribute('data-id');

    if (Number(dataId)) {
      setActiveId(Number(dataId));
    }
  }

  function closeConfirm() {
    setActiveId(0);
  }

  async function confirm() {
    setIsConfirming(true);
    await complete.mutate(activeId);
    setIsConfirming(false);
  }

  const data = useMemo(() => {
    if (!orders) return [];

    return orders.results.map((o) => ({
      ...o,
      number: `#${o.id}`,
      created_at: format(parseISO(o.created_at), 'dd/MM/yy h:mmaaa'),
      customer: getFullName(o.customer.user),
      status: (
        <StatusBadge color={colorMapping[o.status]}>
          {o.status === 'pickup' ? 'In-store pickup' : capitalize(o.status)}
        </StatusBadge>
      ),
      confirm: o.status === 'pickup' && (
        <TableAction data-id={o.id} onClick={openConfirm}>
          CONFIRM
        </TableAction>
      ),
    }));
  }, [orders]);

  return {
    data,
    isLoading,
    isConfirmOpen: Boolean(activeId),
    isConfirming,
    activeTab: status,

    onChangeTab,

    closeConfirm,
    confirm,
  };
}

export default useInstorePickups;
