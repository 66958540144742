import cn from "classnames";

import styles from "./Spinner.module.scss";

interface Props {
  className?: string;
}

function Spinner({ className }: Props) {
  return (
    <div className={cn(styles.root, className)}>
      <div className={styles.spinner}>Loading...</div>
    </div>
  );
}

export default Spinner;
