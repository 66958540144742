import { components, SingleValueProps } from "react-select";
import { SelectOption } from "types";

import styles from "../Filter.module.scss";

function SingleValue({
  children,
  ...props
}: SingleValueProps<SelectOption, false>) {
  return (
    <components.SingleValue {...props}>
      <span className={styles["single-value__label"]}>Filter: </span>
      <span className={styles["single-value__value"]}>{children}</span>
    </components.SingleValue>
  );
}

export default SingleValue;
