import api from 'api';
import {QueryFunctionContext, useQuery} from 'react-query';
import {ListResponse} from 'types';

type PaymentType = {};

async function getMethods({queryKey}: QueryFunctionContext<any[]>) {
  const [,] = queryKey;

  const {data} = await api.get<ListResponse<PaymentType>>(
    '/payment/stripe-payment-methods/',
    {
      params: {},
    }
  );

  return data;
}

function useBilling() {
  const {data, refetch} = useQuery(['methods'], getMethods);

  return {
    refetch,
    data: data?.results || [],
  };
}

export default useBilling;
