const addressTypes = [
  {
    label: 'Dispatch Location',
    value: 'dispatch',
  },
  {
    label: 'Billing Location',
    value: 'billing',
  },
];

export default addressTypes;
