import {Role} from 'types';

const roles: {value: Role; label: string}[] = [
  {value: 'tech', label: 'Technician (driver app)'},
  {value: 'driver', label: 'Driver (driver app)'},
  {value: 'dispatcher', label: 'Dispatcher (dispatch dashboard)'},
  {value: 'management', label: 'Manager (all dashboard)'},
  {value: 'customer_service', label: 'Customer Service (orders)'},
];

export default roles;
