import React, {useEffect, useState} from 'react';
import {QueryFunctionContext, useQuery} from 'react-query';
import ListWrapper from 'components/ListWrapper';
import PageHeader from 'components/PageHeader';
import Typography from 'components/Typography';
import ClickablePagination from 'components/ClickablePagination';
import Modal from 'components/Modal';

import api from 'api';
import {ListResponse, SelectOption} from 'types';

import styles from '../Settings.module.scss';
import {useRouteMatch} from 'react-router-dom';
import Button from 'components/Button';
import FormGroup from '../../../components/FormGroup';
import TextField from '../../../components/TextField';
import FormActionGroup from '../../../components/FormActionGroup';
import companyImage from 'assets/company.jpg';
import stripe from 'assets/stripe.png';
import {Controller, useForm} from 'react-hook-form';
import SelectField from 'components/SelectField';
import {encode} from 'js-base64';

const gateway_types: SelectOption[] = [
  {
    value: 'heartland',
    label: 'Heartland',
  },
  {
    value: 'stripe',
    label: 'Stripe',
  },
];

type FormValues = {
  gateway_type: SelectOption;
  secret_api_key: string;
  public_api_key: string;
  developer_id: string;
  version_number: string;
  stripe_secret_api_key: string;
  stripe_public_key: string;
};

type GatewayResponse = {
  company_id: number;
  company_name: string;
  id: number;
  gateway_type: string;
};

async function getGateways({queryKey}: QueryFunctionContext<string[]>) {
  const [, page] = queryKey;
  const {data} = await api.get<ListResponse<GatewayResponse>>(
    `/payment/gateways/`,
    {
      params: {
        limit: 20,
        offset: +page * 20,
      },
    }
  );

  return data;
}

function Users() {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isDeleteVisible, setIsDeleteVisible] = useState(false);
  const {params} = useRouteMatch<{page: string | undefined}>();
  const [currentPage, setCurrentPage] = useState(parseInt(params.page || '1'));
  const [submitting, setSubmitting] = useState(false);
  const {data, isLoading, refetch} = useQuery(
    ['users', String(currentPage - 1 || 0)],
    getGateways
  );
  const [currentGateWay, setCurrentGateWay] = useState<GatewayResponse>();

  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    formState: {errors},
  } = useForm<FormValues>({
    defaultValues: {
      gateway_type: gateway_types[0],
    },
  });

  useEffect(() => {
    if (params.page) {
      setCurrentPage(parseInt(params.page));
    }
  }, [params]);

  const handleOrdersClose = () => {
    setModalOpen(false);
  };
  const submithandler = (data: FormValues) => {
    setSubmitting(true);
    api
      .post('/payment/gateways/', {
        gateway_type: data.gateway_type.value,
        credentials:
          data.gateway_type.value === 'heartland'
            ? {
                HEARTLAND_SECRET_API_KEY: encode(data.secret_api_key),
                HEARTLAND_PUBLIC_API_KEY: encode(data.public_api_key),
                HEARTLAND_DEVELOPER_ID: encode(data.developer_id),
                HEARTLAND_VERSION_NUMBER: encode(data.version_number),
              }
            : {
                STRIPE_SECRET_API_KEY: encode(data.stripe_secret_api_key),
                STRIPE_PUBLIC_API_KEY: encode(data.stripe_public_key),
              },
      })
      .then(() => {
        refetch();
        reset();
        setModalOpen(false);
      })
      .catch(() => {})
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleDelete = async () => {
    try {
      await api.delete(`/payment/gateways/${currentGateWay?.id}/`);
      refetch();
      setIsDeleteVisible(false);
    } catch (error) {}
  };

  return (
    <div className={styles.main_container}>
      <PageHeader className={styles.top_header}>
        <Typography variant="h2">Payment gateway</Typography>
        {data?.results && data?.results?.length < 1 && (
          <Button color="green" size="sm" onClick={() => setModalOpen(true)}>
            Add New Gateway
          </Button>
        )}
      </PageHeader>
      <div style={{height: 20}} />

      {data?.results.map((item) => {
        return (
          <ListWrapper
            onClick={() => {
              setCurrentGateWay(item);
              setIsDeleteVisible(true);
            }}
            key={item.id}
            style={{
              display: 'flex',
              paddingLeft: 20,
              paddingRight: 20,
              marginTop: 25,
              justifyContent: 'space-between',
            }}
          >
            <div>
              <h2>{item.gateway_type === 'stripe' ? 'Stripe' : 'Heartland'}</h2>
              <p style={{fontSize: 20}}>Company Name: {item.company_name}</p>
            </div>
            <img
              alt={`gateway ${item.id}`}
              style={{
                width: 200,
                height: 75,
              }}
              src={item.gateway_type === 'stripe' ? stripe : companyImage}
            />
          </ListWrapper>
        );
      })}
      <ClickablePagination
        page={currentPage}
        total={Math.round((data?.count || 0) / 20)}
        setCurrentPage={setCurrentPage}
      />
      <Modal
        isOpen={isModalOpen}
        onClose={handleOrdersClose}
        className={styles.list_wrapper}
      >
        <div className={styles.formWrapper}>
          <FormGroup columns={1}>
            <Controller
              name="gateway_type"
              control={control}
              defaultValue={gateway_types[0]}
              rules={{required: true}}
              render={({field}) => (
                <SelectField
                  label="Gateway type"
                  placeholder="Click to select"
                  options={gateway_types}
                  error={errors.gateway_type?.message}
                  {...field}
                />
              )}
            />
          </FormGroup>
          {watch('gateway_type')?.value === 'heartland' ? (
            <>
              <FormGroup columns={1}>
                <TextField
                  label="Secret api key"
                  placeholder="Enter api key"
                  {...register('secret_api_key', {required: 'Required'})}
                  error={errors.secret_api_key?.message}
                />
              </FormGroup>
              <FormGroup columns={1}>
                <TextField
                  label="Public api key"
                  placeholder="Enter public api key"
                  {...register('public_api_key', {required: 'Required'})}
                  error={errors.public_api_key?.message}
                />
              </FormGroup>
              <FormGroup columns={1}>
                <TextField
                  label="Developer id"
                  placeholder="Enter id"
                  {...register('developer_id', {required: 'Required'})}
                  error={errors.developer_id?.message}
                />
              </FormGroup>
              <FormGroup columns={1}>
                <TextField
                  label="Version number"
                  placeholder="Enter number"
                  {...register('version_number', {required: 'Required'})}
                  error={errors.version_number?.message}
                />
              </FormGroup>
            </>
          ) : (
            <>
              <FormGroup columns={1}>
                <TextField
                  label="Stripe secret api key"
                  placeholder="Enter api key"
                  {...register('stripe_secret_api_key', {required: 'Required'})}
                  error={errors.stripe_secret_api_key?.message}
                />
              </FormGroup>
              <FormGroup columns={1}>
                <TextField
                  label="Stripe public api key"
                  placeholder="Enter public key"
                  {...register('stripe_public_key', {required: 'Required'})}
                  error={errors.stripe_public_key?.message}
                />
              </FormGroup>
            </>
          )}

          <FormActionGroup className={styles.buttonWrap}>
            <Button
              onClick={() => setModalOpen(false)}
              component="a"
              color="gray"
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit(submithandler)}
              type="submit"
              color="green"
              loading={submitting || isLoading}
            >
              Save
            </Button>
          </FormActionGroup>
        </div>
      </Modal>
      <Modal
        isOpen={isDeleteVisible}
        onClose={() => setIsDeleteVisible(false)}
        className={styles.list_wrapper}
      >
        <div className={styles.header}>
          <div className={styles.header__title}>
            <Typography variant="h2">
              Do you want to delete this gateway?
            </Typography>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: 40,
              marginBottom: 30,
            }}
          >
            <div style={{marginTop: 15}}>
              <Typography
                style={{
                  fontSize: 20,
                  textTransform: 'capitalize',
                }}
                variant="p"
              >
                {currentGateWay?.gateway_type}
              </Typography>
              <Typography
                style={{
                  fontSize: 18,
                  textTransform: 'capitalize',
                  marginTop: 15,
                }}
                variant="p"
              >
                Company ID: {currentGateWay?.company_id}
              </Typography>
            </div>
            <img
              alt={`gateway ${currentGateWay?.id}`}
              style={{
                width: 200,
                height: 75,
              }}
              src={
                currentGateWay?.gateway_type === 'stripe'
                  ? stripe
                  : companyImage
              }
            />
          </div>
          <FormActionGroup className={styles.buttonWrap}>
            <Button
              onClick={() => setIsDeleteVisible(false)}
              component="a"
              color="gray"
            >
              Cancel
            </Button>
            <Button onClick={handleDelete} type="submit" color="danger">
              Delete
            </Button>
          </FormActionGroup>
        </div>
      </Modal>
    </div>
  );
}

export default Users;
