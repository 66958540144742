import cn from "classnames";

import { StatusColor } from "types";

import styles from "./StatusBadge.module.scss";

interface Props {
  children: React.ReactNode;
  color: StatusColor;
}

function StatusBadge({ children, color }: Props) {
  return (
    <div className={cn(styles.root, styles[`--${color}`])}>{children}</div>
  );
}

export default StatusBadge;
