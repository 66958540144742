import useAuth from "hooks/useAuth";

import Logo from "components/Logo";
import Spinner from "components/Spinner";

import AuthApp from "app/AuthApp";
import MainApp from "app/MainApp";

import "app/App.scss";

function Loading() {
  return (
    <div className="content --full-width --center --column">
      <Logo />
      <Spinner />
    </div>
  );
}

function useApp() {
  const { loading, currentUser } = useAuth();

  if (loading) return <Loading />;

  if (currentUser) {
    return <MainApp />;
  } else {
    return <AuthApp />;
  }
}

function App() {
  const app = useApp();
  return <div className="App">{app}</div>;
}

export default App;
