import React from 'react';
import cn from 'classnames';

import styles from './FormGroup.module.scss';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  columns: number;
}

function FormGroup({className, columns, ...restProps}: Props) {
  return (
    <div
      className={cn(styles.root, className, styles[`columns-${columns}`])}
      {...restProps}
    />
  );
}

FormGroup.defaultProps = {
  columns: 1,
};

export default FormGroup;
