const nyPostalCodeBoroughOptions = [
  {
    label: 'Queens',
    value: '11372',
  },
  {
    label: 'Queens',
    value: '11004',
  },
  {
    label: 'Queens',
    value: '11040',
  },
  {
    label: 'Queens',
    value: '11426',
  },
  {
    label: 'Queens',
    value: '11365',
  },
  {
    label: 'Queens',
    value: '11373',
  },
  {
    label: 'Queens',
    value: '11001',
  },
  {
    label: 'Queens',
    value: '11375',
  },
  {
    label: 'Queens',
    value: '11427',
  },
  {
    label: 'Queens',
    value: '11374',
  },
  {
    label: 'Queens',
    value: '11366',
  },
  {
    label: 'Queens',
    value: '11423',
  },
  {
    label: 'Queens',
    value: '11428',
  },
  {
    label: 'Queens',
    value: '11432',
  },
  {
    label: 'Queens',
    value: '11379',
  },
  {
    label: 'Queens',
    value: '11429',
  },
  {
    label: 'Queens',
    value: '11435',
  },
  {
    label: 'Queens',
    value: '11415',
  },
  {
    label: 'Queens',
    value: '11418',
  },
  {
    label: 'Queens',
    value: '11433',
  },
  {
    label: 'Queens',
    value: '11451',
  },
  {
    label: 'Brooklyn',
    value: '11221',
  },
  {
    label: 'Queens',
    value: '11421',
  },
  {
    label: 'Queens',
    value: '11419',
  },
  {
    label: 'Queens',
    value: '11434',
  },
  {
    label: 'Brooklyn',
    value: '11216',
  },
  {
    label: 'Queens',
    value: '11416',
  },
  {
    label: 'Brooklyn',
    value: '11233',
  },
  {
    label: 'Queens',
    value: '11436',
  },
  {
    label: 'Brooklyn',
    value: '11213',
  },
  {
    label: 'Brooklyn',
    value: '11212',
  },
  {
    label: 'Brooklyn',
    value: '11225',
  },
  {
    label: 'Brooklyn',
    value: '11218',
  },
  {
    label: 'Brooklyn',
    value: '11226',
  },
  {
    label: 'Brooklyn',
    value: '11219',
  },
  {
    label: 'Brooklyn',
    value: '11210',
  },
  {
    label: 'Brooklyn',
    value: '11230',
  },
  {
    label: 'Brooklyn',
    value: '11204',
  },
  {
    label: 'Bronx',
    value: '10471',
  },
  {
    label: 'Bronx',
    value: '10470',
  },
  {
    label: 'Bronx',
    value: '10466',
  },
  {
    label: 'Bronx',
    value: '10467',
  },
  {
    label: 'Bronx',
    value: '10463',
  },
  {
    label: 'Bronx',
    value: '10475',
  },
  {
    label: 'Bronx',
    value: '10464',
  },
  {
    label: 'Bronx',
    value: '10469',
  },
  {
    label: 'Bronx',
    value: '10468',
  },
  {
    label: 'Manhattan',
    value: '10463',
  },
  {
    label: 'Bronx',
    value: '10458',
  },
  {
    label: 'Manhattan',
    value: '10034',
  },
  {
    label: 'Manhattan',
    value: '10033',
  },
  {
    label: 'Bronx',
    value: '10462',
  },
  {
    label: 'Manhattan',
    value: '10040',
  },
  {
    label: 'Bronx',
    value: '10453',
  },
  {
    label: 'Bronx',
    value: '10465',
  },
  {
    label: 'Bronx',
    value: '10464',
  },
  {
    label: 'Bronx',
    value: '10464',
  },
  {
    label: 'Bronx',
    value: '10461',
  },
  {
    label: 'Bronx',
    value: '10457',
  },
  {
    label: 'Bronx',
    value: '10460',
  },
  {
    label: 'Manhattan',
    value: '10032',
  },
  {
    label: 'Bronx',
    value: '10452',
  },
  {
    label: 'Bronx',
    value: '10456',
  },
  {
    label: 'Bronx',
    value: '10472',
  },
  {
    label: 'Manhattan',
    value: '10031',
  },
  {
    label: 'Manhattan',
    value: '10039',
  },
  {
    label: 'Bronx',
    value: '10459',
  },
  {
    label: 'Bronx',
    value: '10451',
  },
  {
    label: 'Bronx',
    value: '10473',
  },
  {
    label: 'Manhattan',
    value: '10030',
  },
  {
    label: 'Manhattan',
    value: '10027',
  },
  {
    label: 'Bronx',
    value: '10474',
  },
  {
    label: 'Bronx',
    value: '10455',
  },
  {
    label: 'Manhattan',
    value: '10037',
  },
  {
    label: 'Manhattan',
    value: '10024',
  },
  {
    label: 'Bronx',
    value: '10454',
  },
  {
    label: 'Manhattan',
    value: '10026',
  },
  {
    label: 'Manhattan',
    value: '10035',
  },
  {
    label: 'Manhattan',
    value: '10025',
  },
  {
    label: 'Manhattan',
    value: '10035',
  },
  {
    label: 'Queens',
    value: '11357',
  },
  {
    label: 'Manhattan',
    value: '10029',
  },
  {
    label: 'Manhattan',
    value: '00083',
  },
  {
    label: 'Queens',
    value: '11356',
  },
  {
    label: 'Queens',
    value: '11359',
  },
  {
    label: 'Queens',
    value: '11360',
  },
  {
    label: 'Queens',
    value: '11105',
  },
  {
    label: 'Manhattan',
    value: '10128',
  },
  {
    label: 'Queens',
    value: '11371',
  },
  {
    label: 'Manhattan',
    value: '10023',
  },
  {
    label: 'Queens',
    value: '11363',
  },
  {
    label: 'Manhattan',
    value: '10028',
  },
  {
    label: 'Queens',
    value: '11354',
  },
  {
    label: 'Queens',
    value: '11102',
  },
  {
    label: 'Queens',
    value: '11370',
  },
  {
    label: 'Manhattan',
    value: '10021',
  },
  {
    label: 'Queens',
    value: '11361',
  },
  {
    label: 'Queens',
    value: '11358',
  },
  {
    label: 'Queens',
    value: '11362',
  },
  {
    label: 'Manhattan',
    value: '10044',
  },
  {
    label: 'Queens',
    value: '11369',
  },
  {
    label: 'Queens',
    value: '11103',
  },
  {
    label: 'Queens',
    value: '11106',
  },
  {
    label: 'Queens',
    value: '11368',
  },
  {
    label: 'Queens',
    value: '11377',
  },
  {
    label: 'Manhattan',
    value: '10036',
  },
  {
    label: 'Queens',
    value: '11355',
  },
  {
    label: 'Queens',
    value: '11101',
  },
  {
    label: 'Queens',
    value: '11364',
  },
  {
    label: 'Manhattan',
    value: '10018',
  },
  {
    label: 'Manhattan',
    value: '10020',
  },
  {
    label: 'Queens',
    value: '11005',
  },
  {
    label: 'Manhattan',
    value: '10017',
  },
  {
    label: 'Manhattan',
    value: '10001',
  },
  {
    label: 'Manhattan',
    value: '10011',
  },
  {
    label: 'Manhattan',
    value: '10016',
  },
  {
    label: 'Queens',
    value: '11104',
  },
  {
    label: 'Queens',
    value: '11109',
  },
  {
    label: 'Manhattan',
    value: '10010',
  },
  {
    label: 'Queens',
    value: '11367',
  },
  {
    label: 'Manhattan',
    value: '10014',
  },
  {
    label: 'Manhattan',
    value: '10003',
  },
  {
    label: 'Brooklyn',
    value: '11222',
  },
  {
    label: 'Manhattan',
    value: '10002',
  },
  {
    label: 'Queens',
    value: '11378',
  },
  {
    label: 'Manhattan',
    value: '10009',
  },
  {
    label: 'Manhattan',
    value: '10012',
  },
  {
    label: 'Manhattan',
    value: '10013',
  },
  {
    label: 'Brooklyn',
    value: '11211',
  },
  {
    label: 'Manhattan',
    value: '10007',
  },
  {
    label: 'Brooklyn',
    value: '11237',
  },
  {
    label: 'Queens',
    value: '11385',
  },
  {
    label: 'Manhattan',
    value: '10038',
  },
  {
    label: 'Brooklyn',
    value: '11206',
  },
  {
    label: 'Manhattan',
    value: '10006',
  },
  {
    label: 'Queens',
    value: '11412',
  },
  {
    label: 'Manhattan',
    value: '10005',
  },
  {
    label: 'Brooklyn',
    value: '11251',
  },
  {
    label: 'Manhattan',
    value: '10004',
  },
  {
    label: 'Queens',
    value: '11411',
  },
  {
    label: 'Brooklyn',
    value: '11201',
  },
  {
    label: 'Manhattan',
    value: '10004',
  },
  {
    label: 'Brooklyn',
    value: '11205',
  },
  {
    label: 'Brooklyn',
    value: '11208',
  },
  {
    label: 'Brooklyn',
    value: '11207',
  },
  {
    label: 'Manhattan',
    value: '10004',
  },
  {
    label: 'Manhattan',
    value: '10004',
  },
  {
    label: 'Queens',
    value: '11413',
  },
  {
    label: 'Brooklyn',
    value: '11217',
  },
  {
    label: 'Brooklyn',
    value: '11238',
  },
  {
    label: 'Brooklyn',
    value: '11231',
  },
  {
    label: 'Queens',
    value: '11422',
  },
  {
    label: 'Queens',
    value: '11420',
  },
  {
    label: 'Queens',
    value: '11417',
  },
  {
    label: 'Brooklyn',
    value: '11215',
  },
  {
    label: 'Queens',
    value: '11414',
  },
  {
    label: 'Brooklyn',
    value: '11231',
  },
  {
    label: 'Brooklyn',
    value: '11232',
  },
  {
    label: 'Queens',
    value: '11430',
  },
  {
    label: 'Brooklyn',
    value: '11203',
  },
  {
    label: 'Brooklyn',
    value: '11239',
  },
  {
    label: 'Brooklyn',
    value: '11236',
  },
  {
    label: 'Brooklyn',
    value: '11220',
  },
  {
    label: 'Staten Island',
    value: '10301',
  },
  {
    label: 'Staten Island',
    value: '10310',
  },
  {
    label: 'Staten Island',
    value: '10303',
  },
  {
    label: 'Brooklyn',
    value: '11234',
  },
  {
    label: 'Staten Island',
    value: '10302',
  },
  {
    label: 'Queens',
    value: '11693',
  },
  {
    label: 'Brooklyn',
    value: '11209',
  },
  {
    label: 'Staten Island',
    value: '10304',
  },
  {
    label: 'Staten Island',
    value: '10314',
  },
  {
    label: 'Brooklyn',
    value: '11693',
  },
  {
    label: 'Brooklyn',
    value: '11228',
  },
  {
    label: 'Queens',
    value: '11096',
  },
  {
    label: 'Staten Island',
    value: '10305',
  },
  {
    label: 'Brooklyn',
    value: '11229',
  },
  {
    label: 'Brooklyn',
    value: '11214',
  },
  {
    label: 'Queens',
    value: '11691',
  },
  {
    label: 'Queens',
    value: '11096',
  },
  {
    label: 'Brooklyn',
    value: '11223',
  },
  {
    label: 'Queens',
    value: '11693',
  },
  {
    label: 'Queens',
    value: '11692',
  },
  {
    label: 'Brooklyn',
    value: '11235',
  },
  {
    label: 'Queens',
    value: '11693',
  },
  {
    label: 'Staten Island',
    value: '10306',
  },
  {
    label: 'Queens',
    value: '11694',
  },
  {
    label: 'Brooklyn',
    value: '11224',
  },
  {
    label: 'Staten Island',
    value: '10308',
  },
  {
    label: 'Queens',
    value: '11697',
  },
  {
    label: 'Staten Island',
    value: '10312',
  },
  {
    label: 'Staten Island',
    value: '10309',
  },
  {
    label: 'Staten Island',
    value: '10307',
  },
  {
    label: 'Manhattan',
    value: '10280',
  },
  {
    label: 'Manhattan',
    value: '10048',
  },
  {
    label: 'Manhattan',
    value: '10279',
  },
  {
    label: 'Manhattan',
    value: '10165',
  },
  {
    label: 'Manhattan',
    value: '10168',
  },
  {
    label: 'Manhattan',
    value: '10055',
  },
  {
    label: 'Manhattan',
    value: '10105',
  },
  {
    label: 'Manhattan',
    value: '10118',
  },
  {
    label: 'Manhattan',
    value: '10176',
  },
  {
    label: 'Manhattan',
    value: '10162',
  },
  {
    label: 'Manhattan',
    value: '10019',
  },
  {
    label: 'Manhattan',
    value: '10111',
  },
  {
    label: 'Manhattan',
    value: '10170',
  },
  {
    label: 'Manhattan',
    value: '10112',
  },
  {
    label: 'Manhattan',
    value: '10122',
  },
  {
    label: 'Manhattan',
    value: '10107',
  },
  {
    label: 'Manhattan',
    value: '10103',
  },
  {
    label: 'Manhattan',
    value: '10153',
  },
  {
    label: 'Manhattan',
    value: '10174',
  },
  {
    label: 'Manhattan',
    value: '10166',
  },
  {
    label: 'Manhattan',
    value: '10169',
  },
  {
    label: 'Manhattan',
    value: '10167',
  },
  {
    label: 'Manhattan',
    value: '10177',
  },
  {
    label: 'Manhattan',
    value: '10172',
  },
  {
    label: 'Manhattan',
    value: '10171',
  },
  {
    label: 'Manhattan',
    value: '10154',
  },
  {
    label: 'Manhattan',
    value: '10152',
  },
  {
    label: 'Manhattan',
    value: '10270',
  },
  {
    label: 'Manhattan',
    value: '10104',
  },
  {
    label: 'Manhattan',
    value: '10271',
  },
  {
    label: 'Manhattan',
    value: '10110',
  },
  {
    label: 'Manhattan',
    value: '10175',
  },
  {
    label: 'Manhattan',
    value: '10151',
  },
  {
    label: 'Manhattan',
    value: '10173',
  },
  {
    label: 'Manhattan',
    value: '10178',
  },
  {
    label: 'Manhattan',
    value: '10119',
  },
  {
    label: 'Manhattan',
    value: '10121',
  },
  {
    label: 'Manhattan',
    value: '10115',
  },
  {
    label: 'Manhattan',
    value: '10123',
  },
  {
    label: 'Manhattan',
    value: '10106',
  },
  {
    label: 'Manhattan',
    value: '10158',
  },
  {
    label: 'Manhattan',
    value: '10041',
  },
  {
    label: 'Manhattan',
    value: '10120',
  },
  {
    label: 'Manhattan',
    value: '10278',
  },
  {
    label: 'Manhattan',
    value: '10155',
  },
  {
    label: 'Manhattan',
    value: '10022',
  },
  {
    label: 'Manhattan',
    value: '10043',
  },
  {
    label: 'Manhattan',
    value: '10081',
  },
  {
    label: 'Manhattan',
    value: '10096',
  },
  {
    label: 'Manhattan',
    value: '10097',
  },
  {
    label: 'Manhattan',
    value: '10196',
  },
  {
    label: 'Manhattan',
    value: '10196',
  },
  {
    label: 'Manhattan',
    value: '10275',
  },
  {
    label: 'Manhattan',
    value: '10265',
  },
  {
    label: 'Manhattan',
    value: '10045',
  },
  {
    label: 'Manhattan',
    value: '10047',
  },
  {
    label: 'Manhattan',
    value: '10047',
  },
  {
    label: 'Manhattan',
    value: '10080',
  },
  {
    label: 'Manhattan',
    value: '10203',
  },
  {
    label: 'Manhattan',
    value: '10259',
  },
  {
    label: 'Manhattan',
    value: '10260',
  },
  {
    label: 'Manhattan',
    value: '10285',
  },
  {
    label: 'Manhattan',
    value: '10286',
  },
  {
    label: 'Bronx',
    value: '11370',
  },
  {
    label: 'Manhattan',
    value: '10065',
  },
  {
    label: 'Manhattan',
    value: '10075',
  },
  {
    label: 'Manhattan',
    value: '10069',
  },
  {
    label: 'Manhattan',
    value: '10281',
  },
  {
    label: 'Manhattan',
    value: '10282',
  },
  {
    label: 'New Jersey',
    value: '07020',
  },
  {
    label: 'New Jersey',
    value: '07022',
  },
  {
    label: 'New Jersey',
    value: '07024',
  },
  {
    label: 'New Jersey',
    value: '07026',
  },
  {
    label: 'NY Upstate',
    value: '12260',
  },
  {
    label: 'NY Upstate',
    value: '12250',
  },
  {
    label: 'NY Upstate',
    value: '14539',
  },
  {
    label: 'NY Upstate',
    value: '13475',
  },
  {
    label: 'NY Upstate',
    value: '12232',
  },
  {
    label: 'NY Upstate',
    value: '12230',
  },
  {
    label: 'NY Upstate',
    value: '12220',
  },
  {
    label: 'NY Upstate',
    value: '14112',
  },
  {
    label: 'NY Upstate',
    value: '14169',
  },
  {
    label: 'NY Upstate',
    value: '14038',
  },
  {
    label: 'NY Upstate',
    value: '13763',
  },
  {
    label: 'NY Upstate',
    value: '13024',
  },
  {
    label: 'NY Upstate',
    value: '12201',
  },
  {
    label: 'NY Upstate',
    value: '12233',
  },
  {
    label: 'NY Upstate',
    value: '12195',
  },
  {
    label: 'NY Upstate',
    value: '13860',
  },
  {
    label: 'NY Upstate',
    value: '12214',
  },
  {
    label: 'NY Upstate',
    value: '12226',
  },
  {
    label: 'NY Upstate',
    value: '12123',
  },
  {
    label: 'NY Upstate',
    value: '14110',
  },
  {
    label: 'NY Upstate',
    value: '14151',
  },
  {
    label: 'NY Upstate',
    value: '14027',
  },
  {
    label: 'NY Upstate',
    value: '13599',
  },
  {
    label: 'NY Upstate',
    value: '12212',
  },
  {
    label: 'NY Upstate',
    value: '12242',
  },
  {
    label: 'NY Upstate',
    value: '12234',
  },
  {
    label: 'NY Upstate',
    value: '12128',
  },
  {
    label: 'NY Upstate',
    value: '12345',
  },
  {
    label: 'NY Upstate',
    value: '12261',
  },
  {
    label: 'NY Upstate',
    value: '12225',
  },
  {
    label: 'NY Upstate',
    value: '14902',
  },
  {
    label: 'NY Upstate',
    value: '14694',
  },
  {
    label: 'NY Upstate',
    value: '14140',
  },
  {
    label: 'NY Upstate',
    value: '14010',
  },
  {
    label: 'NY Upstate',
    value: '13449',
  },
  {
    label: 'Long Island',
    value: '11101',
  },
  {
    label: 'Long Island',
    value: '11802',
  },
  {
    label: 'Long Island',
    value: '00501',
  },
  {
    label: 'Long Island',
    value: '11120',
  },
  {
    label: 'Long Island',
    value: '11551',
  },
  {
    label: 'Long Island',
    value: '11022',
  },
  {
    label: 'Long Island',
    value: '11103',
  },
  {
    label: 'Long Island',
    value: '11767',
  },
  {
    label: 'Long Island',
    value: '11956',
  },
  {
    label: 'Long Island',
    value: '11256',
  },
  {
    label: 'Long Island',
    value: '11973',
  },
  {
    label: 'Long Island',
    value: '11760',
  },
  {
    label: 'Long Island',
    value: '11737',
  },
  {
    label: 'Long Island',
    value: '11775',
  },
  {
    label: 'Long Island',
    value: '11002',
  },
  {
    label: 'Long Island',
    value: '11027',
  },
  {
    label: 'Long Island',
    value: '11582',
  },
  {
    label: 'Long Island',
    value: '11707',
  },
  {
    label: 'Long Island',
    value: '11380',
  },
  {
    label: 'Long Island',
    value: '11431',
  },
  {
    label: 'Long Island',
    value: '11554',
  },
  {
    label: 'Long Island',
    value: '06390',
  },
  {
    label: 'Long Island',
    value: '11969',
  },
  {
    label: 'Long Island',
    value: '00544',
  },
  {
    label: 'Long Island',
    value: '11109',
  },
  {
    label: 'Long Island',
    value: '11571',
  },
  {
    label: 'Long Island',
    value: '11026',
  },
  {
    label: 'Long Island',
    value: '11531',
  },
  {
    label: 'Long Island',
    value: '11105',
  },
  {
    label: 'Long Island',
    value: '11352',
  },
  {
    label: 'Long Island',
    value: '11359',
  },
  {
    label: 'Long Island',
    value: '11386',
  },
  {
    label: 'Long Island',
    value: '67647',
  },
  {
    label: 'Connecticut',
    value: '06001',
  },
  {
    label: 'Connecticut',
    value: '06002',
  },
  {
    label: 'Connecticut',
    value: '06010',
  },
  {
    label: 'Connecticut',
    value: '060016',
  },
  {
    label: 'Connecticut',
    value: '06019',
  },
  {
    label: 'Connecticut',
    value: '06020',
  },
  {
    label: 'Connecticut',
    value: '06021',
  },
  {
    label: 'Connecticut',
    value: '06022',
  },
  {
    label: 'Connecticut',
    value: '06023',
  },
  {
    label: 'Connecticut',
    value: '06024',
  },
  {
    label: 'Connecticut',
    value: '06025',
  },
  {
    label: 'Connecticut',
    value: '06026',
  },
  {
    label: 'Connecticut',
    value: '06027',
  },
  {
    label: 'Connecticut',
    value: '06028',
  },
  {
    label: 'Connecticut',
    value: '06029',
  },
  {
    label: 'Connecticut',
    value: '06031',
  },
  {
    label: 'Connecticut',
    value: '06032',
  },
  {
    label: 'Connecticut',
    value: '06033',
  },
  {
    label: 'Connecticut',
    value: '06034',
  },
  {
    label: 'Connecticut',
    value: '06035',
  },
  {
    label: 'Connecticut',
    value: '06037',
  },
  {
    label: 'Connecticut',
    value: '06039',
  },
  {
    label: 'Connecticut',
    value: '06040',
  },
  {
    label: 'Connecticut',
    value: '06043',
  },
  {
    label: 'Connecticut',
    value: '06045',
  },
  {
    label: 'Connecticut',
    value: '06049',
  },
  {
    label: 'Connecticut',
    value: '06050',
  },
  {
    label: 'Connecticut',
    value: '06051',
  },
  {
    label: 'Connecticut',
    value: '06052',
  },
  {
    label: 'Connecticut',
    value: '06053',
  },
  {
    label: 'Connecticut',
    value: '06057',
  },
  {
    label: 'Connecticut',
    value: '06058',
  },
  {
    label: 'Connecticut',
    value: '06059',
  },
  {
    label: 'Connecticut',
    value: '06060',
  },
  {
    label: 'Connecticut',
    value: '06061',
  },
  {
    label: 'Connecticut',
    value: '06062',
  },
  {
    label: 'Connecticut',
    value: '06063',
  },
  {
    label: 'Connecticut',
    value: '06064',
  },
  {
    label: 'Connecticut',
    value: '06065',
  },
  {
    label: 'Connecticut',
    value: '06066',
  },
  {
    label: 'Connecticut',
    value: '06067',
  },
  {
    label: 'Connecticut',
    value: '06068',
  },
  {
    label: 'Connecticut',
    value: '06069',
  },
  {
    label: 'Connecticut',
    value: '06070',
  },
  {
    label: 'Connecticut',
    value: '06071',
  },
  {
    label: 'Connecticut',
    value: '06072',
  },
  {
    label: 'Connecticut',
    value: '06073',
  },
  {
    label: 'Connecticut',
    value: '06074',
  },
  {
    label: 'Connecticut',
    value: '06075',
  },
  {
    label: 'Connecticut',
    value: '06076',
  },
  {
    label: 'Connecticut',
    value: '06077',
  },
  {
    label: 'Connecticut',
    value: '06078',
  },
  {
    label: 'Connecticut',
    value: '06079',
  },
  {
    label: 'Connecticut',
    value: '06081',
  },
  {
    label: 'Connecticut',
    value: '06082',
  },
  {
    label: 'Connecticut',
    value: '06083',
  },
  {
    label: 'Connecticut',
    value: '06084',
  },
  {
    label: 'Connecticut',
    value: '06085',
  },
  {
    label: 'Connecticut',
    value: '06088',
  },
  {
    label: 'Connecticut',
    value: '06089',
  },
  {
    label: 'Connecticut',
    value: '06090',
  },
  {
    label: 'Connecticut',
    value: '06091',
  },
  {
    label: 'Connecticut',
    value: '06092',
  },
  {
    label: 'Connecticut',
    value: '06093',
  },
  {
    label: 'Connecticut',
    value: '06094',
  },
  {
    label: 'Connecticut',
    value: '06095',
  },
  {
    label: 'Connecticut',
    value: '06096',
  },
  {
    label: 'Connecticut',
    value: '06098',
  },
];

export default nyPostalCodeBoroughOptions;
