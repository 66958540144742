import React from "react";
import cn from "classnames";

import styles from "./FormActionGroup.module.scss";

function FormActionGroup({
  className,
  ...restProps
}: React.HTMLAttributes<HTMLDivElement>) {
  return <div className={cn(styles.root, className)} {...restProps} />;
}

export default FormActionGroup;
