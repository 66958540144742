import React from "react";
import cn from "classnames";

import styles from "./Card.module.scss";

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

function Card({ className, children }: Props) {
  return <div className={cn(styles.root, className)}>{children}</div>;
}

export default Card;
