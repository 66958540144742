import { AxiosResponse } from "axios";
import { User } from "types";
import { ApiClient } from "./api-client";

type AuthResponse = {
  token: string;
}

export class AuthService extends ApiClient {
  async login(email: string, password: string): Promise<AxiosResponse<AuthResponse>> {
    return this.postAuth('/users/auth/', {email, password},{headers:{'Authorization':'', 'Accept'
  :
    "application/json, text/plain, */*"}}); //'Authorization':''
  }

  async getProfile(): Promise<AxiosResponse<User>> {
    return this.get('/users/whoami/');
  }
}
