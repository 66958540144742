import { useHistory } from "react-router";

import IconButton from "components/IconButton";

import styles from "./Back.module.scss";


function Back() {
  const { goBack } = useHistory();
  const goBackHandler = () => {
    goBack()
  };

  return (
    <IconButton icon="arrow_left" className={styles.root} onClick={goBackHandler} />
  );
}

export default Back;
