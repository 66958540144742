import {ItemId, DataItem, DataTableSchemaItem} from 'types';

import Header from './components/Header';
import Data from './components/Data';
import Loader from './components/Loader';
import Empty from './components/Empty';

import useDataTable from './useDataTable';

import styles from './DataTable.module.scss';
import classNames from 'classnames';

interface Props {
  schema: DataTableSchemaItem[];
  data: DataItem[];
  selectable: boolean;
  selectedRows?: ItemId[];
  onSelectRow?: (rows: ItemId[]) => any;
  sortBy?: string;
  sortOrder: 'asc' | 'desc';
  onSort?: (sortBy: string, sortOrder: 'asc' | 'desc') => any;
  loading?: boolean;
  onClick?: (item: DataItem) => any;
  containerStyle?: string;
  wrapperStyle?: string;
}

function getTemplateColumns(
  schema: DataTableSchemaItem[],
  selectable: boolean
) {
  const cols = [
    selectable ? 'minmax(32px, auto)' : undefined,
    ...schema.map((s) => s.colWidth || '1fr'),
  ]
    .filter((col) => Boolean(col))
    .join(' ');

  return cols;
}

function DataTable({
  schema,
  data,
  selectable,
  selectedRows,
  sortBy,
  sortOrder,
  onSelectRow,
  onSort,
  loading,
  onClick,
  containerStyle,
  wrapperStyle,
}: Props) {
  const {allSelected, handleSelecAll, handleSelectSingle, handleSort} =
    useDataTable({
      data,
      selectedRows,
      sortBy,
      sortOrder,
      onSelectRow,
      onSort,
    });

  return (
    <div className={classNames(styles.root, containerStyle)}>
      <div
        className={classNames(styles.table, wrapperStyle)}
        style={{gridTemplateColumns: getTemplateColumns(schema, selectable)}}
      >
        <Header
          schema={schema}
          selectable={selectable}
          allSelected={allSelected}
          sortBy={sortBy}
          sortOrder={sortOrder}
          handleSelecAll={handleSelecAll}
          handleSort={handleSort}
          style={{gridTemplateColumns: getTemplateColumns(schema, selectable)}}
        />

        {!loading && (
          <Data
            schema={schema}
            data={data}
            style={{
              cursor: onClick ? 'cursor' : 'auto',
              gridTemplateColumns: getTemplateColumns(schema, selectable),
            }}
            selectable={selectable}
            selectedRows={selectedRows}
            handleSelectSingle={handleSelectSingle}
            onClick={onClick}
          />
        )}
      </div>
      {loading && <Loader />}
      {!loading && data.length === 0 && <Empty />}
    </div>
  );
}

DataTable.defaultProps = {
  selectable: false,
  sortOrder: 'asc',
};

export default DataTable;
