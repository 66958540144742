import {
  Elements,
  CardElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import api from 'api';
import Button from 'components/Button';
import Modal from 'components/Modal';
import {get} from 'lodash';
import {useState} from 'react';
import toast from 'react-hot-toast';
import styles from '../Billing.module.scss';

type Props = {
  isOpen: boolean;
  id: string;
  card: string;
  onClose: () => void;
  refetch: () => void;
};

const stripePromise = loadStripe(
  'pk_test_51Q7zUUQAe4FnsECEBD9njBsuOFHup3jNeEKrBelmp8khWWiZCtLC3vsESbVahSV4AH4crsWY0Os4m1gcarNHkITz00HrRX0eN6'
);

function NewCard({isOpen, onClose, id, card, refetch}: Props) {
  const elements = useElements();
  const stripe = useStripe();
  const [loading, setLoading] = useState(false);
  const [isUpdate, setIsUpdate] = useState(!!id);

  const onSubmit = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    try {
      //@ts-ignore
      const response = await stripe?.createPaymentMethod({
        type: 'card',
        card: elements?.getElement(CardElement),
      });
      if (response?.error) {
        setLoading(false);
        toast.error(response.error.message || 'Something went wrong');
        return;
      }

      if (response?.paymentMethod) {
        await api.post('/payment/stripe-payment-methods/', {
          id: response.paymentMethod.id,
        });
      }
      setLoading(false);
      refetch();
      onClose();
    } catch (error) {
      setLoading(false);
      toast.error(get(error, 'message', '') || 'Something went wrong');
    }
  };

  const deleteHandler = async () => {
    try {
      await api.delete(`/payment/stripe-payment-methods/${id}/`);
      onClose();
      refetch();
    } catch (error) {
      toast.error(
        get(error, 'response.data.message', '') || 'Something went wrong'
      );
    }
  };

  return (
    <Modal
      onClose={onClose}
      onRequestClose={onClose}
      showCloseButton
      isOpen={isOpen}
      onAfterOpen={() => setIsUpdate(!!id)}
      className={styles.container}
    >
      <div className="container">
        <h2 className="text-center">
          {isUpdate ? 'Payment Information' : 'Add Payment Method'}
        </h2>
        <div className="card">
          <form onSubmit={onSubmit}>
            <div style={{height: 20}} />
            {isUpdate && (
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <p style={{fontSize: 20}}>Card:</p>
                <p style={{fontSize: 20}}>**** **** **** {card}</p>
              </div>
            )}
            {isUpdate && (
              <p
                onClick={() => setIsUpdate(false)}
                style={{fontWeight: 'bold', cursor: 'pointer'}}
              >
                +Add new card
              </p>
            )}
            {!isUpdate && <CardElement />}
            <div style={{height: 20}} />
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                gap: 6,
                marginTop: 20,
              }}
            >
              {isUpdate && (
                <Button onClick={deleteHandler} color="danger">
                  Delete
                </Button>
              )}
              {!isUpdate && (
                <Button
                  loading={loading}
                  style={{padding: '0px 60px'}}
                  color="green"
                  type="submit"
                >
                  Add
                </Button>
              )}
            </div>
          </form>
          {/* <Controller
            name="card_number"
            control={control}
            rules={{
              required: 'Please, fill card number',
              minLength: {
                value: 18,
                message: 'Please, fill card number',
              },
            }}
            render={({field, fieldState}) => (
              <TextField
                label="Card number"
                onChange={(event) => {
                  setValue('card_number', cc_format(event.target.value));
                }}
                placeholder="**** **** **** 1234"
                hasError={false}
                value={field.value}
                error={fieldState.error?.message}
              />
            )}
          />
          <FormGroup columns={2}>
            <Controller
              name="expiry_date"
              control={control}
              rules={{
                required: 'Please, fill expiry date',
                minLength: {
                  value: 5,
                  message: 'Please, fill expiry date',
                },
              }}
              render={({field, fieldState}) => (
                <TextField
                  label="Expiry date"
                  placeholder="mm/yy"
                  hasError={false}
                  onChange={(event) => {
                    setValue('expiry_date', dd_format(event.target.value));
                  }}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )}
            />
            <Controller
              name="secure_code"
              control={control}
              rules={{
                required: 'Please, fill secure code',
                minLength: {
                  value: 3,
                  message: 'Please, fill secure code',
                },
              }}
              render={({field, fieldState}) => (
                <TextField
                  maxLength={4}
                  label="Secure code"
                  value={field.value}
                  placeholder="CVC"
                  hasError={false}
                  onChange={(event) => {
                    setValue(
                      'secure_code',
                      event.target.value.replace(/\D/g, '')
                    );
                  }}
                  error={fieldState.error?.message}
                />
              )}
            />
          </FormGroup> */}
        </div>
      </div>
    </Modal>
  );
}

const CardMain = (props: Props) => {
  return (
    <Elements stripe={stripePromise}>
      <NewCard {...props} />
    </Elements>
  );
};

export default CardMain;
