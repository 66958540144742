import Typography from "components/Typography";
import styles from "../DataTable.module.scss";

function Empty() {
  return (
    <div className={styles.empty}>
      <Typography variant="h3">No data</Typography>
      <Typography component="p" variant="label">
        There is no data yet
      </Typography>
    </div>
  );
}

export default Empty;
