import Spinner from "components/Spinner";
import styles from "../DataTable.module.scss";

function Loader() {
  return (
    <div className={styles.loader}>
      <Spinner />
    </div>
  );
}

export default Loader;
