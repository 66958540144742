import React, { HTMLAttributes } from 'react';

type Props = HTMLAttributes<HTMLDivElement> & {
  className?: string;
  width?: number;
  height?: number;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

const NoteIcon: React.FC<Props> = ({className, width, height, onClick, ...props}) => {
  return (
    <div className={className} onClick={onClick} {...props}>
      {/*<svg*/}
      {/*  width={width}*/}
      {/*  height={height}*/}
      {/*  viewBox="0 0 120 130"*/}
      {/*  fill="none"*/}
      {/*  xmlns="http://www.w3.org/2000/svg"*/}
      {/*>*/}
      {/*  <rect*/}
      {/*    x={2}*/}
      {/*    y={2}*/}
      {/*    width={122}*/}
      {/*    height={115}*/}
      {/*    rx={10}*/}
      {/*    stroke="black"*/}
      {/*    strokeWidth={10}*/}
      {/*  />*/}
      {/*  <path*/}
      {/*    d="M74.6542 56.6396C76.0832 58.8701 76.0832 62.4937 74.6542 64.7264L51.9335 100.26C51.2475 101.333 50.3174 101.938 49.3467 101.938C49.3358 101.938 49.3236 101.938 49.3128 101.938L38.686 101.785C36.6902 101.753 35.0782 99.2341 35.0619 96.1152L34.9616 79.4921C34.9534 77.9528 35.3385 76.4792 36.034 75.3914L58.7546 39.8617C60.1823 37.6269 62.5007 37.6269 63.9284 39.8617L74.6542 56.6396ZM89.0122 34.1857L78.2837 17.4078C76.9102 15.2599 74.4806 15.2599 73.1113 17.4078L71.3948 20.0899C70.7088 21.1628 70.3224 22.6173 70.3224 24.1355C70.3224 25.6515 70.7088 27.1081 71.3948 28.181L82.1233 44.9589C82.8351 46.0763 83.7733 46.636 84.7102 46.636C85.6457 46.636 86.5839 46.0763 87.2957 44.9589L89.0122 42.2767C90.4412 40.044 90.4412 36.4204 89.0122 34.1857ZM70.3088 29.8815C68.8798 27.6467 66.5613 27.6467 65.135 29.8815C63.706 32.1141 63.706 35.7377 65.135 37.9704L75.8622 54.7483C76.5767 55.8657 77.5163 56.4254 78.4491 56.4254C79.3859 56.4254 80.3228 55.8678 81.0346 54.7483C82.465 52.5156 82.465 48.892 81.0346 46.6593L70.3088 29.8815Z"*/}
      {/*    fill="black"*/}
      {/*  />*/}
      {/*</svg>*/}
      <svg
        fill="#000000"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 50 50"
        xmlSpace="preserve"
        width={width}
        height={height}
      >
        <g>
          <path d="M29.276,19.278c1.054,1.052,1.054,2.761,0,3.814L12.518,39.851c-0.506,0.506-1.192,0.791-1.908,0.791 c-0.008,0-0.017,0-0.025,0l-7.838-0.072c-1.472-0.015-2.661-1.203-2.673-2.674L0,30.056c-0.006-0.726,0.278-1.421,0.791-1.934 l16.758-16.757c1.053-1.054,2.763-1.054,3.816,0L29.276,19.278z M39.866,8.688l-7.913-7.913c-1.013-1.013-2.805-1.013-3.815,0 L26.872,2.04c-0.506,0.506-0.791,1.192-0.791,1.908c0,0.715,0.285,1.402,0.791,1.908l7.913,7.913 c0.525,0.527,1.217,0.791,1.908,0.791c0.69,0,1.382-0.264,1.907-0.791l1.266-1.265C40.92,11.451,40.92,9.742,39.866,8.688z  M26.071,6.658c-1.054-1.054-2.764-1.054-3.816,0c-1.054,1.053-1.054,2.762,0,3.815l7.912,7.913 c0.527,0.527,1.22,0.791,1.908,0.791c0.691,0,1.382-0.263,1.907-0.791c1.055-1.053,1.055-2.762,0-3.815L26.071,6.658z" />
        </g>
      </svg>
    </div>
  );
};

NoteIcon.defaultProps = {
  width: 14,
  height: 14,
};

export default NoteIcon;

