import FieldWrapper from "components/FieldWrapper";
import Select, { Props as SelectProps } from "components/Select";

interface Props extends SelectProps {
  label?: string;
  error?: string;
}
function SelectField({ label, error, ...restProps }: Props) {
  return (
    <FieldWrapper label={label} error={error}>
      <Select {...restProps} />
    </FieldWrapper>
  );
}

SelectField.defaultProps = {
  size: "md",
};

export default SelectField;
