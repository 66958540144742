import useDebounce from 'hooks/useDebounce';
import useProductList, {ProductTypeFilter} from 'hooks/useProductList';
import React, {useEffect, useState} from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';
import Products from './Products';

type Props = {};

const ProductsContainer: React.VFC<Props> = () => {
  const {params} = useRouteMatch<{page: string | undefined}>();
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(parseInt(params.page || '1'));
  const [productType, setProductType] = useState<ProductTypeFilter>('');
  const [search, setSearch] = useState('');
  const searchDebounced = useDebounce(search, 400);

  const {products, totalPages, refetch} = useProductList({
    page: currentPage - 1,
    search: searchDebounced ? searchDebounced : undefined,
    offering_type: productType,
  });

  useEffect(() => {
    setCurrentPage(1);
    history.replace(`/products/page/1`);
  }, [history, searchDebounced]);

  useEffect(() => {
    if (params.page) {
      setCurrentPage(parseInt(params.page));
    }
  }, [params.page]);

  return (
    <Products
      search={search}
      refetch={refetch}
      products={products}
      productType={productType}
      onProductTypeChange={setProductType}
      onSearchChange={setSearch}
      totalPages={totalPages}
      currentPage={currentPage}
    />
  );
};

export default ProductsContainer;
