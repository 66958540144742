import api from 'api';
import Button from 'components/Button';
import Select from 'components/Select';
import useAuth from 'hooks/useAuth';
import {get} from 'lodash';
import {useState} from 'react';
import toast from 'react-hot-toast';
import {useHistory} from 'react-router-dom';
import {SelectOption} from 'types';

const UpdatePlan = () => {
  const {currentUser, refetch} = useAuth();
  const {goBack} = useHistory();
  // State to manage the number of seats
  const [seats, setSeats] = useState<SelectOption>({
    label: get(currentUser, 'company.plan.subscription_quantity', 1),
    value: get(currentUser, 'company.plan.subscription_quantity', 1),
  });
  const pricePerSeat = 11.0; // Assuming $11 per seat
  const salesTaxRate = 0.0875;

  // Calculate pricing
  const seatCost = Number(seats.value) * pricePerSeat;
  const salesTax = seatCost * salesTaxRate;
  const totalPerMonth = seatCost + salesTax;

  const updatePlan = async () => {
    try {
      await api.put('/payment/stripe-subscription-management/', {
        product_id: get(currentUser, 'company.plan.plan_id', ''),
        quantity: seats.value,
      });
      setTimeout(() => {
        refetch(false);
        goBack();
      }, 1000);
    } catch (error) {
      toast.error(get(error, 'response.data.message', 'Error'));
    }
  };

  return (
    <div style={styles.container}>
      <h1>Update your {get(currentUser, 'company.plan.plan_name', '')} plan</h1>

      <p>
        Your plan has <strong>{seats.value} seats</strong> right now, with{' '}
        <strong>{seats.value} active people</strong> on your team filling seats.
        If you need more seats, you can add them here.
      </p>

      <div style={styles.card}>
        <label htmlFor="seats">
          <p>How many total seats do you need?</p>
        </label>
        <Select
          onChange={(value) => {
            setSeats(value as SelectOption);
          }}
          value={seats}
          options={[...Array(30)].map((_, index) => ({
            label: index + 1,
            value: index + 1,
          }))}
        />
        <p>You currently have {seats.value} active people.</p>
      </div>

      <div style={styles.summary}>
        <h2>Plan summary</h2>
        <div style={styles.tableRow}>
          <p>
            <b>
              {get(currentUser, 'company.plan.plan_name', '')} plan with{' '}
              {seats.value} seats
            </b>
          </p>
          <p>${seatCost.toFixed(2)}</p>
        </div>
        <div style={styles.tableRow}>
          <p>
            <b>Sales tax ({(salesTaxRate * 100).toFixed(3)}%)</b>
          </p>
          <p>${salesTax.toFixed(2)}</p>
        </div>
        <div style={styles.tableRow}>
          <p>
            <strong>Total per month</strong>
          </p>
          <p>
            <b>${totalPerMonth.toFixed(2)}</b>
          </p>
        </div>
      </div>

      <div style={styles.buttons}>
        <Button onClick={goBack} color="danger" style={styles.cancelButton}>
          Cancel
        </Button>
        <Button onClick={updatePlan} color="green">
          Update billing amount
        </Button>
      </div>
    </div>
  );
};

// Inline styling (can be replaced with a CSS file or styled-components)
const styles = {
  container: {
    maxWidth: '80%',
    margin: '0 auto',
    fontFamily: 'Arial, sans-serif',
    padding: '20px',
    backgroundColor: '#f9f9f9',
  },
  card: {
    padding: '20px',
    backgroundColor: '#fef7e0',
    border: '1px solid #f0e6c0',
    borderRadius: '8px',
    marginBottom: '20px',
  },
  select: {
    padding: '10px',
    margin: '10px 0',
    width: '200px',
    fontSize: '16px',
    borderRadius: '4px',
    border: '1px solid #ccc',
  },
  summary: {
    marginBottom: '20px',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    textAlign: 'left',
  },
  tableRow: {
    display: 'flex',
    borderBottom: '1px solid #e0e0e0',
    justifyContent: 'space-between',
  },
  buttons: {
    display: 'flex',
    gap: '10px',
    marginTop: '40px',
    justifyContent: 'flex-end',
  },
  cancelButton: {
    padding: '10px 20px',
    backgroundColor: '#f8f9fa',
    color: '#333',
    border: '1px solid #ccc',
    borderRadius: '5px',
    cursor: 'pointer',
  },
};

export default UpdatePlan;
