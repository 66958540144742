import {forwardRef} from 'react';

import Input, {InputProps} from 'components/Input';

import FieldWrapper from 'components/FieldWrapper';

interface Props extends InputProps {
  label?: string;
  error?: string;
  wrapperClass?: string;
}

const TextField = forwardRef<HTMLInputElement, Props>(function (
  {label, error, wrapperClass, ...restProps},
  ref
) {
  return (
    <FieldWrapper className={wrapperClass} label={label} error={error}>
      <Input ref={ref} hasError={Boolean(error)} {...restProps} />
    </FieldWrapper>
  );
});

export default TextField;
