import cn from "classnames";

import styles from "./Checkbox.module.scss";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {}

function Checkbox({ className, ...props }: Props) {
  return (
    <input
      className={cn(styles.checkbox, className)}
      type="checkbox"
      {...props}
    />
  );
}

export default Checkbox;
