import React, {useMemo} from 'react';
import styles from './Pagination.module.scss';

type PaginationProps = {
  page: number;
  total: number;
  setCurrentPage: (page: number) => void;
};

const Pagination: React.VFC<PaginationProps> = ({
  page,
  total,
  setCurrentPage,
}) => {
  const pagesLeft = useMemo(() => {
    return [...Array(Math.min(total, 5))].map((_, key) => {
      let rangeStart = 1;

      if (page % 5 === 0 && page > 1) {
        rangeStart = page - 3;
      } else {
        rangeStart = Math.floor(page / 5) * 5;
      }
      rangeStart = rangeStart === 0 ? 1 : rangeStart;
      return {
        page: rangeStart + key,
        title: rangeStart + key,
      };
    });
  }, [page, total]);

  const pagesRight = useMemo(() => {
    if (page + 5 >= total) {
      return [];
    }
    return [...Array(5)].map((_, key) => ({
      page: total - 5 + key,
      title: total - 5 + key,
    }));
  }, [page, total]);

  if (!total) {
    return null;
  }

  return (
    <div className={styles.pagination}>
      <div
        onClick={() => setCurrentPage(1)}
        className={styles.pagination__navlink}
      >
        &lt;&lt;
      </div>
      <div
        onClick={() => setCurrentPage(page === 1 ? 1 : page - 1)}
        className={styles.pagination__navlink}
      >
        &lt;
      </div>
      <ul className={styles.pagination__list}>
        {pagesLeft.map((pageItem, index) => (
          <li key={index} className={styles.pagination__item}>
            <div
              onClick={() => setCurrentPage(pageItem.page)}
              className={[
                styles.pagination__link,
                pageItem.page === page ? styles.active : '',
              ].join(' ')}
            >
              {pageItem.title}
            </div>
          </li>
        ))}
        {!!pagesRight.length && (
          <li className={styles.pagination__separator}>...</li>
        )}
        {pagesRight.map((pageItem, index) => (
          <li key={index} className={styles.pagination__item}>
            <div
              onClick={() => setCurrentPage(pageItem.page)}
              className={[
                styles.pagination__link,
                pageItem.page === page ? styles.active : '',
              ].join(' ')}
            >
              {pageItem.title}
            </div>
          </li>
        ))}
      </ul>
      <div
        onClick={() => setCurrentPage(page === total ? total : page + 1)}
        className={styles.pagination__navlink}
      >
        &gt;
      </div>
      <div
        onClick={() => setCurrentPage(total)}
        className={styles.pagination__navlink}
      >
        &gt;&gt;
      </div>
    </div>
  );
};

export default Pagination;
