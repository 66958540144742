import {useState} from 'react';

type SortOrder = 'asc' | 'desc';

function useSort(defaultColumn: string, defaultSortOrder: SortOrder = 'asc') {
  const [sortBy, setSortBy] = useState(defaultColumn);
  const [sortOrder, setSortOrder] = useState<SortOrder>(defaultSortOrder);

  // to use in request queries to backend
  const order = `${sortOrder === 'desc' ? '-' : ''}${sortBy}`;

  function onSort(sortBy: string, sortOrder: 'asc' | 'desc') {
    setSortBy(sortBy);
    setSortOrder(sortOrder);
  }

  return {
    sortBy,
    sortOrder,
    order,
    onSort,
  };
}

export default useSort;
