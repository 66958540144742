import React from 'react';
import {IconProps} from 'types/ui';

const PrintIcon: React.VFC<IconProps> = ({size = 16}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 490 490"
      width={size}
      fill="currentColor"
      height={size}
    >
      <path
        d="M420.931 104.275h-44.166V0H113.236v104.275H69.069C30.982 104.275 0 136.499 0 176.104v123.732c0 39.615 30.982 71.839 69.069 71.839h33.506V490h284.839V371.675h33.516c38.089 0 69.069-32.224 69.069-71.839V176.104c.001-39.605-30.979-71.829-69.068-71.829zM134.087 20.852h221.827v83.424H134.087V20.852zm232.477 448.297H123.427V290.204h243.137v178.945zm102.585-169.313c0 28.109-21.635 50.987-48.218 50.987h-33.516v-81.47H102.576v81.47H69.069c-26.583 0-48.218-22.878-48.218-50.987V176.104c0-28.11 21.635-50.978 48.218-50.978h351.862c26.583 0 48.218 22.867 48.218 50.978v123.732z"
        fill="currentColor"
      />
      <path
        d="M173.997 337.975h141.997v20.852H173.997zM173.997 395.508h141.997v20.852H173.997zM366.34 173.354h31.124v20.852H366.34z"
        fill="currentColor"
      />
    </svg>
  );
};

export default PrintIcon;
