import Typography from 'components/Typography';
import PageHeader from 'components/PageHeader';
import ListWrapper from 'components/ListWrapper';
import FormGroup from 'components/FormGroup';
import TextField from 'components/TextField';
import Button from 'components/Button';
import {useForm} from 'react-hook-form';
import {validateEmail} from 'utils/validators';
import useAuth from 'hooks/useAuth';
import {get} from 'lodash';
import api from 'api';
import toast from 'react-hot-toast';

type FormValues = {
  name: string;
  email: string;
  address: string;
  phone: string;
  id: string;
};
function CompanyDetails() {
  const {currentUser} = useAuth();
  const {
    register,
    formState: {errors},
    handleSubmit,
  } = useForm<FormValues>({
    defaultValues: {
      name: get(currentUser, 'company.name', ''),
      email: get(currentUser, 'company.email', ''),
      address: get(currentUser, 'company.address', ''),
      phone: get(currentUser, 'company.phone', ''),
      id: get(currentUser, 'company.id', ''),
    },
  });

  const saveHandler = async (data: FormValues) => {
    try {
      const company_id = get(currentUser, 'company.id', 0);
      await api.put(`/companies/companies/${company_id}/`, data);
    } catch (error) {
      toast.error(get(error, 'response.data.error_message', '') || 'Error');
    }
  };

  return (
    <div>
      <PageHeader>
        <Typography variant="h2">Company details</Typography>
      </PageHeader>
      <ListWrapper
        style={{
          maxWidth: 700,
          paddingLeft: 20,
          display: 'flex',
          paddingTop: 20,
          paddingRight: 20,
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="h3">Details</Typography>
          </div>
          <FormGroup>
            <TextField
              label="Name"
              placeholder="Name"
              {...register('name', {
                required: 'Required',
              })}
              error={errors.name?.message}
            />
          </FormGroup>
          <FormGroup>
            <TextField
              label="Email"
              placeholder="Email"
              {...register('email', {
                required: 'Required',
                validate: validateEmail,
              })}
              error={errors.email?.message}
            />
          </FormGroup>

          <FormGroup columns={1}>
            <TextField
              label="Address"
              placeholder="Email address"
              {...register('address', {
                required: 'Required',
              })}
              error={errors.email?.message}
            />
          </FormGroup>

          <FormGroup columns={1}>
            <TextField
              label="Phone number"
              placeholder="Phone number"
              {...register('phone', {
                required: 'Required',
              })}
              error={errors.phone?.message}
            />
          </FormGroup>
        </div>
        <Button
          onClick={handleSubmit(saveHandler)}
          style={{marginLeft: 'auto', marginBottom: 10, marginTop: 30}}
          color="green"
        >
          Save
        </Button>
      </ListWrapper>
    </div>
  );
}

export default CompanyDetails;
