import React from 'react';
import cn from 'classnames';

import logo from './images/logo.png';

import styles from './Logo.module.scss';

function Logo({className, ...restProps}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={cn(styles.root, className)} {...restProps}>
      <img style={{width: 200, height: 200}} alt="logo" src={logo} />
    </div>
  );
}

export default Logo;
