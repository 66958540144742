import Select from 'components/Select';

import './Filter.scss';
import DateTimePicker from 'components/DateTimePicker';
import {parseJSON} from 'date-fns';
import locationIcon from '../../../Requests/location.svg';
import {components} from 'react-select';
import CheckboxField from 'components/CheckboxField';
import stat_time from '../../icons/stat_time.svg';
import calendar from '../../icons/calendar.svg';
import {isArray} from 'lodash';
import {SelectOption} from 'types';

export type FilterOption = {
  value: any;
  label: string;
};

export type FilterOptionGroup = {
  label: string;
  options: FilterOption[];
};

type Props = {
  options: FilterOptionGroup[];
  onChange: (value: any, meta: any) => void;
  onChangeData: (value: any) => void;
  value: any;
  date: undefined | string;
  allChecked: boolean;
  onChangeCheckbox: () => void;
  onChangeTime: (values: any) => void;
  onChangePriority: (value: SelectOption | null) => void;
  preferenceTime: {label: string; value: string} | undefined;
  priority: SelectOption | undefined;
};

const Filter: React.VFC<Props> = ({
  options,
  value,
  onChange,
  onChangeData,
  date = undefined,
  allChecked,
  onChangeCheckbox,
  preferenceTime,
  priority,
  onChangeTime,
  onChangePriority,
}) => {
  return (
    <div>
      <div className="display-row">
        <CheckboxField
          name={`checkbox`}
          className={'checkbox'}
          checked={allChecked}
          onChange={onChangeCheckbox}
        />

        <Select
          className="DeliveryPlanner__Filter"
          isSearchable={false}
          onChange={onChange}
          options={options}
          value={value}
          placeholder="Location"
          isClearable
          components={{
            Input: () => null,
            DropdownIndicator: (props) =>
              !value.length ? (
                <components.DropdownIndicator {...props} />
              ) : null,
            Control: ({children, ...props}) => (
              <components.Control {...props}>
                <img
                  className={'locationIcon'}
                  src={locationIcon}
                  alt="location_icon"
                />
                {children}
              </components.Control>
            ),
            SingleValue: ({children, ...props}) => {
              return (
                <components.SingleValue {...props}>
                  {isArray(props.selectProps.value)
                    ? props.selectProps.value
                        .map((item: {label: string}) => item.label)
                        .join(', ')
                    : ''}
                </components.SingleValue>
              );
            },
            IndicatorSeparator: () => null,
          }}
        />
      </div>
      <div className="display-row timers">
        <DateTimePicker
          showTimeSelect={false}
          icon={calendar}
          dateFormat="MM/dd/yy E"
          placeholder="Pr. date"
          customInput="customInput_Filter"
          value={date ? parseJSON(date) : undefined}
          minTime={date ? parseJSON(date) : undefined}
          onChange={onChangeData}
        />
        <Select
          className={'Time_Select'}
          isSearchable={false}
          onChange={onChangeTime}
          options={[
            {label: 'Morning', value: 'morning'},
            {label: 'Afternoon', value: 'afternoon'},
            {label: 'Evening', value: 'evening'},
          ]}
          value={preferenceTime}
          placeholder={
            <span className="select-placeholder-text">Pr. time</span>
          }
          isClearable
          components={{
            Input: () => null,
            DropdownIndicator: (props) =>
              !preferenceTime ? (
                <components.DropdownIndicator {...props} />
              ) : null,
            Control: ({children, ...props}) => (
              <components.Control {...props} className="select-row">
                <img className={'timer'} src={stat_time} alt="timer" />
                {children}
              </components.Control>
            ),
            IndicatorSeparator: () => null,
          }}
        />
      </div>
      <Select
        className={'Priority_Select'}
        isSearchable={false}
        onChange={onChangePriority}
        options={[
          {label: 'High', value: 'high'},
          {label: 'Medium', value: 'medium'},
          {label: 'Low', value: 'low'},
        ]}
        value={priority}
        placeholder={<span className="select-placeholder-text">Priority</span>}
        isClearable
        components={{
          Input: () => null,
          DropdownIndicator: (props) =>
            !priority ? <components.DropdownIndicator {...props} /> : null,
          IndicatorSeparator: () => null,
        }}
      />
    </div>
  );
};

export default Filter;
