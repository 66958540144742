import cn from "classnames";

import styles from "./ListWrapper.module.scss";

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

function ListWrapper({ className, ...restProps }: Props) {
  return <div className={cn(styles.root, className)} {...restProps} />;
}

export default ListWrapper;
