import cn from "classnames";

import arrow from "./icons/arrow_right.svg";

import styles from "./TableAction.module.scss";

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

function TableAction({ children, className, ...restProps }: Props) {
  return (
    <div className={cn(styles.root, className)} {...restProps}>
      <div>{children}</div>
      <img className={styles.arrow} alt="arrow right" src={arrow} />
    </div>
  );
}

export default TableAction;
