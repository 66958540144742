import { components, DropdownIndicatorProps } from "react-select";

import { SelectOption } from "types";

function DropdownIndicator(props: DropdownIndicatorProps<SelectOption, false>) {
  return (
    <components.DropdownIndicator {...props}>
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.0625 7C3.0625 6.75838 3.25838 6.5625 3.5 6.5625H10.5C10.7416 6.5625 10.9375 6.75838 10.9375 7C10.9375 7.24162 10.7416 7.4375 10.5 7.4375H3.5C3.25838 7.4375 3.0625 7.24162 3.0625 7Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.875 4.375C0.875 4.13338 1.07088 3.9375 1.3125 3.9375H12.6875C12.9291 3.9375 13.125 4.13338 13.125 4.375C13.125 4.61662 12.9291 4.8125 12.6875 4.8125H1.3125C1.07088 4.8125 0.875 4.61662 0.875 4.375Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.25 9.625C5.25 9.38338 5.44588 9.1875 5.6875 9.1875H8.3125C8.55412 9.1875 8.75 9.38338 8.75 9.625C8.75 9.86662 8.55412 10.0625 8.3125 10.0625H5.6875C5.44588 10.0625 5.25 9.86662 5.25 9.625Z"
          fill="black"
        />
      </svg>
    </components.DropdownIndicator>
  );
}

export default DropdownIndicator;
