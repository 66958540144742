import React, {useMemo} from 'react';
import {NavLink} from 'react-router-dom';
import styles from './Pagination.module.scss';

type PaginationProps = {
  basePath: string;
  page: number;
  total: number;
  setCurrentPage: (page: number) => void;
};

const Pagination: React.VFC<PaginationProps> = ({
  page,
  total,
  basePath,
  setCurrentPage,
}) => {
  const pagesLeft = useMemo(() => {
    return [...Array(Math.min(total, 5))].map((_, key) => {
      let rangeStart = 1;
      if (page % 5 === 0 && page > 1) {
        rangeStart = page - 4;
      } else {
        rangeStart = Math.floor(page / 5) * 5;
      }
      rangeStart = rangeStart === 0 ? 1 : rangeStart;
      return {
        page: rangeStart + key,
        link: `${basePath}/page/${rangeStart + key}`,
        title: rangeStart + key,
      };
    });
  }, [basePath, page, total]);

  const pagesRight = useMemo(() => {
    if (page + 5 >= total) {
      return [];
    }
    return [...Array(5)].map((_, key) => ({
      page: total - 5 + key,
      link: `${basePath}/page/${total - 5 + key}`,
      title: total - 5 + key,
    }));
  }, [basePath, page, total]);

  if (!total) {
    return null;
  }

  return (
    <div className={styles.pagination}>
      <NavLink to={`${basePath}/page/1`} className={styles.pagination__navlink}>
        &lt;&lt;
      </NavLink>
      <NavLink
        to={`${basePath}/page/${page === 1 ? 1 : page - 1}`}
        className={styles.pagination__navlink}
      >
        &lt;
      </NavLink>
      <ul className={styles.pagination__list}>
        {pagesLeft.map((pageItem, index) => (
          <li key={index} className={styles.pagination__item}>
            <NavLink
              to={`${basePath}/page/${pageItem.page}`}
              activeClassName={styles.active}
              className={[
                styles.pagination__link,
                page === 1 && pageItem.page === 1 ? styles.active : '',
              ].join(' ')}
            >
              {pageItem.title}
            </NavLink>
          </li>
        ))}
        {!!pagesRight.length && (
          <li className={styles.pagination__separator}>...</li>
        )}
        {pagesRight.map((pageItem, index) => (
          <li key={index} className={styles.pagination__item}>
            <NavLink
              to={`${basePath}/page/${pageItem.page}`}
              activeClassName={styles.active}
              className={styles.pagination__link}
            >
              {pageItem.title}
            </NavLink>
          </li>
        ))}
      </ul>
      <NavLink
        to={`${basePath}/page/${page === total ? total : page + 1}`}
        className={styles.pagination__navlink}
      >
        &gt;
      </NavLink>
      <NavLink
        to={`${basePath}/page/${total}`}
        className={styles.pagination__navlink}
      >
        &gt;&gt;
      </NavLink>
    </div>
  );
};

export default Pagination;
