import {useRouteMatch, Switch, Route, Redirect} from 'react-router-dom';

import Gateway from './PaymentGateway';
import Routes from './Routes';
import Billing from './Billing';
import Tariff from './Tariff';
import TariffUpdate from './TariffUpdate';
import History from './History';

import styles from './Settings.module.scss';
import CompanyDetails from 'pages/CompanyDetails';
import {useEffect} from 'react';
import useUi from 'hooks/useUi';
import useAuth from 'hooks/useAuth';
import {get} from 'lodash';

function Settings() {
  const {currentUser} = useAuth();
  const {path} = useRouteMatch();
  const {setSidebarExtended, setFullsizeContent} = useUi();

  useEffect(
    function () {
      setSidebarExtended(false);
      setFullsizeContent(true);

      return function () {
        setSidebarExtended(true);
        setFullsizeContent(false);
      };
    },
    [setSidebarExtended, setFullsizeContent]
  );

  return (
    <div className={styles.root}>
      <Switch>
        <Route path={`${path}/billing`} exact component={Billing} />
        <Route path={`${path}/tariff`} exact component={Tariff} />
        <Route path={`${path}/billing/update`} component={TariffUpdate} />
        <Route path={`${path}/billing/history`} component={History} />
        <Route path={`${path}/gateway`} component={Gateway} />
        <Route path={`${path}/routes`} component={Routes} />
        <Route path={`${path}/company`} component={CompanyDetails} />
        <Redirect
          to={
            get(currentUser, 'company.plan')
              ? `${path}/billing`
              : `${path}/tariff`
          }
        />
      </Switch>
    </div>
  );
}

export default Settings;
