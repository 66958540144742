import 'leaflet/dist/leaflet.css';
import {MapContainer, TileLayer} from 'react-leaflet';
import Routing from './RoutingMachine';
import {Order} from 'types';

type Props = {
  isMapping?: boolean;
  display: boolean;
  orders: Order[];
  isHistory?: boolean;
  start_location: {
    name: string;
    lat: number;
    lng: number;
  };
  end_location: {
    name: string;
    lat: number;
    lng: number;
  };
};

function NewMap({
  isMapping,
  display,
  orders,
  start_location,
  isHistory,
  end_location,
}: Props) {
  return (
    <div style={{position: 'relative', zIndex: 0}}>
      <MapContainer
        center={[40.7128, -74.006]}
        style={{height: 'calc(100vh - 4rem)'}}
        zoom={11}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        <Routing
          start_location={start_location}
          end_location={end_location}
          orders={orders}
          isMapping={isMapping}
          isHistory={isHistory}
          display={display}
        />
      </MapContainer>
    </div>
  );
}

export default NewMap;
