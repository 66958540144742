import {LatLngExpression} from 'leaflet';
import {
  MapContainer,
  TileLayer,
  MapContainerProps,
  Marker,
  useMap,
} from 'react-leaflet';
import L from 'leaflet';

interface Props extends MapContainerProps {
  center: LatLngExpression;
  onDrag?: (data: {lat: number; lon: number}) => void;
}
const returnIcon = () => {
  return new L.DivIcon({
    className: 'leaflet-div-icon',
    html: `<div class="my-div-icon red-pin"></div>`,
  });
};

function ChangeView({center}: {center: LatLngExpression}) {
  const map = useMap();
  map.setView(center, 11);
  return null;
}

const MapPreview = ({center, onDrag, ...props}: Props) => {
  return (
    <MapContainer
      center={center}
      style={{height: 300, width: 550}}
      zoom={11}
      {...props}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />
      <ChangeView center={center} />
      <Marker
        eventHandlers={{
          dragend: (event) => {
            //@ts-ignore
            onDrag({
              lat: event.target._latlng.lat,
              lon: event.target._latlng.lng,
            });
          },
        }}
        icon={returnIcon()}
        draggable
        position={center}
      ></Marker>
    </MapContainer>
  );
};

export default MapPreview;
