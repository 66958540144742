import axios, {AxiosInstance, AxiosRequestConfig} from 'axios';
// import store from '@app/store';

export abstract class ApiClient {
  private baseUrl: string;
  private httpClient: AxiosInstance;

  constructor() {
    this.baseUrl = process.env['REACT_APP_API_URL'] || '';
    if (!this.baseUrl) {
      throw new Error('API base url not defined.');
    }
    this.httpClient = axios.create({
      baseURL: `${this.baseUrl}/`,
      headers: {
        // 'Content-Type': 'application/json'
      },
    });
    this.httpClient.interceptors.request.use(function (config) {
      const token = localStorage.getItem('token');
      if (config.headers && token) {
        config.headers.Authorization = token ? `Token ${token}` : '';
      }
      return config;
    });
  }

  protected async head(path: string, query: object = {}) {
    return this.httpClient.head(path, {params: query});
  }

  protected async get(path: string, query: object = {}) {
    return this.httpClient.get(path, {params: query});
  }

  protected async post(
    path: string,
    payload: object = {},
    config: AxiosRequestConfig = {}
  ) {
    return this.httpClient.post(path, payload, config);
  }

  protected async postAuth(
    path: string,
    payload: object = {},
    config: AxiosRequestConfig = {}
  ) {
    return axios.post(`${this.baseUrl}${path}`, payload, config);
  }

  protected async put(
    path: string,
    payload: object = {},
    config: AxiosRequestConfig = {}
  ) {
    return this.httpClient.put(path, payload, config);
  }

  protected async patch(path: string, payload: object = {}) {
    return this.httpClient.patch(path, payload);
  }

  protected async delete(path: string, query: object = {}) {
    return this.httpClient.delete(path, {params: query});
  }
}
