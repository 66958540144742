import {formatDistanceToNow, parseJSON} from 'date-fns';
import {capitalize} from 'lodash';

import DataTable from 'components/DataTable';
import ListActionsWrapper from 'components/ListActionsWrapper';
import ListTabs from 'components/ListTabs';
import ListWrapper from 'components/ListWrapper';
import SearchInput from 'components/SearchInput';
import Typography from 'components/Typography';
import {DataItem, DeliveryStatus, StatusColor} from 'types';
import StatusBadge from 'components/StatusBadge';
import useOrderList from 'hooks/useOrderList';
import {useCallback, useMemo} from 'react';
import {useHistory} from 'react-router-dom';

const SCHEMA = [
  {dataKey: 'number', header: 'New Leaf Order Number'},
  {dataKey: 'created_at', header: 'Date & Time'},
  {dataKey: 'status', header: 'Delivery Status'},
];

const statuses: DeliveryStatus[] = [
  'ready_for_delivery',
  'in_transit',
  'delivered',
  'cancelled',
];
const colors: Record<DeliveryStatus, StatusColor> = {
  in_store_pickup: 'iris',
  ready_for_delivery: 'iris',
  in_transit: 'fuschia',
  delivered: 'green',
  cancelled: 'critical',
};

function getStatus() {
  const index = Math.round(Math.random() * 100) % statuses.length;
  const status = statuses[index];
  const text = capitalize(status.replaceAll('_', ' '));
  const color = colors[status];

  return <StatusBadge color={color}>{text}</StatusBadge>;
}

const tabs = [{title: 'All'}, {title: 'Active'}, {title: 'Archived'}];

function Deliveries() {
  const {orders} = useOrderList();
  const history = useHistory();

  const handleNavigateDelivery = useCallback(
    (row: DataItem) => {
      history.push(`/deliveries/${row.id}`);
    },
    [history]
  );

  const data = useMemo(
    () =>
      orders.map((order) => ({
        id: order.id,
        number: <Typography variant="strong">#{order.id}</Typography>,
        created_at: formatDistanceToNow(parseJSON(order.created_at), {
          addSuffix: true,
        }),
        status: getStatus(),
      })),
    [orders]
  );
  return (
    <>
      <Typography variant="h2">All deliveries</Typography>
      <ListWrapper>
        <ListTabs tabs={tabs} />
        <ListActionsWrapper>
          <SearchInput placeholder="Search" />
        </ListActionsWrapper>
        <DataTable
          data={data}
          schema={SCHEMA}
          selectable={false}
          onClick={handleNavigateDelivery}
        />
      </ListWrapper>
    </>
  );
}

export default Deliveries;
