function IconCube() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1073 11.25C12.5215 11.2543 12.8538 11.5936 12.8495 12.0077L12.75 21.6462C12.7457 22.0604 12.4064 22.3927 11.9923 22.3884C11.5781 22.3842 11.2458 22.0449 11.25 21.6307L11.3496 11.9923C11.3539 11.5781 11.6931 11.2458 12.1073 11.25Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2619 1.80548C11.4871 1.67821 11.7413 1.61133 12 1.61133C12.2587 1.61133 12.5129 1.67821 12.7381 1.80548L20.6131 6.25657C20.8443 6.38728 21.0367 6.57705 21.1706 6.80648C21.3045 7.03592 21.375 7.29678 21.375 7.56241V16.4372C21.375 16.7029 21.3045 16.9637 21.1706 17.1932C21.0367 17.4226 20.8443 17.6124 20.6131 17.7431L12.7381 22.1942C12.5129 22.3214 12.2587 22.3883 12 22.3883C11.7413 22.3883 11.4871 22.3214 11.2619 22.1942L3.38691 17.7431C3.15566 17.6124 2.96327 17.4226 2.82941 17.1932C2.69554 16.9637 2.625 16.7028 2.625 16.4372V7.56241C2.625 7.29678 2.69554 7.03592 2.82941 6.80648C2.96327 6.57705 3.15566 6.38728 3.38691 6.25657L3.75596 6.90949L3.38691 6.25657L11.2619 1.80548ZM12 3.11133L4.125 7.56242V16.4372L12 20.8883L19.875 16.4372V7.56242L12 3.11133L12.369 2.45841L12 3.11133Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.82368 6.81691C3.02573 6.45532 3.48265 6.32598 3.84425 6.52803L12.0953 11.1385L20.15 6.53271C20.5095 6.3271 20.9677 6.45191 21.1733 6.81149C21.3789 7.17107 21.2541 7.62925 20.8945 7.83486L12.4719 12.6511C12.2435 12.7817 11.9634 12.7831 11.7337 12.6547L3.11257 7.83747C2.75097 7.63543 2.62164 7.17851 2.82368 6.81691Z"
        fill="black"
      />
    </svg>
  );
}

export default IconCube;
