import Modal from 'components/Modal';
import Typography from 'components/Typography';
import useAuth from 'hooks/useAuth';
import styles from './UserModal/UserModal.module.scss';

interface Props {
  isOpen: boolean;
  onClose: (start: boolean) => void;
}

function Guide({isOpen, onClose}: Props) {
  const {currentUser} = useAuth();
  return (
    <Modal
      className={styles.modal}
      showCloseButton={false}
      isOpen={isOpen}
      onClose={() => onClose(false)}
    >
      <Typography style={{textAlign: 'center', fontSize: 45}} variant="h1">
        👋 Welcome, {currentUser?.first_name}
      </Typography>
      <Typography
        style={{
          textAlign: 'center',
          color: '#000',
          fontWeight: '500',
          fontSize: 20,
          marginTop: 10,
        }}
        variant="h5"
      >
        Magic is provider of economic affairs
      </Typography>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: 5,
          marginTop: 10,
          marginBottom: 60,
        }}
      >
        <Typography
          style={{
            textAlign: 'center',
            color: '#000',
            fontWeight: '500',
          }}
          variant="h5"
        >
          Go the hang of Magic already?
        </Typography>
        <Typography
          onClick={() => onClose(false)}
          style={{
            textAlign: 'center',
            fontWeight: '500',
            color: '#2a4dff',
            cursor: 'pointer',
          }}
          variant="h5"
        >
          Skip the tutorial
        </Typography>
      </div>
      <Typography
        className="start-guide"
        onClick={() => onClose(true)}
        style={{
          textAlign: 'center',
          fontSize: 20,
          color: '#2a4dff',
          cursor: 'pointer',
        }}
        variant="p"
      >
        See quick guide through Magic
      </Typography>
    </Modal>
  );
}

export default Guide;
