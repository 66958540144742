import ReactModal, {Props as ReactModalProps} from 'react-modal';
import cn from 'classnames';

import closeIcon from './icons/close.svg';

import styles from './Modal.module.scss';

interface Props extends ReactModalProps {
  onClose?: () => void;
  showCloseButton?: boolean;
  overlayClassName?: string;
  className?: string;
}

function Modal({
  overlayClassName,
  className,
  children,
  onClose,
  showCloseButton = true,
  ...props
}: Props) {
  return (
    <ReactModal
      overlayClassName={cn(styles.overlay, overlayClassName)}
      className={cn(styles.modal, className)}
      shouldCloseOnOverlayClick
      {...props}
    >
      {showCloseButton && (
        <img
          className={styles.close_icon}
          alt="close"
          src={closeIcon}
          onClick={onClose}
        />
      )}
      {children}
    </ReactModal>
  );
}

export default Modal;
