import React from 'react';
import cn from 'classnames';

import Typography from 'components/Typography';

import styles from './FieldWrapper.module.scss';

interface Props {
  label?: string;
  error?: string;
  children: React.ReactNode;
  className?: string;
}

function FieldWrapper({label, error, className, children}: Props) {
  return (
    <div className={cn(styles.root, className)}>
      {label ? <label className={styles.label}>{label}</label> : null}
      {children}
      {error && (
        <Typography className={styles.error} variant="p">
          {error}
        </Typography>
      )}
    </div>
  );
}

export default FieldWrapper;
