import Joyride from 'react-joyride';

function Onboarding({steps, onFinish}: {steps: any[]; onFinish?: () => void}) {
  return (
    <Joyride
      continuous
      hideBackButton
      showProgress
      callback={(data) => {
        if (onFinish && data.action === 'reset') {
          onFinish();
        }
      }}
      steps={steps.reverse()}
    />
  );
}

export default Onboarding;
