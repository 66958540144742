import {useEffect, useMemo, useState} from 'react';
import {useRouteMatch} from 'react-router-dom';
import {QueryFunctionContext, useQuery} from 'react-query';
import {SingleValue} from 'react-select';

import ViewDetails from 'components/ViewDetails';

import {ListResponse, SelectOption, User} from 'types';
import api from 'api';
import {getFullName} from 'helpers/user';
import roles from 'constants/roles';
import Switch from 'react-switch';
import localStyles from './Employees.module.scss';
import useSort from 'hooks/useSort';

const filterOptions: SelectOption[] = [
  {value: '', label: 'All employees'},
  ...roles,
];

async function getEmployees({queryKey}: QueryFunctionContext) {
  const [, role, order, keyWord] = queryKey;
  const {data} = await api.get<ListResponse<User>>('/users/', {
    params: {role, order, search: keyWord ? keyWord : undefined},
  });

  return data;
}

function useEmployees() {
  const {url} = useRouteMatch();
  const [keyWord, setKeyWord] = useState(
    sessionStorage.getItem(`search_employe`) || ''
  );
  const [filterValue, setFilterValue] = useState<SelectOption>(
    filterOptions[0]
  );
  const {sortBy, sortOrder, order, onSort} = useSort('name');

  const {data: employees, isLoading} = useQuery(
    ['employees', filterValue.value, order, keyWord],
    getEmployees
  );

  const data = useMemo(
    function () {
      if (!employees) return [];

      return employees.results
        .filter((employee) => employee.role !== 'customer')
        .map((employee) => ({
          ...employee,
          name: getFullName(employee),
          role: <span className={localStyles.role}>{employee.role}</span>,
          active:
            employee.role !== 'management' ? (
              <EmployeStatus id={employee.id} active={employee.is_active} />
            ) : null,
          view: <ViewDetails to={`${url}/${employee.id}`} />,
        }));
    },
    [employees, url]
  );

  function onChangeFilter(newValue: SingleValue<SelectOption>) {
    newValue && setFilterValue(newValue);
  }

  return {
    data,
    isLoading,
    filterOptions,
    filterValue,
    sortBy,
    sortOrder,
    keyWord,
    setKeyWord,
    onChangeFilter,
    onSort,
  };
}

const EmployeStatus = ({active, id}: {active: boolean; id: number}) => {
  const [checked, setChecked] = useState(active);

  useEffect(() => {
    setChecked(active);
  }, [active]);

  const onChange = () => {
    setChecked((pr) => !pr);
    api
      .patch<User>(`/users/${id}/`, {
        is_active: !checked,
      })
      .then(() => {})
      .catch((err) => {
        setChecked((pr) => !pr);
      });
  };

  return (
    <div
      style={{
        paddingRight: 60,
      }}
    >
      <label>
        <Switch
          onColor="#ab20fd"
          onChange={onChange}
          handleDiameter={17}
          height={20}
          width={40}
          checked={checked}
        />
      </label>
    </div>
  );
};

export default useEmployees;
