import classNames from 'classnames';

import Checkbox from 'components/Checkbox';

import {DataTableSchemaItem} from 'types';

import styles from '../DataTable.module.scss';

interface Props {
  schema: DataTableSchemaItem[];
  selectable: boolean;
  allSelected: boolean;
  sortBy?: string;
  sortOrder: 'asc' | 'desc';
  style?: any;
  handleSelecAll: () => void;
  handleSort: React.MouseEventHandler<HTMLDivElement>;
}

function Header({
  schema,
  selectable,
  allSelected,
  sortBy,
  sortOrder,
  style = {},
  handleSelecAll,
  handleSort,
}: Props) {
  return (
    <div className={classNames(styles.row)} style={style}>
      {selectable && (
        <div className={classNames(styles.select, styles.head)}>
          <Checkbox
            className={styles.checkbox}
            checked={allSelected}
            onChange={handleSelecAll}
          />
        </div>
      )}

      {schema.map((s) => (
        <div
          className={classNames(styles.head, styles[s.dataKey], {
            [styles[`--${s.align}`]]: s.align,
          })}
          key={`header-${s.dataKey}`}
          data-key={s.dataKey}
          data-sortable={s.sortable}
          onClick={handleSort}
        >
          {s.sortable ? (
            <div className={styles.header_cell}>
              <span>{s.header}</span>

              <div
                className={classNames(
                  styles.sort_button,
                  styles[`--${sortOrder}`],
                  {
                    [styles[`--active`]]: s.dataKey === sortBy,
                  }
                )}
              />
            </div>
          ) : (
            s.header
          )}
        </div>
      ))}
    </div>
  );
}

export default Header;
