function IconChat() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.375 20.2495H4.46875C4.27813 20.2495 4.09531 20.1738 3.96052 20.039C3.82573 19.9042 3.75 19.7214 3.75 19.5308V11.6245C3.75 10.4918 3.97309 9.37029 4.40654 8.32386C4.83998 7.27742 5.4753 6.32661 6.2762 5.52571C7.0771 4.72481 8.02791 4.08949 9.07435 3.65605C10.1208 3.2226 11.2423 2.99951 12.375 2.99951H12.375C13.5077 2.99951 14.6292 3.2226 15.6756 3.65605C16.7221 4.0895 17.6729 4.72481 18.4738 5.52572C19.2747 6.32662 19.91 7.27743 20.3435 8.32387C20.7769 9.3703 21 10.4919 21 11.6245V11.6245C21 13.912 20.0913 16.1058 18.4738 17.7233C16.8563 19.3408 14.6625 20.2495 12.375 20.2495Z"
        stroke="#222222"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.37506 10.499H15.0001"
        stroke="#222222"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.37506 13.499H15.0001"
        stroke="#222222"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default IconChat;
