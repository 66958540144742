import Input from 'components/Input';
import React from 'react';
import styles from './DateTimePicker.module.scss';
import stat_time from 'assets/icons/stat_time.svg';
import DatePicker, {ReactDatePickerProps} from 'react-datepicker';
import {formatISO} from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
import classNames from 'classnames';

interface Props extends ReactDatePickerProps {
  value: any;
  onChange: (value: any, evt: any) => void;
  showTimeSelect?: boolean;
  dateFormat?: string;
  placeholder?: string;
  icon?: string;
  selectsRange?: any;
  customInput?: string;
  minDate?: undefined | Date;
  minTime?: undefined | Date;
  maxTime?: undefined | Date;
  disabled?: boolean;
}

const DateTimePicker: React.VFC<Props> = ({
  value,
  onChange,
  showTimeSelect = true,
  dateFormat = 'MM/dd/yyyy h:mm aaa',
  placeholder = 'Select date',
  icon = null,
  customInput,
  minDate = null,
  minTime = undefined,
  maxTime = undefined,
  disabled = false,

  ...props
}) => {
  return (
    <div className={styles.wrapper}>
      <DatePicker
        showTimeSelect={showTimeSelect}
        selected={value}
        isClearable
        minDate={minDate}
        minTime={minTime}
        disabled={disabled}
        maxTime={maxTime}
        dateFormat={dateFormat}
        placeholderText={placeholder}
        wrapperClassName={styles.datePicker}
        customInput={
          <Input
            icon={<img alt="timer" src={icon ?? stat_time} />}
            size="md"
            readOnly={disabled}
            disabled={disabled}
            value={value ? formatISO(value) : ''}
            className={classNames(styles.customInput, customInput)}
          />
        }
        clearButtonClassName={styles.clearButtonClassName}
        className={styles.wrapper}
        onChange={onChange}
        {...props}
      />
    </div>
  );
};

export default DateTimePicker;
