import cn from "classnames";

import styles from "./ListActionsWrapper.module.scss";

interface Props {
  className?: string;
  children: React.ReactNode;
}

function ListActionsWrapper({ className, children }: Props) {
  return <div className={cn(styles.root, className)}>{children}</div>;
}

export default ListActionsWrapper;
