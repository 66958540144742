import { useState } from "react";

function useNow() {
  const [now, setNow] = useState(new Date());
  const timeout = 60 - now.getSeconds();

  setTimeout(function () {
    setNow(new Date());
  }, timeout * 1000);

  return now;
}

export default useNow;
