import ReactModal from 'react-modal';
import {BrowserRouter as Router} from 'react-router-dom';
import {QueryClient, QueryClientProvider} from 'react-query';

import AuthProvider from 'providers/AuthProvider';
import UIProvider from 'providers/UIProvider';
import './index.css';
import App from 'app';

ReactModal.setAppElement('#root');

const queryClient = new QueryClient();

function Root() {
  return (
    <Router>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <UIProvider>
            <App />
          </UIProvider>
        </AuthProvider>
      </QueryClientProvider>
    </Router>
  );
}

export default Root;
