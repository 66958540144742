function IconTruck() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.75 7.5C15.75 7.08579 16.0858 6.75 16.5 6.75H20.4922C20.792 6.75 21.0849 6.83983 21.3332 7.0079C21.5814 7.17597 21.7736 7.41457 21.8849 7.69291L21.1886 7.97146L21.8849 7.69291L23.1964 10.9715C23.2888 11.2025 23.2606 11.4644 23.121 11.6705C22.9815 11.8766 22.7489 12 22.5 12H16.5C16.0858 12 15.75 11.6642 15.75 11.25V7.5ZM17.25 8.25V10.5H21.3922L20.4922 8.25H17.25Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.75 13.5C0.75 13.0858 1.08579 12.75 1.5 12.75H16.5C16.9142 12.75 17.25 13.0858 17.25 13.5C17.25 13.9142 16.9142 14.25 16.5 14.25H1.5C1.08579 14.25 0.75 13.9142 0.75 13.5Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.625 16.5C16.7966 16.5 16.125 17.1716 16.125 18C16.125 18.8284 16.7966 19.5 17.625 19.5C18.4534 19.5 19.125 18.8284 19.125 18C19.125 17.1716 18.4534 16.5 17.625 16.5ZM14.625 18C14.625 16.3431 15.9681 15 17.625 15C19.2819 15 20.625 16.3431 20.625 18C20.625 19.6569 19.2819 21 17.625 21C15.9681 21 14.625 19.6569 14.625 18Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.375 16.5C5.54657 16.5 4.875 17.1716 4.875 18C4.875 18.8284 5.54657 19.5 6.375 19.5C7.20343 19.5 7.875 18.8284 7.875 18C7.875 17.1716 7.20343 16.5 6.375 16.5ZM3.375 18C3.375 16.3431 4.71815 15 6.375 15C8.03185 15 9.375 16.3431 9.375 18C9.375 19.6569 8.03185 21 6.375 21C4.71815 21 3.375 19.6569 3.375 18Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.875 18C7.875 17.5858 8.21079 17.25 8.625 17.25H15.375C15.7892 17.25 16.125 17.5858 16.125 18C16.125 18.4142 15.7892 18.75 15.375 18.75H8.625C8.21079 18.75 7.875 18.4142 7.875 18Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.18934 5.68934C1.47064 5.40804 1.85217 5.25 2.25 5.25H16.5C16.9142 5.25 17.25 5.58579 17.25 6V16.0514C17.25 16.4656 16.9142 16.8014 16.5 16.8014C16.0858 16.8014 15.75 16.4656 15.75 16.0514V6.75L2.25 6.75V17.25H4.125C4.53921 17.25 4.875 17.5858 4.875 18C4.875 18.4142 4.53921 18.75 4.125 18.75H2.25C1.85218 18.75 1.47065 18.592 1.18934 18.3107C0.908035 18.0294 0.75 17.6478 0.75 17.25V6.75C0.75 6.35218 0.908035 5.97064 1.18934 5.68934Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.75 11.25C15.75 10.8358 16.0858 10.5 16.5 10.5H22.5C22.9142 10.5 23.25 10.8358 23.25 11.25V17.25C23.25 17.6478 23.092 18.0294 22.8107 18.3107C22.5294 18.592 22.1478 18.75 21.75 18.75H19.875C19.4608 18.75 19.125 18.4142 19.125 18C19.125 17.5858 19.4608 17.25 19.875 17.25H21.75V12H17.25V16.0514C17.25 16.4656 16.9142 16.8014 16.5 16.8014C16.0858 16.8014 15.75 16.4656 15.75 16.0514V11.25Z"
        fill="black"
      />
    </svg>
  );
}

export default IconTruck;
