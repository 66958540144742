import React, {MouseEvent, useState} from 'react';
import {Link} from 'react-router-dom';
import cn from 'classnames';

import styles from './Button.module.scss';
import Spinner from 'components/Spinner';

interface Props
  extends React.HTMLAttributes<HTMLButtonElement | HTMLAnchorElement> {
  component: 'a' | 'button';

  size: 'lg' | 'md' | 'sm';
  color: 'danger' | 'green' | 'primary' | 'gray' | 'blue' | 'outlined';

  disabled?: boolean;
  loading?: boolean;
  children?: any;
  to?: string;
}

function Button({
  component = 'button',
  className,
  children,
  size,
  color,
  loading,
  to,
  onClick,
  ...restProps
}: Props) {
  const [isLoading, setIsLoading] = useState(false);

  async function handleClick(event: MouseEvent<HTMLButtonElement>) {
    if (!onClick) return;

    setIsLoading(true);
    await onClick(event);
    setIsLoading(false);
  }

  if (component === 'a' && to) {
    return (
      <Link
        to={to}
        className={cn(
          styles.root,
          styles[`--${size}`],
          styles[`--${color}`],
          {[styles[`--loading`]]: loading},
          className
        )}
        {...restProps}
      >
        {children}
      </Link>
    );
  } else {
    return (
      <button
        className={cn(
          styles.root,
          styles[`--${size}`],
          styles[`--${color}`],
          {[styles[`--loading`]]: loading || isLoading},
          className
        )}
        onClick={handleClick}
        {...restProps}
        disabled={restProps.disabled || isLoading}
      >
        {children}
        {(loading || isLoading) && (
          <div className={styles.loader}>
            <Spinner />
          </div>
        )}
      </button>
    );
  }
}

Button.defaultProps = {
  component: 'button',
  size: 'md',
  color: 'primary',
  type: 'button',
};

export default Button;
