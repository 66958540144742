import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Product} from 'types';
import styles from './ProductImage.module.scss';
import {ReactComponent as NoImageIcon} from 'assets/icons/no-image.svg';

type Props = {
  size?: 'thumbnail' | 'medium';
  product?: Product;
  value?: File;
  onChange?: (file: File) => void;
};

const ProductImage: React.VFC<Props> = ({
  size = 'thumbnail',
  product,
  value,
  onChange,
}) => {
  const [selectedFile, setSelectedFile] = useState<File>();
  const [preview, setPreview] = useState<string>();

  const isUploadable = useMemo(() => {
    return typeof onChange === 'function';
  }, [onChange]);

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target && e.target.files) {
        setSelectedFile(e.target.files[0]);
      }
      onChange && e.target.files && onChange(e.target.files[0]);
    },
    [onChange]
  );

  useEffect(() => {
    if (selectedFile) {
      const objectUrl = URL.createObjectURL(selectedFile);
      setPreview(objectUrl);

      return () => {
        URL.revokeObjectURL(objectUrl);
      };
    }
  }, [selectedFile]);

  const imageUrl = useMemo(() => {
    return preview || product?.image?.[size];
  }, [preview, product?.image, size]);

  return (
    <label
      className={[
        styles.wrapper,
        styles[`wrapper--${size}`],
        isUploadable ? styles[`wrapper--uploadable`] : '',
      ].join(' ')}
    >
      {imageUrl ? (
        <img
          src={imageUrl}
          alt={product?.name}
          className={styles.product_image}
        />
      ) : (
        <NoImageIcon />
      )}
      {isUploadable && <p className={styles.note}>Click to select new image</p>}
      {isUploadable && <input type="file" onChange={handleInputChange} />}
    </label>
  );
};

export default ProductImage;
