function IconHome() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.0045 10.2767L12.504 3.45775C12.3659 3.33223 12.186 3.26269 11.9995 3.2627C11.8129 3.2627 11.633 3.33226 11.4949 3.45778L3.99545 10.2767C3.91814 10.347 3.85637 10.4326 3.8141 10.5282C3.77183 10.6238 3.75 10.7271 3.75 10.8316V19.4998C3.75 19.6988 3.82902 19.8895 3.96967 20.0302C4.11032 20.1708 4.30109 20.2498 4.5 20.2498H19.5C19.6989 20.2498 19.8897 20.1708 20.0303 20.0302C20.171 19.8895 20.25 19.6988 20.25 19.4998V10.8316C20.25 10.7271 20.2282 10.6238 20.1859 10.5282C20.1436 10.4326 20.0818 10.347 20.0045 10.2767Z"
        stroke="#222222"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default IconHome;
