import Select, { Props as SelectProps } from "components/Select";

import DropdownIndicator from "./components/DropdownIndicator";
import SingleValue from "./components/SingleValue";

import styles from "./Filter.module.scss";

function Filter({ ...restProps }: SelectProps) {
  return (
    <Select
      className={styles.root}
      {...restProps}
      components={{ DropdownIndicator, SingleValue }}
    />
  );
}

Filter.defaultProps = {
  size: "md",
};

export default Filter;
