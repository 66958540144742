import { User } from "types";

export function getFullName(user?: {
  first_name?: string;
  last_name?: string;
}) {
  return user ? [user.first_name, user.last_name].join(" ") : "";
}

export function getAddress(user: User) {
  return (
    [user.address_line_1, user.address_line_2, user.city, user.state]
      .filter((item) => Boolean(item))
      .join(", ") +
    " " +
    user.zipcode
  );
}
