import React, {MouseEvent, ReactNode, useEffect, useState} from 'react';
import {get} from 'lodash';

import CheckboxField from 'components/CheckboxField';
import Typography from 'components/Typography';

import {Order} from 'types';

import {ReactComponent as MorningIcon} from 'assets/icons/morning.svg';
import {ReactComponent as DayIcon} from 'assets/icons/day.svg';
import {ReactComponent as EveningIcon} from 'assets/icons/evening.svg';
import styles from './Ticket.module.scss';
import IconList from 'components/Sidebar/MenuIcon/IconList';
import NoteIcon from 'components/Sidebar/MenuIcon/NoteIcon';
import classNames from 'classnames';
import arrow_bottom from './arrow_bottom.svg';

interface Props extends Order {
  index?: number;
  arrival_time?: string;
  arrival_date?: string;
  new_leaf_order?: string;
  selected?: boolean;
  timePreference?: string;
  borough?: string;
  isAssigned: boolean;
  onChangeCheckbox: (id: number, checked: boolean) => void;
  onOrdersClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  handleNoteClick: () => void;
  showTime?: boolean;
  isPrint?: boolean;
  isError?: boolean;
}

const timePreferenceIconMap: Record<string, ReactNode> = {
  morning: <MorningIcon />,
  day: <DayIcon />,
  evening: <EveningIcon />,
};

function Ticket({
  index,
  id,
  arrival_date,
  timePreference,
  delivery_address,
  arrival_time,
  selected,
  isAssigned,
  customer,
  onChangeCheckbox,
  onOrdersClick,
  handleNoteClick,
  showTime,
  isPrint = false,
  isError,
}: Props) {
  function naming() {
    if (typeof arrival_time === 'string') {
      const [hour, minute] = arrival_time.split(':');
      let hourInt = parseInt(hour, 10);
      let minuteInt = parseInt(minute, 10);

      let period = 'AM';
      if (hourInt >= 12) {
        period = 'PM';
      }

      if (hourInt === 0) {
        hourInt = 12;
      } else if (hourInt > 12) {
        hourInt -= 12;
      }
      return `${hourInt}:${minuteInt.toString().padStart(2, '0')} ${period}`;
    }
    return '';
  }
  const handleOrderClick = (e: MouseEvent<HTMLDivElement>) => {
    onOrdersClick(e);
  };

  const [isOpen, setIsOpen] = useState(!index);

  useEffect(() => {
    if (showTime && !isPrint) {
      setIsOpen(false);
    }
  }, [showTime, isPrint]);

  return (
    <div
      className={classNames(
        styles.root,
        ((typeof delivery_address !== 'string' &&
          !delivery_address?.is_valid) ||
          isError) &&
          styles.borderColor
      )}
    >
      {index && !isPrint && (
        <div className={styles.rightElement}>
          <span className={styles.index}>{index}</span>
          <img
            onClick={() => setIsOpen((pr) => !pr)}
            alt="arrow bottom"
            src={arrow_bottom}
            className={isOpen ? styles.open : ''}
          />
        </div>
      )}
      {isPrint ? (
        <div className={styles.top}>
          <Typography variant="h4">STOP # {index ? index : ''}</Typography>
          {typeof arrival_time === 'string' && isAssigned && showTime && (
            <span className={styles.delivery_time}>
              {' '}
              {'-  '}
              {naming()}
            </span>
          )}
        </div>
      ) : (
        <div
          style={{marginBottom: index && !isPrint ? 0 : 8}}
          className={styles.top}
        >
          <CheckboxField
            name={`checkbox-${id}`}
            className={styles.checkbox}
            label={`#${id}`}
            checked={selected}
            onChange={() => onChangeCheckbox(id, !selected)}
          />
          {isOpen ? (
            <>
              <IconList
                className={styles.listButton}
                onClick={handleOrderClick}
              />
              {isAssigned && (
                <div className={styles.noteButton}>
                  <NoteIcon
                    className={styles.listButton}
                    onClick={handleNoteClick}
                  />
                </div>
              )}
            </>
          ) : (
            <Typography className={styles.customer} variant="p">
              {get(customer, 'user.first_name', '')}{' '}
              {get(customer, 'user.last_name', '')}
            </Typography>
          )}
        </div>
      )}
      {isOpen && (
        <>
          {typeof customer === 'string' ? (
            <Typography className={styles.customer} variant="p">
              {customer}
            </Typography>
          ) : (
            <Typography className={styles.customer} variant="p">
              {get(customer, 'user.first_name', '')}{' '}
              {get(customer, 'user.last_name', '')}
            </Typography>
          )}

          {typeof delivery_address === 'string' ? (
            <Typography className={styles.addressCalc} variant="p">
              {delivery_address}
            </Typography>
          ) : (
            <Typography className={styles.address} variant="p">
              {delivery_address?.address_line_1 || ''}{' '}
              {delivery_address?.address_line_2 || ''} <br />
              {delivery_address?.city}, {delivery_address?.state}{' '}
              {delivery_address?.zipcode}
            </Typography>
          )}
          <div className={styles.footer}>
            {timePreference && timePreferenceIconMap[timePreference] && (
              <span className={styles.time_preference}>
                {timePreferenceIconMap[timePreference]}
              </span>
            )}
            {typeof arrival_time === 'string' &&
              isAssigned &&
              showTime &&
              !isPrint && (
                <span className={styles.delivery_time}>
                  {arrival_date ? `${arrival_date}  ` : ''} {naming()}
                </span>
              )}
            {!!get(delivery_address, 'unit_address', '') && isPrint && (
              <span className={styles.delivery_time}>
                Unit# {get(delivery_address, 'unit_address', '')}
              </span>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default Ticket;
