import {Fragment, useState} from 'react';
import {Link} from 'react-router-dom';
import {useForm} from 'react-hook-form';

import Card from 'components/Card';
import Button from 'components/Button';
import TextField from 'components/TextField';
import CardContent from 'components/CardContent';
import Logo from 'components/Logo';
import Typography from 'components/Typography';

import {validateEmail} from 'utils/validators';
import useAuth from 'hooks/useAuth';
import EyeIcon from '../EmployeeForm/icons/eye.svg';
import EyeDisabledIcon from '../EmployeeForm/icons/eye-disable.svg';
import styles from './Signup.module.scss';

interface FormValues {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  password: string;
  passwordConfirmation: string;
  company_name: string;
  company_address: string;
  company_email: string;
  company_phone: string;
  website: string;
}

type Status = 'ready' | 'error' | 'done';

function Signup() {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [status, setStatus] = useState<Status>('ready');

  const {
    register,
    handleSubmit,
    watch,
    formState: {errors},
  } = useForm<FormValues>();

  const {register: signup} = useAuth();

  async function submit(data: FormValues) {
    setLoading(true);

    try {
      await signup({
        ...data,
        phone: data.company_phone,
        email: data.company_email,
      });
      setStatus('done');
    } catch {
      setStatus('error');
    } finally {
      setLoading(false);
    }
  }

  return (
    <Card className={styles.root}>
      <CardContent>
        <Logo />
        <Typography variant="h2" textAlign="center">
          Sign Up
        </Typography>
        {status === 'ready' ? (
          <Fragment>
            <form onSubmit={handleSubmit(submit)}>
              <div className={styles.row}>
                <div>
                  <Typography variant="h4">Company details</Typography>
                  <TextField
                    label="Company name"
                    placeholder="Name"
                    error={errors.company_name?.message}
                    {...register('company_name', {
                      required: 'Required',
                    })}
                  />
                  <TextField
                    label="Address"
                    placeholder="Address"
                    error={errors.company_address?.message}
                    {...register('company_address', {
                      required: 'Required',
                    })}
                  />
                  <TextField
                    label="Email"
                    type="email"
                    placeholder="Email"
                    error={errors.company_email?.message}
                    {...register('company_email', {
                      required: 'Required',
                      validate: validateEmail,
                    })}
                  />
                  <TextField
                    label="Phone"
                    placeholder="Phone"
                    error={errors.company_phone?.message}
                    {...register('company_phone', {
                      required: 'Required',
                    })}
                  />
                  <TextField
                    label="Website"
                    placeholder="https://"
                    {...register('website')}
                  />
                </div>
                <div>
                  <Typography variant="h4">User details</Typography>
                  <TextField
                    label="First name"
                    placeholder="First name"
                    error={errors.first_name?.message}
                    {...register('first_name', {
                      required: 'Required',
                    })}
                  />
                  <TextField
                    label="Last name"
                    placeholder="Last name"
                    error={errors.last_name?.message}
                    {...register('last_name', {
                      required: 'Required',
                    })}
                  />
                  {/* <TextField
                    label="Email"
                    placeholder="Email"
                    type="email"
                    error={errors.email?.message}
                    {...register('email', {
                      required: 'Required',
                      validate: validateEmail,
                    })}
                  />
                  <TextField
                    label="Phone"
                    placeholder="Phone"
                    error={errors.passwordConfirmation?.message}
                    {...register('phone', {
                      required: 'Required',
                    })}
                  /> */}
                  <div style={{position: 'relative'}}>
                    <TextField
                      label="Password"
                      type={showPassword ? 'text' : 'password'}
                      placeholder="Password"
                      error={errors.password?.message}
                      {...register('password', {
                        required: 'Required',
                        minLength: {value: 6, message: 'Too short'},
                      })}
                    />
                    <img
                      onClick={() => setShowPassword((pr) => !pr)}
                      className={styles.eyeIcon}
                      src={showPassword ? EyeIcon : EyeDisabledIcon}
                      alt="eye"
                    />
                  </div>
                  <div style={{position: 'relative'}}>
                    <TextField
                      label="Confirm password"
                      type={showPassword1 ? 'text' : 'password'}
                      placeholder="Confirm password"
                      error={errors.passwordConfirmation?.message}
                      {...register('passwordConfirmation', {
                        required: 'Required',
                        validate: (val: string) => {
                          if (watch('password') !== val) {
                            return 'Your passwords do no match';
                          }
                        },
                      })}
                    />
                    <img
                      onClick={() => setShowPassword1((pr) => !pr)}
                      className={styles.eyeIcon}
                      src={showPassword1 ? EyeIcon : EyeDisabledIcon}
                      alt="eye"
                    />
                  </div>
                </div>
              </div>
              <br />
              <hr color="#E5E5E5" style={{marginBottom: 0}} />
              <div className={styles.button_container}>
                <Button type="submit" loading={loading}>
                  Sign up
                </Button>
              </div>
            </form>
            <div className={styles.signup}>
              <Typography variant="p">
                Already have an account?{' '}
                <Link className={styles.link} to="/signin">
                  Sign in
                </Link>
              </Typography>
            </div>
          </Fragment>
        ) : status === 'done' ? (
          <Typography
            textAlign="center"
            className={styles.message}
            component="p"
            variant="h3"
          >
            To verify your account, click on the confirmation link sent to the
            email provided.
          </Typography>
        ) : (
          <Typography
            textAlign="center"
            className={styles.message}
            component="p"
            variant="h3"
          >
            Something went wrong. Can't tell what exactly.
          </Typography>
        )}
      </CardContent>
    </Card>
  );
}

export default Signup;
