import classNames from 'classnames';
// import pluralize from 'pluralize';
import Spinner from 'components/Spinner';
import Button from 'components/Button';
import Filter from './components/Filter';
// import Sort from './components/Sort';
import Ticket from './components/Ticket';
// import Map from './components/Map';
// import NewMap from './components/NewMap';
import SignedDocument from './components/SignedDocument';
import useDeliveryPlanner from './useDeliveryPlanner';
import stat_path from './icons/stat_path.svg';
import stat_stops from './icons/stat_stops.svg';
import stat_time from './icons/stat_time.svg';
import sort_icon from './icons/sort.svg';
import reset_icon from './icons/reset.svg';

import styles from './DeliveryPlanner.module.scss';
import AssignedHeader from './components/AssignedHeader';
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import nyPostalCodeBoroughOptions from 'shared/ny-postal-code-borough-options';
import {get, groupBy, map, sortBy} from 'lodash';
// import DateTimePicker from 'components/DateTimePicker';
import {add, parseISO} from 'date-fns';
import {utcToZonedTime, format} from 'date-fns-tz';
import {
  getOrderDeliveryBorough,
  getOrderDeliveryTimePreference,
} from 'utils/order-helpers';

import SendRouteModal from 'components/SendRouteModal';
import FormGroup from 'components/FormGroup';
import PrintIcon from 'icons/PrintIcon';
import ReactToPrint from 'react-to-print';
import Modal from 'components/Modal';
import {Order} from 'types';
import {Link, useLocation} from 'react-router-dom';
import {useForm, useFormState} from 'react-hook-form';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import clockIcon from 'components/NoteForm/icons/clock.svg';
import CheckboxField from 'components/CheckboxField/CheckboxField';
// import Map from './components/Map';
import TextField from 'components/TextField';
// import SignedDocument from './components/SignedDocument';
import {components} from 'react-select';

import NewMap from './components/NewMap';
import Select from 'components/Select';
import Onboarding from 'Onboarding';
import api from 'api';
import useAuth from 'hooks/useAuth';
import authContext from 'contexts/authContext';
import PermissionModal from 'components/PermissionModal';
import plansIds from 'constants/plans';

const locationOptionsGrouped = groupBy(nyPostalCodeBoroughOptions, 'label');
const locationOptions = sortBy(
  map(locationOptionsGrouped, (obj, key) => ({
    label: key,
    value: map(obj, 'value'),
  })),
  'label'
);

type noteTypes = {
  [key: string]: object;
};

function DeliveryPlanner() {
  const [moveOrderModal, setMoveToOrder] = useState(false);
  const {
    route,
    currentTeam,
    teams,
    isLoading,
    unassignedOrders,
    selectedUnassignedOrders,
    selectedAssignedOrders,
    stats,
    isSubmittable,
    onChangeUnassignedCheckbox,
    onChangeUnassignedCheckboxAll,
    onChangeAssignedCheckbox,
    onChangeAssignedCheckboxAll,
    onChangeRouteParams,
    onChangeStartTime,
    onSendRoute,
    sortBy,
    setSortBy,
    filter,
    // refetch,
    onFilterChange,
    assignSelected,
    moveToOrders,
    unassignSelected,
    calculate,
    sendDriverNote,
    onChangeData,
    date,
    setPreferenceTime,
    preferenceTime,
    priority,
    onChangePriority,
    setSelectedUnassignedOrders,
    setSelectedAssignedOrders,
    hasNextPage,
    fetchNextPage,
    isFetching,
    roundedTime,
    searchKey,
    setSearch,
    count,
    fetchingAll,
    updateRoute,
    calculating: calculatingRoute,
    resetCalculate,
    sortOptions,
    search,
    defaultRoute,
    permissionRef,
  } = useDeliveryPlanner({setMoveToOrder});
  const {currentUser} = useAuth();
  const {handleSubmit, control, register, setValue, reset} = useForm();
  const {isSubmitting} = useFormState({control});

  const [isSendRouteModalOpen, setIsSendRouteModalOpen] = useState(false);
  const [orderList, setOrderList] = useState<Order[] | null>(null);
  const [activeOrder, setActiveOrder] = useState({});
  const [noteModal, setNoteModal] = useState(false);
  const [allChecked, setAllChecked] = useState(false);
  const [allCheckedAssign, setAllCheckedAssign] = useState(false);
  const [notes, setNotes] = useState<noteTypes>({});
  const location = useLocation<{isRouteHistory: boolean} | null>();
  const [isRouteHistory, setIsRouteHistory] = useState(false);
  const [calculating, setCalculating] = useState(false);
  const [isCalculate, setIsCalculate] = useState(false);

  const {setUser} = useContext(authContext);

  useEffect(() => {
    if (!get(currentUser, 'company.plan')) {
      permissionRef.current?.open({
        action: '/settings/tariff',
        buttonTitle: 'Buy plan',
        title:
          'You do not have any plan yet. You should buy plan for your business',
      });
    } else if (get(currentUser, 'company.plan.plan_limit_out')) {
      permissionRef.current?.open({
        action: '/settings/billing',
        buttonTitle: 'Update plan',
        title: 'You have more active employee than your tariff',
      });
    }

    if (location.state) {
      setIsRouteHistory(location.state?.isRouteHistory);
    } else {
      setIsRouteHistory(false);
    }
  }, [location.state, permissionRef, currentUser]);

  const observer = useRef<IntersectionObserver>();
  const lastElementRef = useCallback(
    (node: HTMLDivElement) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, fetchNextPage, hasNextPage]
  );

  const handleOrdersClick =
    (order: Order) => (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      setActiveOrder(order);
      setOrderList(order.orders);
    };
  const handleNoteClick = (id: number, staff: string) => {
    setNoteModal(true);
    setValue('driverNote', staff ? staff : get(notes[`${id}`], 'note', ''));
  };

  const handleOrdersClose = () => {
    setOrderList(null);
    setNoteModal(false);
  };
  const noteSubmit = async (value: any) => {
    const body = {
      id: Number(get(activeOrder, 'id', '')),
      note: String(value?.driverNote),
    };
    const res = await sendDriverNote(body);
    if (res.status === 201) {
      setNotes((pr) => ({...pr, [`${get(activeOrder, 'id', '')}`]: body}));
      reset();
      setNoteModal(false);
      // refetch();
    }
  };

  const orderListRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (route.deliveries.length < 1) {
      setAllCheckedAssign(false);
    }
    if (unassignedOrders.length < 1) {
      setAllChecked(false);
    }
  }, [route.deliveries.length, fetchNextPage, unassignedOrders.length]);

  const move = (
    source: any,
    destination: any,
    droppableSource: any,
    droppableDestination: any
  ) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {
      list: [],
      list1: [],
    };
    //@ts-ignore
    result[droppableSource.droppableId] = sourceClone;
    //@ts-ignore
    result[droppableDestination.droppableId] = destClone;

    return result;
  };

  const reorder = (list: Order[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  // @ts-ignore
  const handleDragEnd = async (result) => {
    if (!result.destination) return;

    if (result.source.droppableId === result.destination.droppableId) {
      if (result.source.droppableId === 'list') {
        const newItems = [...route.deliveries];
        const [reorderedItem] = newItems.splice(result.source.index, 1);
        newItems.splice(result.destination.index, 0, reorderedItem);
        route.deliveries = newItems;
        defaultRoute.deliveries = newItems;
      } else {
        const res = reorder(
          unassignedOrders,
          result.source.index,
          result.destination.index
        );
        unassignedOrders.length = 0;
        unassignedOrders.push(...res);
      }
    } else {
      const res = move(
        result.source.droppableId === 'list'
          ? route.deliveries
          : unassignedOrders,
        result.destination.droppableId === 'list'
          ? route.deliveries
          : unassignedOrders,
        result.source,
        result.destination
      );
      defaultRoute.deliveries = res?.list;
      route.deliveries = res?.list;
      updateRoute.mutate({...route, deliveries: res?.list});

      unassignedOrders.length = 0;
      unassignedOrders.push(...res.list1);
      setSelectedUnassignedOrders((pr) =>
        pr.filter((item) => item !== Number(result.draggableId))
      );
      setSelectedAssignedOrders((pr) =>
        pr.filter((item) => item !== Number(result.draggableId))
      );
    }
    recalculate();
  };

  const recalculate = async () => {
    if (route.id !== '0' && route.deliveries.length > 0 && isSubmittable) {
      setCalculating(true);
      if (get(currentUser, 'company.plan.plan_id') === plansIds[2]) {
        await calculate(false);
      }
      setCalculating(false);
    }
  };

  const onChangeCheckbox = () => {
    setAllChecked((pr) => !pr);
    onChangeUnassignedCheckboxAll(
      unassignedOrders.map((item) => item.id),
      !allChecked
    );
  };
  const onChangeCheckboxAssign = () => {
    setAllCheckedAssign((pr) => !pr);
    onChangeAssignedCheckboxAll(
      route.deliveries.map((item) => item.id),
      !allCheckedAssign
    );
  };

  const onChange = (val: any) => {
    onChangeUnassignedCheckboxAll([], false);
    setAllChecked(false);
    setPreferenceTime(val);
  };

  const [showGuide, setShowGuide] = useState(false);

  const onboardingShown = useCallback(() => {
    if (currentUser) {
      setUser({
        ...currentUser,
        is_onboarded: true,
      });
    }
    api
      .post('/users/onboard/', {
        is_onboarded: true,
      })
      .then(() => {})
      .catch((err) => {});
  }, [currentUser, setUser]);

  useEffect(() => {
    if (!get(currentUser, 'is_onboarded', true)) {
      setShowGuide(true);
      localStorage.setItem('show-guide', 'true');
      onboardingShown();
    }
  }, [currentUser, onboardingShown]);

  const onMappingPressed = async () => {
    setIsCalculate(false);
    if (get(currentUser, 'company.plan.plan_id') === plansIds[0]) {
      permissionRef.current?.open({
        action: '/settings/billing',
        buttonTitle: 'Update plan',
        title: 'You need to upgrade your plan to use mapping',
        cancelable: true,
      });
      return;
    }
    await calculate(true);
  };
  const calculateHandler = async () => {
    if (get(currentUser, 'company.plan.plan_id') === plansIds[1]) {
      permissionRef.current?.open({
        action: '/settings/billing',
        buttonTitle: 'Update plan',
        title: 'You need to upgrade your plan to use calculate route',
        cancelable: true,
      });
      return;
    }
    if (
      get(currentUser, 'company.plan.plan_id') === plansIds[2] &&
      route.deliveries.length > 25
    ) {
      permissionRef.current?.open({
        action: '',
        buttonTitle: 'Got it',
        title:
          'You can only use 25 orders at the same time when calculating. You can use mapping to view direction.',
        cancelable: true,
      });
      return;
    }
    await calculate(false);
    setIsCalculate(true);
  };

  return (
    <>
      {showGuide && (
        <Onboarding
          steps={[
            {
              target: '.draggable-1',
              title: 'Tasks',
              disableBeacon: true,
              content: 'You can drag task from here to right',
            },
            {
              target: '.assign-team',
              title: 'Assign team',
              content: 'You can assign task for your teams from here',
            },
            {
              target: '.calculate-route',
              title: 'Calculate route',
              content: 'You can calculate route from here',
            },
            {
              target: '.send-route',
              title: 'Send route',
              content: 'You can send calculated route to your team',
            },
            {
              target: '.history-route',
              title: 'Route history',
              content: 'You can check route history from here',
            },
          ].reverse()}
        />
      )}
      <div className={styles.root}>
        <SendRouteModal
          route={route}
          isOpen={isSendRouteModalOpen}
          onClose={() => setIsSendRouteModalOpen(false)}
          onSubmit={onSendRoute}
        />
        <DragDropContext onDragEnd={handleDragEnd}>
          <div className={styles.panel}>
            <div className={classNames(styles.panel__header)}>
              <span className={styles.panel__inbox_title}>
                Inbox: {count} Jobs
              </span>
            </div>
            <div className={styles.panel__filtersCon}>
              <div className={styles.panel__filters}>
                <Filter
                  onChange={onFilterChange}
                  value={filter}
                  options={[
                    {
                      label: 'Locations',
                      options: locationOptions,
                    },
                  ]}
                  allChecked={allChecked}
                  date={date}
                  onChangeCheckbox={onChangeCheckbox}
                  onChangeData={onChangeData}
                  onChangeTime={onChange}
                  priority={priority}
                  onChangePriority={onChangePriority}
                  preferenceTime={preferenceTime}
                />

                {/* <Sort value={sortBy} onChange={(value) => setSortBy(value)} /> */}
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                position: 'relative',
                zIndex: 1,
              }}
            >
              <TextField
                value={searchKey}
                onChange={(target) => setSearch(target.target.value)}
                wrapperClass={styles.searchWrapper}
                placeholder="Search..."
              />

              <div className={styles.sortSelect}>
                <Select
                  value={sortBy}
                  onChange={(val: any) => {
                    setSortBy(val);
                  }}
                  isSearchable={false}
                  components={{
                    Control: (props) => (
                      <components.Control
                        {...props}
                        className={styles.sortController}
                      >
                        <img
                          className={styles.sortIcon}
                          src={sort_icon}
                          alt="sort"
                        />
                        {props.children}
                      </components.Control>
                    ),

                    DropdownIndicator: (props) => <></>,
                  }}
                  options={sortOptions}
                />
              </div>
            </div>
            <div className={classNames(styles.panel__content)}>
              {!isRouteHistory && (
                <>
                  <div className="draggable-1" />
                  {isLoading ? (
                    <div className={styles.spinner_loading}>
                      <Spinner />
                    </div>
                  ) : (
                    <>
                      <Droppable droppableId="list1">
                        {(provided: any) => (
                          <div
                            style={{minHeight: '70vh'}}
                            ref={provided.innerRef}
                          >
                            {unassignedOrders.map((ticket, index) => {
                              return (
                                <div
                                  key={ticket.id}
                                  ref={
                                    unassignedOrders.length === index + 1
                                      ? lastElementRef
                                      : null
                                  }
                                >
                                  <Draggable
                                    draggableId={String(ticket.id)}
                                    index={index}
                                  >
                                    {(provided: any) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <Ticket
                                          isAssigned={false}
                                          key={ticket.id}
                                          {...ticket}
                                          new_leaf_order={ticket.new_leaf_order}
                                          timePreference={getOrderDeliveryTimePreference(
                                            ticket
                                          )}
                                          borough={getOrderDeliveryBorough(
                                            ticket
                                          )}
                                          selected={selectedUnassignedOrders.includes(
                                            ticket.id
                                          )}
                                          onChangeCheckbox={
                                            onChangeUnassignedCheckbox
                                          }
                                          onOrdersClick={handleOrdersClick(
                                            ticket
                                          )}
                                          handleNoteClick={() => {
                                            handleNoteClick(ticket.id, '');
                                          }}
                                        />
                                      </div>
                                    )}
                                  </Draggable>
                                </div>
                              );
                            })}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                      {isFetching && unassignedOrders.length > 0 && (
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          <Spinner />
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
            <div className={styles.panel__bottom}>
              <Button
                size="lg"
                style={{
                  borderWidth: 1.8,
                  borderColor: !selectedUnassignedOrders.length
                    ? '#ff3b245A'
                    : '#ff3b24',
                  color: '#ff3b24',
                }}
                color="outlined"
                disabled={!selectedUnassignedOrders.length}
                onClick={() => setMoveToOrder(true)}
              >
                Remove from Inbox
              </Button>
              <Button
                size="lg"
                color="outlined"
                style={{
                  borderWidth: 1.5,
                  borderColor: !selectedUnassignedOrders.length
                    ? '#2005896A'
                    : '#200589',
                  color: '#200589',
                }}
                disabled={!selectedUnassignedOrders.length}
                onClick={assignSelected}
              >
                Move to Router
              </Button>
            </div>
          </div>
          <div className={styles.panel}>
            <AssignedHeader
              roundedTime={roundedTime}
              currentVehicle={currentTeam}
              vehicles={teams?.results}
              startLocation={
                route.start_location
                  ? {
                      name: route.start_location,
                      lat: route.start_latitude,
                      lng: route.start_longitude,
                    }
                  : {
                      name: '1100 2nd Ave, New York, NY 10022',
                      lat: 40.7596958,
                      lng: -73.9650045,
                    }
              }
              recalculate={recalculate}
              routes={route.deliveries.length}
              start_time={route.start_time}
              endLocation={
                route.end_location
                  ? {
                      name: route.end_location,
                      lat: route.end_latitude,
                      lng: route.end_longitude,
                    }
                  : {
                      name: '1100 2nd Ave, New York, NY 10022',
                      lat: 40.7596958,
                      lng: -73.9650045,
                    }
              }
              onChange={onChangeRouteParams}
              onChangeStartTime={onChangeStartTime}
            />
            <div className={classNames(styles.panel__filters, styles.assign)}>
              {/* <div className={styles.panel__start}>
            <span>Starts at</span>
            <DateTimePicker
              value={route.start_time ? parseJSON(route.start_time) : undefined}
              onChange={onChangeStartTime}
            />
          </div> */}
              {!isRouteHistory && (
                <CheckboxField
                  name={`checkbox`}
                  className={classNames(styles.checkbox, styles.assignCheck)}
                  label={allCheckedAssign ? `Uncheck all` : 'Check all'}
                  checked={allCheckedAssign}
                  onChange={onChangeCheckboxAssign}
                />
              )}
              <Button
                className={styles.panel__unassign}
                disabled={!selectedAssignedOrders.length}
                size="sm"
                onClick={unassignSelected}
              >
                Unassign
              </Button>
            </div>
            <div
              ref={orderListRef}
              style={{position: 'absolute', zIndex: -100, display: 'none'}}
              className={classNames(
                styles.panel__content,
                styles['--assigned']
              )}
            >
              <div>
                {route.deliveries.map((ticket, index) => {
                  return (
                    <Ticket
                      isAssigned={true}
                      key={ticket.id}
                      isPrint={true}
                      showTime={route.status === 'processed' || isRouteHistory}
                      index={
                        route.status === 'processed' ? index + 1 : undefined
                      }
                      selected={selectedAssignedOrders.includes(ticket.id)}
                      onChangeCheckbox={onChangeAssignedCheckbox}
                      onOrdersClick={handleOrdersClick(ticket)}
                      {...ticket}
                      handleNoteClick={() => {
                        setActiveOrder(ticket);
                        handleNoteClick(
                          ticket.id,
                          String(get(ticket, 'staff_note', '') || '')
                        );
                      }}
                    />
                  );
                })}
              </div>
            </div>
            <div
              // ref={orderListRef}
              className={classNames(
                styles.panel__content,
                styles['--assigned']
              )}
            >
              <Droppable droppableId="list">
                {(provided: any) => (
                  <div style={{minHeight: '70vh'}} ref={provided.innerRef}>
                    {route.deliveries.map((ticket, index) => {
                      // console.log('Ticket:', ticket);
                      return (
                        <div key={ticket.id}>
                          <Draggable
                            draggableId={String(ticket.id)}
                            index={index}
                          >
                            {(provided: any) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <Ticket
                                  isAssigned={true}
                                  key={ticket.id}
                                  showTime={
                                    route.status === 'processed' ||
                                    isRouteHistory
                                  }
                                  index={index + 1}
                                  selected={selectedAssignedOrders.includes(
                                    ticket.id
                                  )}
                                  isError={(
                                    get(
                                      route,
                                      'wrong_addresses',
                                      []
                                    ) as number[]
                                  ).includes(ticket.id)}
                                  onChangeCheckbox={onChangeAssignedCheckbox}
                                  onOrdersClick={handleOrdersClick(ticket)}
                                  {...ticket}
                                  handleNoteClick={() => {
                                    setActiveOrder(ticket);
                                    handleNoteClick(
                                      ticket.id,
                                      String(
                                        get(ticket, 'staff_note', '') || ''
                                      )
                                    );
                                  }}
                                />
                              </div>
                            )}
                          </Draggable>
                        </div>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>

            <div className={styles.panel__assigned_buttom}>
              <div className={styles.panel__assigned_summary}>
                {/* <span className={styles.panel__deliveries_counter}>
              {route.deliveries.length}{' '}
              {pluralize('Delivery', route.deliveries.length)}
            </span> */}
                <ReactToPrint
                  trigger={() => (
                    <button className={styles.panel__deliveries_print_action}>
                      <PrintIcon size={16} />
                    </button>
                  )}
                  content={() => orderListRef.current}
                  onBeforeGetContent={() => {
                    orderListRef.current?.setAttribute(
                      'style',
                      'display:block; z-index:-1000;position:absolute'
                    );
                  }}
                  onAfterPrint={() => {
                    orderListRef.current?.setAttribute(
                      'style',
                      'display:none; z-index:-1000;position:absolute'
                    );
                  }}
                  onPrintError={() => {
                    orderListRef.current?.setAttribute(
                      'style',
                      'display:none; z-index:-1000;position:absolute'
                    );
                  }}
                />
                <div className={styles.panel__deliveries_end_time}>
                  <img alt="timer" src={stat_time} />
                  {/*<span>Ends at</span> {stats.end_time}*/}
                  <span>Ends at</span>{' '}
                  {route?.start_time && route.total_time
                    ? format(
                        utcToZonedTime(
                          add(parseISO(route.start_time), {
                            minutes: route.total_time,
                          }),
                          'America/New_York'
                        ),
                        'h:mm aaa',
                        {
                          timeZone: 'America/New_York',
                        }
                      )
                    : '-:-- --'}
                </div>
              </div>
              {route.status === 'processed' ? (
                <div className={styles.panel__assigned_stats}>
                  <Fragment>
                    <div className={styles.panel__assigned_stat}>
                      <img alt="stat icon" src={stat_time} />
                      <span>{stats.total_time}</span>
                      <span>Total time</span>
                    </div>
                    <div className={styles.panel__assigned_stat}>
                      <img alt="stat icon" src={stat_path} />
                      <span>{stats.distance}</span>
                      <span>Distance</span>
                    </div>
                    <div className={styles.panel__assigned_stat}>
                      <img alt="stat icon" src={stat_stops} />
                      <span>{stats.stops}</span>
                      <span>Stops</span>
                    </div>
                  </Fragment>
                </div>
              ) : null}

              <>
                <div
                  className={classNames(
                    styles.panel__assigned_actions,
                    get(currentUser, 'company.plan.plan_id') === plansIds[1] &&
                      styles.reverse_row
                  )}
                >
                  {get(currentUser, 'company.plan.plan_id') === plansIds[2] &&
                  route.deliveries.length > 25 ? null : (
                    <Button
                      loading={isCalculate && calculating}
                      color="outlined"
                      onClick={calculateHandler}
                      style={{
                        flex: 1,
                        borderWidth: 1.5,
                        borderColor:
                          !route.deliveries.length ||
                          !isSubmittable ||
                          isRouteHistory
                            ? '#2005896A'
                            : '#200589',
                        color: '#200589',
                      }}
                      className="calculate-route"
                      disabled={
                        !route.deliveries.length ||
                        !isSubmittable ||
                        isRouteHistory
                      }
                    >
                      Calculate
                    </Button>
                  )}
                  {(get(currentUser, 'company.plan.plan_id') !== plansIds[2] ||
                    (get(currentUser, 'company.plan.plan_id') === plansIds[2] &&
                      route.deliveries.length > 25)) && (
                    <Button
                      color="outlined"
                      style={{
                        flex: 1,
                        borderWidth: 1.5,
                        borderColor:
                          !route.deliveries.length || !isSubmittable
                            ? '#2005896A'
                            : '#200589',
                        color: '#200589',
                      }}
                      disabled={!route.deliveries.length || !isSubmittable}
                      className="send-route"
                      loading={!isCalculate && calculating}
                      onClick={onMappingPressed}
                    >
                      Mapping
                    </Button>
                  )}
                  {get(currentUser, 'company.plan.plan_id') === plansIds[2] && (
                    <Button
                      color="outlined"
                      style={{
                        borderWidth: 1.5,
                        borderColor:
                          !isSubmittable ||
                          route.id === '0' ||
                          !route.deliveries.length ||
                          !search
                            ? '#2196536A'
                            : '#219653',
                        color: '#219653',
                      }}
                      disabled={
                        !isSubmittable ||
                        route.id === '0' ||
                        !route.deliveries.length ||
                        !search
                      }
                      className="send-route"
                      onClick={() => setIsSendRouteModalOpen(true)}
                    >
                      Send Route
                    </Button>
                  )}
                </div>
                {get(currentUser, 'company.plan.plan_id') !== plansIds[2] && (
                  <Button
                    color="outlined"
                    style={{
                      borderWidth: 1.5,
                      borderColor:
                        !isSubmittable ||
                        route.id === '0' ||
                        !route.deliveries.length ||
                        !search
                          ? '#2196536A'
                          : '#219653',
                      color: '#219653',
                    }}
                    disabled={
                      !isSubmittable ||
                      route.id === '0' ||
                      !route.deliveries.length ||
                      !search
                    }
                    className="send-route"
                    onClick={() => setIsSendRouteModalOpen(true)}
                  >
                    Send Route
                  </Button>
                )}
              </>
            </div>
          </div>
        </DragDropContext>

        <div className={styles.panel}>
          <div className={classNames(styles.panel__header)}>
            {search ? (
              <div onClick={resetCalculate} className={styles.datetime}>
                <img
                  style={{margin: 0, width: 20, height: 20}}
                  alt="reset"
                  src={reset_icon}
                />
                <span className={styles.panel__change_vehicle}>Reset</span>
              </div>
            ) : (
              <span className={styles.panel__inbox_title}>Preview</span>
            )}
            <Link
              to="/history/routes/page/1"
              className={classNames(styles.datetime, 'history-route')}
            >
              <img className={styles.clock_icon} alt="clock" src={clockIcon} />
              <span className={styles.panel__change_vehicle}>History</span>
            </Link>
          </div>
          <NewMap
            isMapping={!isCalculate}
            orders={route.deliveries}
            start_location={{
              name: route.start_location,
              lat: route.start_latitude,
              lng: route.start_longitude,
            }}
            end_location={{
              name: route.end_location,
              lat: route.end_latitude,
              lng: route.end_longitude,
            }}
            display={!!search || isRouteHistory}
          />
          {/* <Map
          orders={route.deliveries}
          start_location={route.start_location}
          end_location={route.end_location}
          status={route.status}
          vehicle_type={route.vehicle?.vehicle_type}
          display={route.status === 'processed' || isRouteHistory}
        /> */}
          {/* <NewMap
          orders={route.deliveries}
          start_location={route.start_location}
          end_location={route.end_location}
          status={route.status}
          display={route.status === 'processed' || isRouteHistory}
        /> */}
        </div>

        <Modal
          isOpen={!!orderList || noteModal}
          onClose={handleOrdersClose}
          onRequestClose={handleOrdersClose}
          className={styles.list_wrapper}
        >
          {orderList && (
            <>
              <div className={styles.list}>
                <div
                  className={classNames(
                    styles.list__item_header,
                    styles.list__item
                  )}
                >
                  <p>Order #</p>
                  {/* <p style={{marginLeft: 30}}>RX #</p> */}
                  <p>Preference date</p>
                  {/* <p>Count of items</p> */}
                </div>
                {orderList?.map(
                  ({
                    new_leaf_order,
                    sequential_order_number,
                    preference_date,
                    preference_time,
                  }) => (
                    <Link
                      key={new_leaf_order}
                      to={`/requests/${new_leaf_order}`}
                    >
                      <div className={classNames(styles.list__item)}>
                        <p className={styles.list__id}>
                          #M{sequential_order_number}
                        </p>
                        {/* <p className={styles.list__id}>#{rx_no}</p> */}
                        <p className={styles.list__id}>
                          {preference_date || '--/--/----'}
                          {preference_time ? `/${preference_time}` : ''}
                        </p>
                        {/* <p className={styles.list__count}>
                        {items.length}
                      </p> */}
                      </div>
                    </Link>
                  )
                )}
              </div>
              <div className={styles.list_total}>
                Total items:
                {orderList?.reduce(
                  //@ts-ignore
                  (prev, item) => prev + item.items.length,
                  0
                )}
              </div>
            </>
          )}
          {noteModal && (
            <form
              className={styles.noteFormWrapper}
              onSubmit={handleSubmit(noteSubmit)}
            >
              <FormGroup>
                <div>Dispatch note for driver:</div>
                <textarea
                  className={styles.noteInput}
                  rows={5}
                  {...register('driverNote')}
                />
              </FormGroup>
              <div className={styles.actions}>
                <Button
                  color="outlined"
                  size="md"
                  onClick={() => handleOrdersClose()}
                >
                  Close
                </Button>
                <Button
                  type="submit"
                  color="green"
                  size="md"
                  loading={isSubmitting}
                >
                  Save
                </Button>
              </div>
            </form>
          )}
          {isRouteHistory ? (
            <>
              <div>Driver note for Dispatch:</div>
              {get(activeOrder, 'note', 'Empty!') !== null ? (
                <textarea
                  disabled={true}
                  value={get(activeOrder, 'note', 'Empty!')}
                  className={styles.orderNote}
                />
              ) : (
                <textarea
                  disabled={true}
                  value={'Empty!'}
                  className={styles.orderNote}
                />
              )}

              {!!get(activeOrder, 'delivery_image', null) && (
                <div className={styles.delivery_image}>
                  <p>Delivery image:</p>
                  <img
                    alt="delivery_image"
                    src={get(activeOrder, 'delivery_image.medium', '')}
                  />
                </div>
              )}
              {/* <div
              className={classNames(
                styles.list__item_header,
                styles.list__item
              )}
            >
              <p>Signed by:</p>
              <p>{get(activeOrder, 'accepters_name', '')}</p>
            </div>
            <div
              className={classNames(
                styles.list__item_header,
                styles.list__item
              )}
            >
              <p>Signed at:</p>
              <p>{get(activeOrder, 'signed_at', '')}</p>
            </div> */}
              <SignedDocument driver={route.driver} order={activeOrder} />
              {/* {get(activeOrder, 'signature.file', '') !== '' ? (
              <img
                src={get(activeOrder, 'signature.file', '')}
                className={styles.signatureImage}
                alt="Signature by customer"
              />
            ) : (
              <textarea
                disabled={true}
                value={'No images, yet!'}
                className={styles.orderNote}
              />
            )} */}
            </>
          ) : null}
        </Modal>
        <Modal
          isOpen={moveOrderModal}
          onClose={() => setMoveToOrder(false)}
          onRequestClose={() => setMoveToOrder(false)}
          className={styles.list_wrapper}
        >
          <h3 style={{textAlign: 'center', marginTop: 30, marginBottom: 50}}>
            Are you sure you want to move your orders into Orders section ?
          </h3>
          <div className={styles.actions}>
            {/* <Button
            color="outlined"
            size="md"
            onClick={() => setMoveToOrder(false)}
          >
            Close
          </Button> */}
            <Button
              type="submit"
              color="green"
              size="md"
              onClick={moveToOrders}
            >
              Move
            </Button>
          </div>
        </Modal>
        <Modal
          showCloseButton={false}
          isOpen={fetchingAll}
          className={styles.list_wrapper}
        >
          <div style={{alignItems: 'center', textAlign: 'center'}}>
            <Spinner className={styles.fetchLoader} />
            <h3>Fetching all orders. Please wait...</h3>
          </div>
        </Modal>
        <Modal
          showCloseButton={false}
          isOpen={calculatingRoute}
          className={styles.list_wrapper}
        >
          <div style={{alignItems: 'center', textAlign: 'center'}}>
            <Spinner className={styles.fetchLoader} />
            <h3>Route calculating. Please wait...</h3>
          </div>
        </Modal>
        <PermissionModal getRef={(ref) => (permissionRef.current = ref)} />
      </div>
    </>
  );
}

export default DeliveryPlanner;
