import useAuth from "./useAuth";

function useCurrentUser() {
  const { currentUser } = useAuth();

  if (!currentUser) throw Error("Unauthorized");

  return currentUser;
}

export default useCurrentUser;
