import uiContext from "contexts/uiContext";
import { useMemo, useState } from "react";

function UIProvider({ children }: { children: React.ReactNode }) {
  const [sidebarExtended, setSidebarExtended] = useState(true);
  const [fullsizeContent, setFullsizeContent] = useState(false);

  const value = useMemo(
    function () {
      return {
        sidebarExtended,
        setSidebarExtended,

        fullsizeContent,
        setFullsizeContent,
      };
    },
    [sidebarExtended, setSidebarExtended, fullsizeContent, setFullsizeContent]
  );

  return <uiContext.Provider value={value}>{children}</uiContext.Provider>;
}

export default UIProvider;
