import { ItemId, DataItem } from "types";

interface Params {
  data: DataItem[];
  selectedRows?: ItemId[];
  onSelectRow?: (rows: ItemId[]) => any;
  sortBy?: string;
  sortOrder?: "asc" | "desc";
  onSort?: (sortBy: string, sortOrder: "asc" | "desc") => any;
}

function useDataTable({
  data,
  selectedRows,
  onSelectRow,
  sortBy,
  sortOrder,
  onSort,
}: Params) {
  const allSelected = selectedRows?.length === data.length;

  const handleSelectSingle = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!onSelectRow || !selectedRows) return;

    const { checked } = event.currentTarget;
    const dataId = event.currentTarget.getAttribute("data-id");
    const dataType = event.currentTarget.getAttribute("data-type");
    const id = dataType === "number" ? Number(dataId) : dataId;
    if (id && checked) {
      onSelectRow([...selectedRows, id]);
    } else {
      onSelectRow(selectedRows.filter((row) => row !== id));
    }
  };
  const handleSelecAll = () => {
    if (!onSelectRow) return;

    if (allSelected) {
      onSelectRow([]);
    } else {
      onSelectRow(data.map((row) => row.id));
    }
  };

  const handleSort = (event: React.MouseEvent<HTMLTableCellElement>) => {
    const dataKey = event.currentTarget.getAttribute("data-key");
    const sortable = Boolean(event.currentTarget.getAttribute("data-sortable"));
    if (!onSort || !dataKey || !sortable) return;

    if (dataKey === sortBy) {
      onSort(dataKey, sortOrder === "asc" ? "desc" : "asc");
    } else {
      onSort(dataKey, "asc");
    }
  };

  return {
    allSelected,
    handleSelecAll,
    handleSelectSingle,
    handleSort,
  };
}

export default useDataTable;
