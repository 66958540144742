import {memo, useEffect, useRef, useState} from 'react';
import {
  NavLink,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import cn from 'classnames';

import styles from './ListTabs.module.scss';

interface Tab {
  title: string;
  path?: string;
  value?: string; // must be mandatory
}

interface Props {
  className?: string;
  tabs: Tab[];
  activeTabValue?: string;
  isSupportPage?: boolean;
  isProductsPage?: boolean;
  onChange?: (nextTabValue: string, prevTabValue: string) => void;
}

function ListTabs({
  className,
  tabs,
  activeTabValue,
  onChange,
  isSupportPage,
  isProductsPage,
}: Props) {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const history = useHistory();
  const location = useLocation();
  const prevStatusRef = useRef('');
  const {params} = useRouteMatch<{page: string | undefined}>();

  useEffect(() => {
    // @ts-ignore
    prevStatusRef.current = sessionStorage.getItem('state');

    if (isSupportPage && !location.pathname.startsWith('/history/routes')) {
      sessionStorage.setItem('state', activeTabValue || '');
      if (params.page && (prevStatusRef.current === activeTabValue || '')) {
        history.push(`/support/page/${params.page}`);
      } else {
        history.push(`/support/page/1`);
      }
    }

    if (
      !isSupportPage &&
      (activeTabValue === 'all' || activeTabValue === 'active')
    ) {
      sessionStorage.setItem('state', '');
      history.push(
        `${location.pathname}/page/1`
        // ? `/orders/page/1`
        // : `/requests/page/1`
      );
    } else {
      if (!isSupportPage) {
        sessionStorage.setItem('state', activeTabValue || '');
        if (location.pathname.startsWith('/orders')) {
          sessionStorage.setItem('orders_state', activeTabValue || '');
        }
        if (location.pathname.startsWith('/requests')) {
          sessionStorage.setItem('requests_state', activeTabValue || '');
        }
        if (location.pathname.startsWith('/transactions')) {
          sessionStorage.setItem('transactions_state', activeTabValue || '');
        }

        history.push(
          `/${location.pathname.split('/')[1]}/page/${params.page || 1}`
        );

        // if (params.page && (prevStatusRef.current === activeTabValue || '')) {
        //   history.push(
        //     `/${location.pathname.split('/')[1]}/page/${params.page || 1}`
        //   );
        //   // history.push(
        //   //   isProductsPage
        //   //     ? `/products/page/${params.page}`
        //   //     : location.pathname.startsWith('/orders')
        //   //     ? `/orders/page/${params.page}`
        //   //     : `/requests/page/${params.page}`
        //   // );
        // } else {
        //   history.push(
        //     `/${location.pathname.split('/')[1]}/page/${params.page || 1}`
        //   );
        //   // history.push(
        //   //   isProductsPage
        //   //     ? `/products/page/1`
        //   //     : location.pathname.startsWith('/orders')
        //   //     ? `/orders/page/${params.page || 1}`
        //   //     : `/requests/page/${params.page || 1}`
        //   // );
        // }
      }
    }
  }, [
    activeTabValue,
    history,
    isProductsPage,
    isSupportPage,
    params.page,
    location.pathname,
  ]);

  return (
    <div className={cn(styles.root, className)}>
      {tabs.map((tab, index) => {
        if (tab.path) {
          return (
            <NavLink
              to={tab.path}
              className={styles.tab}
              activeClassName={styles['--active']}
              exact
            >
              {tab.title}
            </NavLink>
          );
        }
        return (
          <span
            key={tab.title}
            className={cn(styles.tab, {
              [styles['--active']]:
                tab.value !== undefined
                  ? tab.value === activeTabValue
                  : index === activeTabIndex,
            })}
            onClick={() => {
              if (onChange) {
                onChange(tab.value || '', activeTabValue || '');
              } else {
                setActiveTabIndex(index);
              }
            }}
          >
            {tab.title}
          </span>
        );
      })}
    </div>
  );
}

export default memo(ListTabs);
