import React from "react";
import classNames from "classnames";

import arrowLeft from "./icons/arrow_left.svg";
import arrowRight from "./icons/arrow_right.svg";

import styles from "./IconButton.module.scss";

type Icon = "arrow_left" | "arrow_right";

interface Props extends React.HTMLProps<HTMLButtonElement> {
  icon?: Icon;
}

const iconMapping: Record<Icon, string> = {
  arrow_left: arrowLeft,
  arrow_right: arrowRight,
};

function IconButton({
  className,
  children,
  icon,
  type,
  disabled,
  ...restProps
}: Props) {
  return (
    <button
      className={classNames(styles.root, className)}
      disabled={disabled}
      {...restProps}
    >
      {children || (icon && <img alt={icon} src={iconMapping[icon]} />)}
    </button>
  );
}

export default IconButton;
