import create from "zustand";

const useState = create<{ distance: number; setDistance: (d: number) => void }>(
  (set) => ({
    distance: 0,
    setDistance: (distance: number) => set({ distance }),
  })
);

export default useState;
