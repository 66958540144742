import { useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";

import Card from "components/Card";
import Button from "components/Button";
import TextField from "components/TextField";
import CardContent from "components/CardContent";
import Logo from "components/Logo";
import Typography from "components/Typography";
import CheckboxField from "components/CheckboxField";

import useAuth from "hooks/useAuth";

import styles from "./Login.module.scss";
import { validateEmail } from "utils/validators";

interface FormValue {
  email: string;
  password: string;
}

function Login() {
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<FormValue>();
  const { login } = useAuth();

  async function submit(data: FormValue) {
    setLoading(true);
    try {
      await login(data.email, data.password);
    } catch {
      setError("password", { type: "manual", message: "Wrong password" });
    } finally {
      setLoading(false);
    }
  }

  return (
    <Card className={styles.root}>
      <CardContent>
        <Logo />
        <Typography variant="h2" textAlign="center">
          Dispatch Service
        </Typography>
        <form onSubmit={handleSubmit(submit)}>
          <TextField
            type="email"
            label="Email"
            placeholder="Email"
            {...register("email", { validate: validateEmail })}
            error={errors.email?.message}
          />
          <TextField
            type="password"
            label="Password"
            placeholder="Password"
            {...register("password", { required: "Required" })}
            error={errors.password?.message}
          />
          <div className={styles.remember}>
            <CheckboxField defaultChecked label="Remember me" />
            <Link className={styles.link} to="/forgot_password">
              Forgot password?
            </Link>
          </div>
          <div className={styles.button_container}>
            <Button type="submit" loading={loading}>
              Login
            </Button>
          </div>
        </form>
        <div className={styles.signup}>
          <Typography variant="p">
            Don’t have an account?{" "}
            <Link className={styles.link} to="/signup">
              Sign Up
            </Link>
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
}

export default Login;
