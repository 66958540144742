import {createContext} from 'react';
import {Auth} from 'types';

const authContext = createContext<Auth>({
  loading: true,
  currentUser: undefined,
  token: undefined,
  register: async () => {},
  login: async () => {},
  logout: async () => {},
  setUser: () => {},
  refetch: () => {},
});

export default authContext;
