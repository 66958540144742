import {Link} from 'react-router-dom';

import Typography from 'components/Typography';
import Modal from 'components/Modal';
import Button from 'components/Button';
import Avatar from 'components/Avatar';

import {getFullName} from 'helpers/user';
import useAuth from 'hooks/useAuth';

import sidebarStyles from '../Sidebar.module.scss';
import styles from './UserModal.module.scss';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

function UserModal({isOpen, onClose}: Props) {
  const {currentUser, logout} = useAuth();

  return (
    <Modal className={styles.modal} isOpen={isOpen} onClose={onClose}>
      <Typography variant="h2">Profile</Typography>

      <div className={styles.root}>
        <Avatar
          className={sidebarStyles.user__avatar}
          file={currentUser?.avatar}
        />
        <div className={styles.details}>
          <Typography className={styles.name} component="p" variant="h3">
            {getFullName(currentUser)}
          </Typography>
          <div className={styles.label}>
            <Typography variant="label">
              Email: {currentUser?.email}, Phone: {currentUser?.phone}
            </Typography>
          </div>
        </div>
        <Link to={`/employees/${currentUser?.id}`} className={styles.edit}>
          Edit
        </Link>
      </div>
      {currentUser?.role === 'management' && (
        <>
          <Typography style={{marginTop: 50}} variant="h2">
            Company
          </Typography>
          <div className={styles.root}>
            <div style={{flex: 1}}>
              <Typography
                style={{fontSize: 20, marginBottom: 5}}
                className={styles.name}
                component="p"
                variant="h3"
              >
                {currentUser?.company?.name}
              </Typography>

              <div className={styles.label}>
                <div>
                  <Typography variant="label">Email: </Typography>
                  <Typography className={styles.phone} variant="label">
                    <b>{currentUser?.company?.email}</b>
                  </Typography>
                </div>
                <div>
                  <Typography variant="label">Phone: </Typography>
                  <Typography className={styles.phone} variant="label">
                    <b>{currentUser?.company?.phone}</b>
                  </Typography>
                </div>
                <div>
                  <Typography variant="label">Location: </Typography>
                  <Typography className={styles.phone} variant="label">
                    <b>{currentUser?.company?.address}</b>
                  </Typography>
                </div>
              </div>
            </div>
            <Link to={`/settings/company`} className={styles.edit}>
              Edit
            </Link>
          </div>
        </>
      )}
      <Button style={{marginTop: 30}} size="sm" onClick={logout}>
        Logout
      </Button>
    </Modal>
  );
}

export default UserModal;
