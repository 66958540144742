import {areIntervalsOverlapping, parse} from 'date-fns';
import {FilterOption} from 'pages/DeliveryPlanner/components/Filter/Filter';
import nyPostalCodeBoroughOptions from 'shared/ny-postal-code-borough-options';
import {Order} from 'types';

export const getOrderDeliveryDistrict = (order: Order) => {
  if (typeof order.delivery_address === 'string') {
    const [, district = ''] = order.delivery_address.split(',');
    return district.trim();
  }
  const address = order.delivery_address.address_line_1;
  const [, district = order.delivery_address.city] = address.split(',');
  return district.trim();
};

const postalCodeBoroughMap = nyPostalCodeBoroughOptions.reduce((prev, item) => {
  prev[item.value] = item.label;
  return prev;
}, {} as Record<string, string>);

export const getOrderDeliveryBorough = (order: Order) => {
  if (typeof order.delivery_address === 'string') {
    const [, postalCode = ''] = order.delivery_address.split(' ');
    return postalCodeBoroughMap[postalCode.trim()] || '';
  }
  return postalCodeBoroughMap[order?.delivery_address?.zipcode] || '';
};

const now = new Date();

const timePreference: Record<string, Interval> = {
  morning: {
    start: parse('8:00 am', 'h:mm aa', now),
    end: parse('12:00 pm', 'h:mm aa', now),
  },
  afternoon: {
    start: parse('12:00 pm', 'h:mm aa', now),
    end: parse('4:00 pm', 'h:mm aa', now),
  },
  evening: {
    start: parse('4:00 pm', 'h:mm aa', now),
    end: parse('8:00 pm', 'h:mm aa', now),
  },
};

export const getOrderDeliveryTimePreference = (order: Order) => {
  if (!order.from_time || !order.to_time) {
    return '';
  }
  const startTime = parse(order.from_time, 'HH:mm:ss', now);
  const endTime = parse(order.to_time, 'HH:mm:ss', now);

  for (const preferenceWindow in timePreference) {
    try {
      if (
        areIntervalsOverlapping(timePreference[preferenceWindow], {
          start: startTime,
          end: endTime,
        })
      ) {
        return preferenceWindow;
      }
    } catch (e) {
      continue;
    }
  }
  return '';
};

export const filterOrder = (filter: FilterOption[]) => (order: Order) => {
  const orderTimePreference = getOrderDeliveryTimePreference(order);

  if (!filter.length) {
    return true;
  }

  const timePreferenceFilters = filter.filter((filterOption) =>
    ['morning', 'afternoon', 'evening'].includes(filterOption.value)
  );

  const locationFilters = filter.filter((filterOption) =>
    Array.isArray(filterOption.value)
  );

  return (
    (!timePreferenceFilters.length ||
      timePreferenceFilters.some((filterOption) => {
        return orderTimePreference === filterOption.value;
      })) &&
    (!locationFilters.length ||
      locationFilters.some((filterOption) => {
        if (typeof order.delivery_address !== 'string') {
          return filterOption.value.includes(
            order?.delivery_address?.zipcode || ''
          );
        }
        return false;
      }))
  );
};

const timePreferenceWeight: Record<string, number> = {
  morning: 1,
  afternoon: 2,
  evening: 3,
};

export const orderSortFunctionMap: Record<string, any> = {
  id: (left: Order, right: Order) => {
    return (
      parseInt(left.new_leaf_order || '0') -
      parseInt(right.new_leaf_order || '0')
    );
  },
  location: (left: Order, right: Order) => {
    const leftBorrough = getOrderDeliveryBorough(left).toLowerCase();
    const rightBorrough = getOrderDeliveryBorough(right).toLowerCase();
    if (leftBorrough < rightBorrough) {
      return -1;
    }
    if (leftBorrough === rightBorrough) {
      return 0;
    }
    if (leftBorrough > rightBorrough) {
      return 1;
    }
  },
  time: (left: Order, right: Order) => {
    const leftTimePreference = getOrderDeliveryTimePreference(left);
    const rightTimePreference = getOrderDeliveryTimePreference(right);
    if (leftTimePreference && rightTimePreference) {
      return (
        timePreferenceWeight[leftTimePreference] -
        timePreferenceWeight[rightTimePreference]
      );
    }
    return 0;
  },
};
