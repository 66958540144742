import cn from "classnames";

import styles from "./PageHeader.module.scss";

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

function PageHeader({ className, ...restProps }: Props) {
  return <div className={cn(styles.root, className)} {...restProps}></div>;
}

export default PageHeader;
