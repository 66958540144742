import {QueryFunctionContext, useQuery} from 'react-query';
import {useRouteMatch} from 'react-router-dom';
import api from 'api';
import {Transaction} from 'types';

async function getRequestById({
  queryKey,
}: QueryFunctionContext): Promise<Transaction> {
  const [, id] = queryKey;
  const {data} = await api.get<Transaction>(`/payment/transactions/${id}/`);
  return {...data} as Transaction;
}

function useRequestDetails() {
  const {params} = useRouteMatch<{id: string}>();
  const {data} = useQuery(['request_details', params.id], getRequestById);

  return {
    data,
    id: params.id,
  };
}

export default useRequestDetails;
