import api from 'api';
import {useMutation, useQueryClient} from 'react-query';

export const useDeleteEmployee = () => {
  const queryClient = useQueryClient();
  const {mutateAsync, isLoading} = useMutation({
    mutationFn: async (id: number) => {
      return await api.delete(`/users/${id}/`);
    },
    mutationKey: ['employees'],
    onSuccess: () => {
      queryClient.invalidateQueries(['employees, employee']);
    },
  });

  return {
    deleteEmployee: mutateAsync,
    isDeleting: isLoading,
  };
};

export default useDeleteEmployee;
