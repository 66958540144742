import React from 'react';
import {IconProps} from 'types/ui';

const ArrowIcon: React.VFC<IconProps> = ({size = 10, className}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 40"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m0 40 20-20L0 0v40Z" fill="currentColor" />
    </svg>
  );
};

export default ArrowIcon;
