// import Button from 'components/Button';
// import StripePricingTable from 'components/StripePricing';
import {useState} from 'react';
import useTariff, {TariffAddition} from './useTariff';
import Button from 'components/Button';
import useAuth from 'hooks/useAuth';
import {get} from 'lodash';
import usePricing from './usePricing';

type ItemProps = {
  id: string;
  title: string;
  price: number;
  people: string;
  addition: TariffAddition[];
  choosen: string;
  setChoosen: (value: string) => void;
  buyTariff: (data: {product_id: string; count: number}) => void;
};
const PlanCard = ({
  title,
  price,
  people,
  addition,
  buyTariff,
  id,
  choosen,
  setChoosen,
}: ItemProps) => {
  const {currentUser} = useAuth();
  const [count, setCount] = useState(
    get(currentUser, 'company.plan.plan_id') === id
      ? get(currentUser, 'company.plan.subscription_quantity', 1)
      : 1
  );

  const {data} = usePricing({quantity: count, id});

  return (
    // @ts-ignore
    <div style={styles.card}>
      <h3>{title}</h3>
      <p>Start with {people} for</p>
      <h2 style={styles.price}>
        {get(data, 'total_prorated_charge', '') || '0'}
        <span style={styles.perMonth}>/month</span>
      </h2>
      {addition.map((item) => {
        return (
          <p style={{margin: 0, marginTop: 5}} key={item.name}>
            {item.name}
          </p>
        );
      })}
      {choosen === id && (
        <div style={{display: 'flex', justifyContent: 'center', marginTop: 20}}>
          <div
            style={{
              display: 'flex',
              alignSelf: 'center',
              borderWidth: 1,
              borderColor: 'rgba(0,0,0,0.2)',
              borderStyle: 'solid',
              borderRadius: 8,
            }}
          >
            <p
              onClick={() => setCount((pr) => Math.max(pr - 1, 1))}
              style={{
                padding: '10px 20px',
                margin: 0,
                fontSize: 20,
                cursor: 'pointer',
              }}
            >
              -
            </p>
            <p
              style={{
                width: 40,
                height: 40,
                margin: 0,
                fontSize: 16,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {count}
            </p>
            <p
              onClick={() => setCount((pr) => pr + 1)}
              style={{
                padding: '10px 20px',
                margin: 0,
                fontSize: 20,
                cursor: 'pointer',
              }}
            >
              +
            </p>
          </div>
        </div>
      )}
      <Button
        onClick={async () =>
          choosen !== id
            ? setChoosen(id)
            : await buyTariff({product_id: id, count})
        }
        style={{marginLeft: 'auto', marginRight: 'auto', marginTop: 30}}
        color="green"
      >
        {choosen === id
          ? get(currentUser, 'company.plan.plan_id') === id
            ? 'Update'
            : 'Buy tariff'
          : 'Choose plan'}
      </Button>
    </div>
  );
};

const PricingPlans = () => {
  const {currentUser} = useAuth();
  const [choosen, setChoosen] = useState(
    get(currentUser, 'company.plan.plan_id', '')
  );

  const {data, buyTariff} = useTariff();

  return (
    //@ts-ignore
    <div style={styles.container}>
      <h2>Choose your plan</h2>
      <p>All paid plans include unlimited projects, clients, and invoices.</p>
      {/* <StripePricingTable /> */}
      {/* @ts-ignore*/}
      <div style={styles.plans}>
        {data.map((item) => {
          return (
            <PlanCard
              key={item.id}
              id={item.id}
              choosen={choosen}
              setChoosen={setChoosen}
              title={item.name}
              price={get(item, 'prices[0].unit_amount_decimal', 0)}
              buyTariff={buyTariff}
              addition={item.marketing_features}
              people={item.description}
            />
          );
        })}
      </div>

      <div style={styles.footer}>
        <p>
          We also offer a <a href="#free">Free plan</a> which provides 1 person
          and 2 projects.
        </p>
        <p>
          Active people are people who can sign in to Harvest to track time and
          expenses, or perform project management and administrative duties. You
          can archive people to free up active user slots and add new people.
        </p>
        <p>
          You can only choose a plan that fits the number of active people you
          currently have. Questions? <a href="#free">Get in touch with us.</a>
        </p>
      </div>
    </div>
  );
};

const styles = {
  container: {
    textAlign: 'center',
    // alignItems: 'center',
    // padding: '20px',
    // justifyContent: 'center',
    // height: '88vh',
    // display: 'flex',
    // flexDirection: 'column',
  },
  plans: {
    display: 'flex',
    justifyContent: 'center',
    gap: '20px',
    marginTop: '20px',
    flexWrap: 'wrap',
  },
  card: {
    border: '1px solid #ccc',
    borderRadius: '10px',
    padding: '20px',
    width: '100%',
    minWidth: 300,
    maxWidth: '25%',
    backgroundColor: '#f9f9f9',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  price: {
    fontSize: '32px',
    margin: '10px 0',
  },
  perMonth: {
    fontSize: '16px',
    color: '#888',
  },
  button: {
    padding: '10px 20px',
    backgroundColor: '#28a745',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  footer: {
    marginTop: '20px',
    fontSize: '14px',
    color: '#666',
  },
};

export default PricingPlans;
