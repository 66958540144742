import classNames from 'classnames';

import {File} from 'types';

import system_avatar from './images/system_avatar.png';

import styles from './Avatar.module.scss';

interface Props {
  className?: string;
  name?: string;
  file?: File;
  size: 'sm' | 'md' | 'lg';
}

const AVATAR_PLACEHOLDER =
  'https://cdn.vectorstock.com/i/preview-1x/77/30/default-avatar-profile-icon-grey-photo-placeholder-vector-17317730.jpg';

function Avatar({className, name, file, size}: Props) {
  return (
    <img
      className={classNames(styles.root, styles[`--${size}`], className)}
      alt={name || 'avatar'}
      src={
        name === 'System'
          ? system_avatar
          : file?.thumbnail || AVATAR_PLACEHOLDER
      }
    />
  );
}

Avatar.defaultProps = {
  size: 'md',
};

export default Avatar;
