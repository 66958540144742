import Select, {Props as SelectProps} from 'react-select';
import {SelectOption, SupportRequestStatus} from 'types';

import './SupportStatusSelect.scss';

export type SupportStatusOption = SelectOption<SupportRequestStatus>;

interface Props extends SelectProps<SupportStatusOption, false> {
  status: SupportRequestStatus;
}

const OPTIONS: SelectOption<SupportRequestStatus>[] = [
  {value: SupportRequestStatus.ACTIVE, label: 'Unresolved'},
  {value: SupportRequestStatus.ARCHIVED, label: 'Resolved'},
];

function SupportStatusSelect({status, isSearchable, ...restProps}: Props) {
  const selectedValue = OPTIONS.find((o) => o.value === status);
  return (
    <Select
      classNamePrefix="SupportStatusSelect"
      className={status}
      options={OPTIONS}
      value={selectedValue}
      isSearchable={false}
      {...restProps}
    />
  );
}

export default SupportStatusSelect;
