import Spinner from 'components/Spinner';
import CustomerDetails from './components/CustomerDetails';
import Header from './components/Header';
import Otcs from './components/Otcs';
import useRequestDetails from './useRequestDetails';
import styles from './RequestDetails.module.scss';
import {get} from 'lodash';
import {useHistory} from 'react-router-dom';
import SignedDocument from './components/SignedDocument';

// @ts-ignore
function RequestDetails() {
  const {id, data} = useRequestDetails();

  const history = useHistory();

  const viewOrder = (id: number) => {
    history.push(`/orders/${id}`);
  };

  if (!data) return <Spinner />;

  return (
    <div>
      <Header
        amount={get(data, 'amount', '0')}
        type={data?.transaction_origin}
        id={id}
        created_at={data?.transaction_date}
      />

      <div className={styles.content}>
        <div className={styles.main_content}>
          <CustomerDetails
            customer={get(data, 'customer', {})}
            deliveryData={get(data, 'delivery_address', {})}
          />
          <SignedDocument order={data} />
          {/* <DoctorDetails
            request={data}
            showInsurance={() => setShowInsurance(true)}
          /> */}
        </div>
        <div className={styles.drugs}>
          <Otcs
            drugs={data.list_rx}
            viewOrder={viewOrder}
            card_number={get(data, 'masked_card_number', '')}
          />
        </div>
      </div>
    </div>
  );
}

export default RequestDetails;
