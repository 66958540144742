import {
  Redirect,
  Route,
  Switch,
  useRouteMatch,
  useParams,
  Link,
} from 'react-router-dom';
import {QueryFunctionContext, useQuery} from 'react-query';

import Back from 'components/Back';
import ListTabs from 'components/ListTabs';
import PageHeader from 'components/PageHeader';
import Typography from 'components/Typography';
import CardContent from 'components/CardContent';
import Card from 'components/Card';
import Spinner from 'components/Spinner';

import Deliveries from './Deliveries';
import Requests from './Requests';

import api from 'api';
import {Customer} from 'types';
import {getFullName} from 'helpers/user';

import styles from './UserDetails.module.scss';
import {format, parse} from 'date-fns';

async function getUserById({queryKey}: QueryFunctionContext) {
  const [, userId] = queryKey;

  const {data} = await api.get<Customer>(`/customers/${userId}/`);

  return data;
}

function UserDetails() {
  const {path, url} = useRouteMatch();
  const {userId} = useParams<{userId: string}>();
  const {data: user, isLoading} = useQuery(['user', userId], getUserById);

  if (isLoading || !user) return <Spinner />;

  return (
    <div>
      <PageHeader>
        {/*  TODO: move to separate component */}
        <div className={styles.title}>
          <Back />
          <div>
            <Typography variant="h2">{getFullName(user.user)}</Typography>
            <Typography variant="h5">
              Created an account at November 2, 2021
            </Typography>
          </div>
        </div>
      </PageHeader>

      <div className={styles.content}>
        <div>
          <ListTabs
            className={styles.tabs}
            tabs={[
              {title: 'Requests', path: `${url}/requests`},
              {title: 'Deliveries', path: `${url}/deliveries`},
            ]}
          />
          <Switch>
            <Route path={`${path}/requests`} component={Requests} />
            <Route path={`${path}/deliveries`} component={Deliveries} />
            <Redirect to={`${path}/requests`} />
          </Switch>
        </div>
        <div className={styles.info_column}>
          <Card>
            <CardContent px="sm" pt="lg" pb="lg">
              <Typography component="p" variant="label">
                Customer Details
              </Typography>
              <br />
              <div className={styles.contact_label}>
                <Typography component="p" variant="label">
                  Contact Information
                </Typography>
                <Link className={styles.edit_link} to={`${url}/edit`}>
                  Edit
                </Link>
              </div>
              <Typography component="p" variant="h3">
                {user.user.phone}
              </Typography>
              <br />

              <Typography component="p" variant="label">
                Date of birth
              </Typography>
              {user.birthday ? (
                <Typography component="p" variant="h3">
                  {format(
                    parse(user.birthday, 'yyyy-MM-dd', new Date()),
                    'MMMM, dd, yyyy'
                  )}
                </Typography>
              ) : (
                '-'
              )}
              <br />

              <Typography variant="a" href={`mailto:${user.user.email}`}>
                {user.user.email}
              </Typography>
              <br />
              <br />
              {/*
              <Typography component="p" variant="label">
                Address
              </Typography>
              <Typography component="p" variant="h3">
                {getAddress(user.user)}
              </Typography> */}
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default UserDetails;
