import {Switch, Route, Redirect} from 'react-router-dom';

import {CustomRouteProps} from 'components/CustomRoute';

import ForgotPassword from 'pages/ForgotPassword';
import Login from 'pages/Login';
import ResetPassword from 'pages/ResetPassword';
import Signup from 'pages/Signup';

const authRoutes: CustomRouteProps[] = [
  {path: '/login', component: Login},
  {path: '/signup', component: Signup},
  {path: '/forgot_password', component: ForgotPassword},
  {path: '/reset_password', component: ResetPassword},
];

function AuthApp() {
  return (
    <div className="content --full-width --center">
      <Switch>
        {authRoutes.map((route) => (
          <Route key={route.path} {...route} />
        ))}
        <Redirect to="/login" />
      </Switch>
    </div>
  );
}

export default AuthApp;
