import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";

import Card from "components/Card";
import Button from "components/Button";
import TextField from "components/TextField";
import CardContent from "components/CardContent";
import Logo from "components/Logo";
import Typography from "components/Typography";

import { validateEmail } from "utils/validators";

import styles from "./ForgotPassword.module.scss";
import api from "api";

interface FormValue {
  email: string;
}

function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValue>();

  async function submit(data: FormValue) {
    setLoading(true);
    await api.post("/users/forgot-password/", data);
    setLoading(false);
    setDone(true);
  }
  return (
    <Card className={styles.root}>
      <CardContent>
        <Logo />
        <Typography variant="h2" textAlign="center">
          Forgot Password?
        </Typography>
        {done ? (
          <Typography className={styles.done} variant="h3" textAlign="center">
            To reset your password, click on the confirmation link sent to the
            email provided.
          </Typography>
        ) : (
          <Fragment>
            <form onSubmit={handleSubmit(submit)}>
              <TextField
                label="Email address"
                placeholder="Email"
                {...register("email", { validate: validateEmail })}
                error={errors.email?.message}
              />

              <div className={styles.button_container}>
                <Button type="submit" loading={loading}>
                  Reset password
                </Button>
              </div>
            </form>

            <div className={styles.signup}>
              <Typography variant="p">
                Already have an account?{" "}
                <Link className={styles.link} to="/login">
                  Sign in
                </Link>
              </Typography>
            </div>
          </Fragment>
        )}
      </CardContent>
    </Card>
  );
}

export default ForgotPassword;
