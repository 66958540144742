import React, {forwardRef, LegacyRef, useRef, useState} from 'react';
import {get} from 'lodash';

import styles from './styles.module.scss';
import ReactToPrint from 'react-to-print';
import PrintIcon from 'icons/PrintIcon';
import {Order} from 'types';
// import Barcode from 'react-barcode';
import {format} from 'date-fns';
import useAuth from 'hooks/useAuth';
// import {mapMMStatusText} from 'constants/mappings';
// import arrow_bottom from '../../../../components/DataTable/icons/arrow_down.svg';

type Props = {
  order: any;
  driver: any;
};

const SignedDocument = forwardRef(
  ({order, driver}: Props, ref: LegacyRef<HTMLDivElement>) => {
    const orderRef = useRef(null);
    const checkRef = useRef(null);
    const [open] = useState(false);
    const {currentUser} = useAuth();

    if (order.status !== 'completed') {
      return null;
    }

    return (
      <div ref={ref}>
        <div
          style={{position: 'relative', zIndex: 1}}
          ref={orderRef}
          className={styles.root}
        >
          <div className={styles.center}>
            <p>{get(currentUser, 'company.name', '')}</p>
            <p>{get(currentUser, 'company.address', '')}</p>
            <p>
              Phone:{' '}
              <span>
                {get(currentUser, 'company.phone', '')}
                {/* | Fax: (212) 371-2255 */}
              </span>
            </p>
          </div>

          <p className={styles.clientHead}>Customer:</p>
          <p>
            {get(order, 'customer.user.first_name', '')}{' '}
            {get(order, 'customer.user.last_name', '')}
          </p>
          <p>
            {get(order, 'delivery_address.address_line_1')}{' '}
            {get(order, 'delivery_address.address_line_2')}
            {get(order, 'delivery_address.city')},{' '}
            {get(order, 'delivery_address.state')}{' '}
            {get(order, 'delivery_address.zipcode')}
          </p>
          <p>Phone# {get(order, 'customer.user.phone')}</p>
          <p>
            <b>Order# {get(order, 'id', 0)}</b>
          </p>
          <div className={styles.table}>
            <div className={styles.tableRow}>
              <div
                style={{
                  flex: 0,
                  width: 20,
                  minWidth: 20,
                }}
              >
                <p className={styles.boldtext}>No</p>
              </div>
              <div>
                <p className={styles.boldtext}>Item</p>
              </div>
              <div>
                <p className={styles.boldtext}>Order Date</p>
              </div>
              {/* <div>
                <p className={styles.boldtext}>Order#</p>
              </div> */}
              <div>
                <p className={styles.boldtext}>Amount due</p>
              </div>
              <div>
                <p className={styles.boldtext}>Payment method</p>
              </div>
              <div>
                <p className={styles.boldtext}>Payment status</p>
              </div>
            </div>
            {get(order, 'orders', []).map((item: Order, index: number) => (
              <div key={index} className={styles.tableRow}>
                <div
                  style={{
                    flex: 0,
                    width: 20,
                    minWidth: 20,
                  }}
                >
                  <p>{index + 1}</p>
                </div>
                <div>
                  <p>{get(item, 'items[0].drug.name')}</p>
                </div>
                <div>
                  <p>{get(item, 'created_date', '')}</p>
                </div>
                {/* <div>
                  <p>{get(item, 'id', '')}</p>
                </div> */}
                <div>
                  <p>${get(item, 'items[0].amount_due', '')}</p>
                </div>
                <div>
                  <p style={{textTransform: 'capitalize'}}>
                    {get(item, 'payment_method', '-')
                      ?.toString()
                      .replace('_', ' ') || '-'}
                  </p>
                </div>
                <div>
                  <p>{!!get(item, 'paid_at', '') ? 'Paid' : 'Unpaid'}</p>
                </div>
              </div>
            ))}
          </div>
          {/* <div className={styles.table}>
            <div className={styles.tableRow}>
              <div>
                <p className={styles.boldtext}>COD Amt</p>
              </div>
              <div>
                <p className={styles.boldtext}>Packages</p>
              </div>
              <div style={{flex: 2}}>
                <p className={styles.boldtext}>Press.Addr</p>
              </div>
            </div>
            <div className={styles.tableRow}>
              <div>
                <p>
                  {(
                    Number(get(order, 'total_cash_payment_amount', 0)) || 0
                  ).toFixed(2)}
                </p>
              </div>
              <div>
                <p>1</p>
              </div>
              <div style={{flex: 2}}>
                <p>{get(order, 'pharm_address', '')}</p>
              </div>
            </div>
          </div> */}
          <div className={styles.row}>
            <div>
              <p>Total Packages: {get(order, 'orders_count', 0)}</p>
              <p>
                Counselling Requested: Yes___ No_&#10004;_ Receiver's Name:{' '}
                <span>{get(order, 'accepters_name', '')}</span>
              </p>
              <p>
                I certify that I requested and received my medication listed
                above from Meds Pharmacy, located at 1104 2nd Ave New York, NY
                10022
                {/* Time:{' '}
          {get(order, 'delivered_at')
            ? format(new Date(get(order, 'delivered_at')), 'p')
            : ''}{' '}
          {get(order, 'delivery_address.address_line_1')}{' '}
          {get(order, 'delivery_address.address_line_2')}
          {get(order, 'delivery_address.city')},{' '}
          {get(order, 'delivery_address.state')}{' '}
          {get(order, 'delivery_address.zipcode')} */}
                . I further certify that I had a patient relationship with the
                ordering medical provider indicated on the prescription label
                and that I requested that the prescriber send this prescription
                to the pharmacy. The foregoing is certified as true and accurate
                under the penalty of Perjury. I have received the above
                mentioned Prescription(s) and paid the total due amount.
              </p>
            </div>
            <div style={{minWidth: 200, marginLeft: 10}}>
              <p>
                Customer: {get(order, 'customer.user.first_name', '')}{' '}
                {get(order, 'customer.user.last_name', '')}
              </p>
              {get(order, 'total_cash_payment_amount', 0) === 0 ? (
                <p>Amount collected:</p>
              ) : (
                <p>
                  Cash on Delivery: $
                  {Number(get(order, 'total_cash_payment_amount', 0)).toFixed(
                    2
                  )}
                </p>
              )}

              <p>
                Date:{' '}
                {get(order, 'signed_date') ? get(order, 'signed_date') : ''}
              </p>
              <p>
                Time:{' '}
                {get(order, 'signed_time') ? get(order, 'signed_time') : ''}
              </p>
              <p>
                Field member: {get(driver, 'first_name', '')}{' '}
                {get(driver, 'last_name', '')}
              </p>
              <p>Receiver: {get(order, 'accepters_name', '')}</p>

              {/* <p>Relation: {get(order, 'relation', '')}</p>
            <p>
              Relation Description:{' '}
              {get(order, 'relation_description', '') || 'N/A'}
            </p> */}
            </div>
          </div>
          <div style={{justifyContent: 'flex-start'}} className={styles.row}>
            <p>Receiver's Signature:</p>
            <img
              src={get(order, 'signature.file', null)}
              className={styles.signatureImage}
              alt="Signature by customer"
            />
          </div>
          <div className={styles.print}>
            <ReactToPrint
              trigger={() => (
                <button className={styles.panel__deliveries_print_action}>
                  <PrintIcon size={16} />
                </button>
              )}
              content={() => orderRef.current}
            />
          </div>
          {!!get(order, 'delivery_image', null) && (
            <div
              style={{position: 'absolute', zIndex: -1, bottom: 0, right: 0}}
              className={styles.delivery_image}
            >
              <img
                alt="delivery_image"
                style={{
                  width: 150,
                }}
                src={get(order, 'delivery_image.medium', '')}
              />
            </div>
          )}
        </div>
        {/* <div onClick={() => setOpen((pr) => !pr)} className={styles.dropdown}>
        <p>Receipt</p>
        <img
          style={{
            transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
          }}
          className={styles.arrow_bottom}
          alt="Arror Bottom"
          src={arrow_bottom}
        />
      </div> */}
        {open && (
          <div ref={checkRef} className={styles.root}>
            <div className={styles.center}>
              <p>Medications & Essentials Delivered Swiftly Inc.</p>
              <p>1104 2nd Ave New York, NY 10022</p>
              <p>
                Phone: <span>(212) 371-6000 | Fax: (212) 371-2255</span>
              </p>
            </div>

            <p
              style={{
                marginBottom: 5,
                marginTop: 20,
                paddingLeft: 50,
                paddingRight: 50,
              }}
            >
              <b>Customer name:</b> {get(order, 'customer.user.first_name', '')}{' '}
              {get(order, 'customer.user.last_name', '')}
            </p>
            <div
              style={{
                justifyContent: 'flex-start',
                marginTop: 0,
                paddingLeft: 50,
                paddingRight: 50,
              }}
              className={styles.row}
            >
              <div>
                <p>
                  <b>Date:</b>{' '}
                  {get(order, 'delivered_at')
                    ? format(
                        new Date(get(order, 'delivered_at')),
                        'MM/dd/yyyy p'
                      )
                    : ''}
                </p>
                <p>
                  <b>Trans Type:</b> Sale
                </p>
              </div>
              <div style={{flex: 1, marginLeft: 95}}>
                <p>
                  <b>Trans#:</b> 1123
                </p>
                <p>
                  <b>Opr:</b> POS
                </p>
              </div>
            </div>

            <div
              style={{paddingLeft: 50, paddingRight: 50}}
              className={styles.row}
            >
              <p style={{fontSize: 15}}>Qty Description</p>
              <p style={{fontSize: 15}}>
                Price ({get(order, 'orders[0].items[0].paid_currency', '')})
              </p>
            </div>
            <hr style={{borderWidth: 0.3}} />
            <div
              style={{paddingLeft: 50, paddingRight: 50}}
              className={styles.row}
            >
              <p>{get(order, 'orders[0].items[0].quantity', '')}</p>
              <p>{get(order, 'orders[0].items[0].paid', '')}</p>
            </div>
            <hr style={{borderWidth: 0.3}} />
            <div style={{paddingLeft: 50, paddingRight: 50}}>
              <div className={styles.row}>
                <p>
                  <b>Sub Total:</b>
                </p>
                <p>{get(order, 'orders[0].items[0].paid', '')}</p>
              </div>
              <div className={styles.row}>
                <p>
                  <b>Discount:</b>
                </p>
                <p>N/A</p>
              </div>
              <div className={styles.row}>
                <p>
                  <b>Tax:</b>
                </p>
                <p>N/A</p>
              </div>
              <div className={styles.row}>
                <p>
                  <b>Total Amount Due:</b>
                </p>
                <p>{get(order, 'orders[0].items[0].paid', '')}</p>
              </div>
            </div>
            <hr style={{borderWidth: 0.3}} />
            <div style={{paddingLeft: 50, paddingRight: 50}}>
              <p>Amount Tendered</p>
              <div className={styles.row}>
                <p>American Express XXXX-XXXX-XXXX</p>
                <p>1100</p>
              </div>
              <div className={styles.row}>
                <p>Total # Item of Sold</p>
                <p>1</p>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {/* <p>THANK YOU FOR YOUR PATRONAGE</p>
            <p style={{margin: 0, marginBottom: 10}}>!! HAVE A NICE DAY!!!!</p> */}
            </div>
            <hr style={{}} />
            <div className={styles.center}>
              <p>Medications & Essentials Delivered Swiftly Inc.</p>
              <p>1104 2nd Ave New York, NY 10022</p>
              <p>
                Phone: <span>(212) 371-6000 | Fax: (212) 371-2255</span>
              </p>
            </div>
            <div className={styles.row}>
              <p>
                <b>Trans Type:</b>
              </p>
              <p>Sale</p>
            </div>
            <div className={styles.row}>
              <p>
                <b>Merchant#:</b>
              </p>
              <p></p>
            </div>
            <div className={styles.row}>
              <p>
                <b>Trans#:</b>
              </p>
              <p>Sale</p>
            </div>
            <div className={styles.row}>
              <p>
                <b>Opr:</b>
              </p>
              <p>POS</p>
            </div>
            <div className={styles.row}>
              <p>
                <b>American Express:</b>
              </p>
              <p>XXXX-XXXX-XXXX</p>
            </div>
            <div className={styles.row}>
              <p>
                <b>Auth#:</b>
              </p>
              <p>189628</p>
            </div>
            <div className={styles.row}>
              <p>
                <b>Total:</b>
              </p>
              <p>{get(order, 'orders[0].items[0].paid', '')}</p>
            </div>

            <div className={styles.print}>
              <ReactToPrint
                trigger={() => (
                  <button className={styles.panel__deliveries_print_action}>
                    <PrintIcon size={16} />
                  </button>
                )}
                content={() => checkRef.current}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
);

export default SignedDocument;
